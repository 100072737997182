import { screenTypeList } from '@constants/index';
import React, { useTransition } from 'react';

const CampaginLocation = ({ campaginTypeId, setCampaignTypeId }) => {
  const [_isPending, startTransition] = useTransition();

  const handleLocationChange = (id) => {
    startTransition(() => {
      setCampaignTypeId(id);
    });
  };

  return (
    <>
      <h1 className="welcome-screen__heading">
        What is the location of campaign?
      </h1>
      <ul className="card-container">
        {screenTypeList.map((image) => {
          const isSelected = String(campaginTypeId) === image.id;
          return (
            <li
              key={image.id}
              className="card"
              onClick={() => handleLocationChange(image.id)}
              role="presentation"
            >
              <div className="card__image-container">
                <div
                  style={{
                    border: '1px solid #0000001A',
                    padding: '31px, 29px, 31px, 29px',
                    borderRadius: '10px',
                    boxShadow: '0px 11px 29.4px 0px #E6E7ED',
                    width: '104px',
                    height: '104px',
                    display: 'flex',
                    background: isSelected ? '#F7F6F2' : '#FFF',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {isSelected ? image?.selectedImage : image?.defaultImage}
                </div>
                <div
                  className="flex align-center"
                  style={{
                    gap: '4px',
                  }}
                >
                  <input
                    id={`name-${image.id}`}
                    type="radio"
                    className="welcome-screen__radio"
                    name="locationImage"
                    style={{
                      margin: 'unset',
                      visibility: isSelected ? 'visible' : 'hidden',
                    }}
                    value={image.id}
                    checked={isSelected}
                  />
                  <p
                    className="welcome-screen__card-subtitle flex align-center"
                    style={{
                      gap: '4px',
                    }}
                  >
                    {image.label?.()}
                  </p>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CampaginLocation;
