import React from 'react';
import indoor from '../Images/indoor.png';
import checkbox from '../Images/outdoor_icon.png';
import { uuid } from '@utils/helpers';
import NorthAmerica from '../Images/Icons/NorthAmericaIcon';
import NordicsIcon from '../Images/Icons/NordicsIcon';
import LatinAmericaIcon from '../Images/Icons/LatinAmericaIcon';
import AllTheWorldIcon from '../Images/Icons/AllTheWorldIcon';
import NordicsSelectedIcon from '../Images/Icons/NordicsSelectedIcon';
import WesternEuropeIcon from '../Images/Icons/WesternEuropeIcon';
import IndoorIcon from '../Images/Icons/IndoorIcon';
import GroupallIcon from '../Images/Icons/GroupallIcon';
import GroupallSelectedIcon from '../Images/Icons/GroupallSelectedIcon';
import OutdoorIcon from '../Images/Icons/OutdoorIcon';

export const CountryMap = {
  1: {
    name: 'nordics',
    center: [25.75, 63.0],
    zoomlevel: 4,
  },
  2: {
    name: 'wester_europe',
    center: [0.2, 50.5],
    zoomlevel: 5,
  },
  3: {
    name: 'north_america',
    center: [-99.1332, 53.0902],
    zoomlevel: 3,
  },
  4: {
    name: 'latin_america',
    center: [-90.0, 5.0],
    zoomlevel: 2.9,
  },
  5: {
    name: 'all_over_the_world',
    center: [10, 45],
    zoomlevel: 1.5,
  },
};

export const countryList = [
  {
    id: uuid(),
    label: 'Nordics',
    description: 'Denmark, Norway, Iceland, Sweden, Finland',
    defaultImage: <NordicsIcon />,
    selectedImage: <NordicsSelectedIcon />,
    alt: 'Image 1',
    type: 1,
  },
  {
    id: uuid(),
    label: 'Western Europe',
    description:
      'United Kingdom, Germany, Netherlands, France, Belgium, Austria, Switzerland, Luxembourg',
    defaultImage: <WesternEuropeIcon />,
    selectedImage: <WesternEuropeIcon fill="#1A026B" />,
    alt: 'Image 2',
    type: 2,
  },
  {
    id: uuid(),
    label: 'North America',
    defaultImage: <NorthAmerica />,
    selectedImage: <NorthAmerica fill="#1A026B" />,
    description: 'US, Canada',
    alt: 'Image 3',
    type: 3,
  },
  {
    id: uuid(),
    label: 'Latin America',
    description: `Includes all the Central & South America`,
    defaultImage: <LatinAmericaIcon />,
    selectedImage: <LatinAmericaIcon fill="#1A026B" />,
    alt: 'Image 4',
    type: 4,
  },
  {
    id: uuid(),
    label: 'All the World',
    defaultImage: <AllTheWorldIcon />,
    selectedImage: <AllTheWorldIcon fill="#1A026B" />,
    alt: 'Image 5',
    type: 5,
  },
];

export const screenTypeList = [
  {
    id: uuid(),
    defaultImage: <GroupallIcon />,
    selectedImage: <GroupallSelectedIcon />,
    alt: 'Location 1',
    type: 3,
    label: () => {
      return (
        <>
          <img src={checkbox} alt="outdoor" width="10px" height="10px" />
          <img src={indoor} alt="indoor" width="10px" height="10px" />
          <span>All</span>
        </>
      );
    },
  },
  {
    id: uuid(),
    defaultImage: <IndoorIcon />,
    selectedImage: <IndoorIcon fill="#1A026B" />,
    alt: 'Location 2',
    type: 1,
    label: () => {
      return (
        <>
          <img src={checkbox} alt="outdoor" width="10px" height="10px" />
          <span>Indoor</span>
        </>
      );
    },
  },
  {
    id: uuid(),
    defaultImage: <OutdoorIcon />,
    selectedImage: <OutdoorIcon fill="#1A026B" />,
    alt: 'Location 3',
    type: 2,
    label: () => {
      return (
        <>
          <img src={indoor} alt="indoor" width="10px" height="10px" />
          <span>Outdoor</span>
        </>
      );
    },
  },
];

export const cities = [
  {
    id: '21',
    type: 'country',
    attributes: {
      name: 'Israel',
      code: 'IL',
    },
    relationships: {
      cities: {
        data: [
          {
            id: '61102',
            name: 'Ashdod',
            type: 'city',
            streets: [
              {
                street: 'Ashdodstraße',
                lat: 52.560448,
                lon: 13.219936,
              },
              {
                street: 'אשדוד',
                lat: 32.041141,
                lon: 34.760588,
              },
              {
                street: 'Ashdod Lane',
                lat: 42.069321,
                lon: -70.753612,
              },
              {
                street: 'Ashdon Close',
                lat: -27.362276,
                lon: 152.987089,
              },
              {
                street: 'Ashdad Road',
                lat: 45.348995,
                lon: -76.757506,
              },
            ],
          },
          {
            id: '61106',
            name: 'Bitha',
            type: 'city',
            streets: [
              {
                street: 'Bitham Road',
                lat: 51.558883,
                lon: -1.387147,
              },
              {
                street: 'Bitham Road',
                lat: 51.574265,
                lon: -1.387621,
              },
              {
                street: 'Bitham Road',
                lat: 52.20109,
                lon: -1.487765,
              },
              {
                street: 'Bitham Lane',
                lat: 51.374756,
                lon: -1.527865,
              },
              {
                street: 'Bitham Lane',
                lat: 52.831983,
                lon: -1.637082,
              },
            ],
          },
          {
            id: '61107',
            name: 'Brosh',
            type: 'city',
            streets: [
              {
                street: 'Broeshartweg',
                lat: 47.494079,
                lon: 16.037823,
              },
              {
                street: 'Broshagen',
                lat: 51.248953,
                lon: 7.790079,
              },
              {
                street: 'דרך ברוש',
                lat: 33.218279,
                lon: 35.618915,
              },
              {
                street: 'ברוש',
                lat: 32.491955,
                lon: 35.50583,
              },
              {
                street: 'ברוש',
                lat: 32.686429,
                lon: 35.416196,
              },
            ],
          },
          {
            id: '61108',
            name: 'Dimona',
            type: 'city',
            streets: [
              {
                street: 'Dimonaktos',
                lat: 35.175711,
                lon: 33.370062,
              },
              {
                street: 'Dimonaktos',
                lat: 34.703112,
                lon: 33.033035,
              },
              {
                street: 'Dimonastraße',
                lat: 50.420451,
                lon: 7.394635,
              },
              {
                street: 'דימונה',
                lat: 31.25639,
                lon: 35.202901,
              },
              {
                street: 'דימונה',
                lat: 32.056631,
                lon: 34.798616,
              },
            ],
          },
          {
            id: '61109',
            name: 'Dorot',
            type: 'city',
            streets: [
              {
                street: 'Dorothea',
                lat: -37.946025,
                lon: -57.7824,
              },
              {
                street: 'Dorotea Morad',
                lat: -32.179655,
                lon: -58.213963,
              },
              {
                street: 'Doroteo Gómez',
                lat: -34.294876,
                lon: -60.257552,
              },
              {
                street: 'Dorotea Macedo',
                lat: -38.666794,
                lon: -62.272597,
              },
              {
                street: 'Doroteo Robles',
                lat: -31.461622,
                lon: -64.102268,
              },
            ],
          },
          {
            id: '61110',
            name: 'Eilat',
            type: 'city',
            streets: [
              {
                street: 'Eilatinkuja',
                lat: 61.435119,
                lon: 29.377432,
              },
              {
                street: 'Eilat Utca',
                lat: 47.679644,
                lon: 16.617413,
              },
              {
                street: 'אילת',
                lat: 32.789174,
                lon: 35.539002,
              },
              {
                street: 'אילת',
                lat: 32.847148,
                lon: 35.071789,
              },
              {
                street: 'אילת',
                lat: 32.794072,
                lon: 35.011974,
              },
            ],
          },
          {
            id: '61111',
            name: 'Eitan',
            type: 'city',
            streets: [
              {
                street: 'איתנים',
                lat: 32.758774,
                lon: 35.146311,
              },
              {
                street: 'איתנים',
                lat: 32.802772,
                lon: 34.975307,
              },
              {
                street: 'איתן',
                lat: 31.949684,
                lon: 34.860113,
              },
              {
                street: 'איתן יונה',
                lat: 32.053713,
                lon: 34.851016,
              },
              {
                street: 'איתן לבני',
                lat: 32.080532,
                lon: 34.869644,
              },
            ],
          },
          {
            id: '61113',
            name: 'Emunim',
            type: 'city',
            streets: [
              {
                street: 'אמונים',
                lat: 32.046636,
                lon: 34.749798,
              },
              {
                street: 'Rruga Munim Selami',
                lat: 40.477668,
                lon: 19.498772,
              },
              {
                street: 'Rua Munim Açu',
                lat: -2.864801,
                lon: -44.053517,
              },
              {
                street: 'Rua Munim',
                lat: -2.490029,
                lon: -44.267201,
              },
              {
                street: 'Rua Munim',
                lat: -2.555234,
                lon: -44.189313,
              },
            ],
          },
          {
            id: '61114',
            name: 'Erez',
            type: 'city',
            streets: [
              {
                street: 'Erezcano',
                lat: -34.798839,
                lon: -58.37794,
              },
              {
                street: 'Erezcano',
                lat: -34.653774,
                lon: -58.428802,
              },
              {
                street: 'Rua Ereza Del Ciamo Bassan',
                lat: -23.543887,
                lon: -46.361763,
              },
              {
                street: 'ארז',
                lat: 32.486424,
                lon: 35.506695,
              },
              {
                street: 'ארז',
                lat: 32.844979,
                lon: 35.423134,
              },
            ],
          },
          {
            id: '61115',
            name: 'Gevim',
            type: 'city',
            streets: [
              {
                street: 'גבים',
                lat: 31.951611,
                lon: 34.804216,
              },
              {
                street: 'Rruga Gezim Erebara',
                lat: 41.346201,
                lon: 19.845556,
              },
              {
                street: 'Evim Caddesi',
                lat: 40.985335,
                lon: 39.699116,
              },
              {
                street: 'Sevim Sokak',
                lat: 40.992238,
                lon: 39.677842,
              },
              {
                street: 'Sevim Sk.',
                lat: 40.874102,
                lon: 35.221425,
              },
            ],
          },
          {
            id: '61116',
            name: 'Gilat',
            type: 'city',
            streets: [
              {
                street: 'Gilat Yolu',
                lat: 41.032558,
                lon: 41.049828,
              },
              {
                street: 'Rruga Vilat Gjermane',
                lat: 41.3176,
                lon: 19.834888,
              },
              {
                street: 'Pilatstraße',
                lat: 48.386147,
                lon: 13.875198,
              },
              {
                street: 'Gilai Place',
                lat: -33.773106,
                lon: 151.257111,
              },
              {
                street: 'Gilaa Circuit',
                lat: -31.092677,
                lon: 150.869899,
              },
            ],
          },
          {
            id: '61117',
            name: 'Grofit',
            type: 'city',
            streets: [
              {
                street: 'גרופית',
                lat: 31.323777,
                lon: 34.947151,
              },
              {
                street: 'גרופית',
                lat: 32.044858,
                lon: 34.834322,
              },
              {
                street: 'גרופית',
                lat: 31.599673,
                lon: 34.756287,
              },
              {
                street: 'גרופית',
                lat: 31.67496,
                lon: 34.583502,
              },
              {
                street: 'Profit Pass',
                lat: -31.796634,
                lon: 115.839863,
              },
            ],
          },
          {
            id: '61118',
            name: 'Hatzeva',
            type: 'city',
            streets: [
              {
                street: 'Hatzeva',
                lat: 30.766717,
                lon: 35.278538,
              },
              {
                street: 'Hazeva 99',
                lat: 30.766074,
                lon: 35.278462,
              },
              {
                street: 'חצבה',
                lat: 31.32668,
                lon: 34.945136,
              },
              {
                street: 'הצלע',
                lat: 32.094523,
                lon: 34.819495,
              },
              {
                street: 'חצבה',
                lat: 31.676762,
                lon: 34.580224,
              },
            ],
          },
          {
            id: '61120',
            name: 'Kannot',
            type: 'city',
            streets: [
              {
                street: 'Kannoth Tile Road',
                lat: 10.103532,
                lon: 76.517975,
              },
              {
                street: 'Kannoth-Theyyappara Road',
                lat: 11.445357,
                lon: 75.991271,
              },
              {
                street: 'Kannoth-Engappuzha Road',
                lat: 11.457861,
                lon: 75.982806,
              },
              {
                street: 'Kannothmala',
                lat: 11.830434,
                lon: 75.924054,
              },
              {
                street: 'Kannoth Road',
                lat: 11.884387,
                lon: 75.389217,
              },
            ],
          },
          {
            id: '61121',
            name: 'Kfar Ahim',
            type: 'city',
            streets: [
              {
                street: 'Mahim Sarvajanik Vachnalaya Marg',
                lat: 19.031372,
                lon: 72.838998,
              },
              {
                street: 'Mahim Railway Fob',
                lat: 19.038947,
                lon: 72.847479,
              },
              {
                street: 'Mahim Sion Link Road',
                lat: 19.04565,
                lon: 72.847133,
              },
              {
                street: 'Mahim Interchange',
                lat: 19.049899,
                lon: 72.837304,
              },
              {
                street: 'Mahim Causeway',
                lat: 19.047995,
                lon: 72.838358,
              },
            ],
          },
          {
            id: '61122',
            name: 'Kfar Maimon',
            type: 'city',
            streets: [
              {
                street: 'Maimonides',
                lat: -33.448359,
                lon: -70.633514,
              },
              {
                street: 'מימון',
                lat: 32.274218,
                lon: 34.908353,
              },
              {
                street: 'מיימון',
                lat: 31.967412,
                lon: 34.804171,
              },
              {
                street: 'Maimonideslaan',
                lat: 52.295499,
                lon: 4.870356,
              },
              {
                street: 'Maimont Circle',
                lat: 32.887502,
                lon: -83.728409,
              },
            ],
          },
          {
            id: '61123',
            name: 'Kiryat Gat',
            type: 'city',
            streets: [
              {
                street: 'Πύλη Ε2',
                lat: 37.944621,
                lon: 23.633703,
              },
              {
                street: 'Гатачка',
                lat: 44.733829,
                lon: 20.46842,
              },
              {
                street: 'Gatina',
                lat: 45.948898,
                lon: 14.685309,
              },
              {
                street: 'Gattingerova',
                lat: 49.068456,
                lon: 20.316676,
              },
              {
                street: 'Gato Montés',
                lat: -34.874167,
                lon: -56.27563,
              },
            ],
          },
          {
            id: '61124',
            name: 'Kissufim',
            type: 'city',
            streets: [
              {
                street: 'כיסופים',
                lat: 31.5172,
                lon: 34.921272,
              },
              {
                street: 'כיסופים',
                lat: 32.116227,
                lon: 34.831405,
              },
              {
                street: 'כיסופים',
                lat: 32.155094,
                lon: 34.83556,
              },
              {
                street: 'كيسوفيم',
                lat: 31.375152,
                lon: 34.365838,
              },
            ],
          },
          {
            id: '61125',
            name: 'Lahav',
            type: 'city',
            streets: [
              {
                street: 'Lahave Islands Road',
                lat: 44.226111,
                lon: -64.361492,
              },
              {
                street: 'Lahave Lane',
                lat: 44.296103,
                lon: -64.364859,
              },
              {
                street: 'Lahave Street',
                lat: 44.379006,
                lon: -64.515754,
              },
              {
                street: 'Lahavere — Jõeküla',
                lat: 58.794062,
                lon: 26.10705,
              },
              {
                street: 'להב',
                lat: 32.922168,
                lon: 35.310678,
              },
            ],
          },
          {
            id: '61128',
            name: 'Maslul',
            type: 'city',
            streets: [
              {
                street: 'Strada Malul Știrbului',
                lat: 46.662836,
                lon: 29.447944,
              },
              {
                street: 'Strada Malul Balții',
                lat: 45.809666,
                lon: 28.173382,
              },
              {
                street: 'Strada Malul Rîiului',
                lat: 48.042081,
                lon: 27.611192,
              },
              {
                street: 'Strada Malul Brateș',
                lat: 45.469283,
                lon: 28.040863,
              },
              {
                street: 'Malul Ghiolului',
                lat: 46.875757,
                lon: 27.614257,
              },
            ],
          },
          {
            id: '61130',
            name: 'Mishmar Hanegev',
            type: 'city',
            streets: [
              {
                street: 'משמר הנגב',
                lat: 31.299914,
                lon: 34.636835,
              },
              {
                street: 'הנגב',
                lat: 33.2027,
                lon: 35.570757,
              },
              {
                street: 'הנגב',
                lat: 32.492667,
                lon: 35.499514,
              },
              {
                street: 'משמר',
                lat: 32.530765,
                lon: 35.484325,
              },
              {
                street: 'הנגב',
                lat: 32.682373,
                lon: 35.245207,
              },
            ],
          },
          {
            id: '61131',
            name: 'Mivtahim',
            type: 'city',
            streets: [
              {
                street: 'מבטחים',
                lat: 32.087307,
                lon: 34.85451,
              },
              {
                street: 'מבטחים',
                lat: 32.119567,
                lon: 34.806559,
              },
            ],
          },
          {
            id: '61132',
            name: 'Negba',
            type: 'city',
            streets: [
              {
                street: 'נגבה',
                lat: 32.602516,
                lon: 35.286212,
              },
              {
                street: 'נגבה',
                lat: 31.76355,
                lon: 35.210576,
              },
              {
                street: 'נגבה',
                lat: 32.568237,
                lon: 34.957652,
              },
              {
                street: 'נגבה',
                lat: 31.320816,
                lon: 34.930881,
              },
              {
                street: 'נגבה',
                lat: 31.890527,
                lon: 34.808928,
              },
            ],
          },
          {
            id: '61133',
            name: 'Nehora',
            type: 'city',
            streets: [
              {
                street: 'Assentamento Sehora Da Conceicao',
                lat: -13.975395,
                lon: -43.984218,
              },
              {
                street: 'Calle Nécora',
                lat: 36.135564,
                lon: -5.844994,
              },
              {
                street: 'Neora Road',
                lat: 25.580442,
                lon: 85.009174,
              },
              {
                street: 'Neora Dera Par Road',
                lat: 25.568868,
                lon: 84.989439,
              },
              {
                street: 'Вулиця Єгора Мовчана',
                lat: 50.419443,
                lon: 35.443247,
              },
            ],
          },
          {
            id: '61134',
            name: 'Netivot',
            type: 'city',
            streets: [
              {
                street: 'נתיבות',
                lat: 32.159595,
                lon: 34.814713,
              },
              {
                street: 'נתיבות',
                lat: 32.044989,
                lon: 34.795978,
              },
              {
                street: 'נתיבות שלום',
                lat: 31.617151,
                lon: 34.753414,
              },
              {
                street: 'גרעין נתיבות',
                lat: 31.822167,
                lon: 34.690378,
              },
            ],
          },
          {
            id: '61135',
            name: 'Nir Banim',
            type: 'city',
            streets: [
              {
                street: 'Bänimatte',
                lat: 46.781248,
                lon: 8.15182,
              },
              {
                street: 'Banim Terrace',
                lat: 52.660786,
                lon: -7.258853,
              },
              {
                street: 'ניר',
                lat: 32.721667,
                lon: 35.108968,
              },
              {
                street: 'ניר',
                lat: 32.472737,
                lon: 34.994988,
              },
              {
                street: 'ניר',
                lat: 32.177034,
                lon: 34.89881,
              },
            ],
          },
          {
            id: '61136',
            name: 'Nir Hen',
            type: 'city',
            streets: [
              {
                street: 'Sir Henry Parkes Avenue',
                lat: -32.730438,
                lon: 151.874763,
              },
              {
                street: 'Sir Henry Crescent',
                lat: -35.013385,
                lon: 150.68896,
              },
              {
                street: 'Sir Henrys Parade',
                lat: -33.70359,
                lon: 150.532184,
              },
              {
                street: 'Sir Henry Bolte Court',
                lat: -38.599936,
                lon: 145.589134,
              },
              {
                street: 'Rua Nair Henrique Da Silva',
                lat: -20.060278,
                lon: -44.154814,
              },
            ],
          },
          {
            id: '61137',
            name: 'Nir Moshe',
            type: 'city',
            streets: [
              {
                street: 'Mosheimergasse',
                lat: 48.3029,
                lon: 16.402838,
              },
              {
                street: 'Rua Moshe Grajcar',
                lat: -23.577314,
                lon: -48.036605,
              },
              {
                street: 'Rua Moshe Grajcar',
                lat: -23.57443,
                lon: -48.035042,
              },
              {
                street: 'ניר',
                lat: 32.721667,
                lon: 35.108968,
              },
              {
                street: 'ניר',
                lat: 32.472737,
                lon: 34.994988,
              },
            ],
          },
          {
            id: '61138',
            name: 'Nirim',
            type: 'city',
            streets: [
              {
                street: 'Nirimba Court',
                lat: -28.214425,
                lon: 153.482527,
              },
              {
                street: 'Nirimba Street',
                lat: -27.649761,
                lon: 153.150381,
              },
              {
                street: 'Nirimba Street',
                lat: -27.464645,
                lon: 153.165761,
              },
              {
                street: 'Nirimba Drive',
                lat: -26.820973,
                lon: 153.051366,
              },
              {
                street: 'Nirimba Avenue',
                lat: -33.758962,
                lon: 151.091244,
              },
            ],
          },
          {
            id: '61139',
            name: 'Noga',
            type: 'city',
            streets: [
              {
                street: 'Nogatstraße',
                lat: 52.469711,
                lon: 13.435098,
              },
              {
                street: 'Nogatweg',
                lat: 53.593138,
                lon: 10.071308,
              },
              {
                street: 'Nogatweg',
                lat: 52.367669,
                lon: 9.854161,
              },
              {
                street: 'Nogatweg',
                lat: 52.458477,
                lon: 9.805073,
              },
              {
                street: 'Nogatstraße',
                lat: 52.622857,
                lon: 9.219644,
              },
            ],
          },
          {
            id: '61140',
            name: 'Ofakim',
            type: 'city',
            streets: [
              {
                street: 'אופקים',
                lat: 32.772845,
                lon: 35.034102,
              },
              {
                street: 'אופקים',
                lat: 32.079404,
                lon: 34.914991,
              },
              {
                street: 'אופקים',
                lat: 32.122314,
                lon: 34.805426,
              },
              {
                street: 'עופרים',
                lat: 32.723773,
                lon: 35.113135,
              },
              {
                street: 'עופרים',
                lat: 32.810575,
                lon: 34.97083,
              },
            ],
          },
          {
            id: '61141',
            name: 'Or Haner',
            type: 'city',
            streets: [
              {
                street: 'Honer Loop',
                lat: 43.926743,
                lon: -123.018794,
              },
              {
                street: 'Dr.-Haenert-Weg',
                lat: 51.036441,
                lon: 11.491425,
              },
              {
                street: 'Sr Hwy or Hauer Rd Access',
                lat: 38.74417,
                lon: -109.33675,
              },
              {
                street: 'Ortona Street',
                lat: 52.110801,
                lon: -106.730295,
              },
              {
                street: 'Or',
                lat: 46.45932,
                lon: 8.904626,
              },
            ],
          },
          {
            id: '61142',
            name: 'Orot',
            type: 'city',
            streets: [
              {
                street: 'Orotons Track',
                lat: -38.058355,
                lon: 146.303633,
              },
              {
                street: 'Orotava Street',
                lat: -38.355692,
                lon: 145.197857,
              },
              {
                street: 'Rua Orotilde Manso',
                lat: -5.335914,
                lon: -36.82957,
              },
              {
                street: 'Rua Orotides Sales',
                lat: -20.944004,
                lon: -45.393395,
              },
              {
                street: 'Rua Orotildes Etelvina Lima',
                lat: -28.747794,
                lon: -49.29912,
              },
            ],
          },
          {
            id: '61143',
            name: 'Patish',
            type: 'city',
            streets: [
              {
                street: 'פטישון',
                lat: 29.558089,
                lon: 34.933346,
              },
              {
                street: 'Patishall Lane',
                lat: 35.258168,
                lon: -80.965425,
              },
              {
                street: 'Parish Robertson',
                lat: -34.817175,
                lon: -58.458936,
              },
              {
                street: 'Paish Place',
                lat: 56.759024,
                lon: -111.448872,
              },
              {
                street: 'Paattistentie',
                lat: 60.584189,
                lon: 22.35701,
              },
            ],
          },
          {
            id: '61145',
            name: 'Revadim',
            type: 'city',
            streets: [
              {
                street: 'רבדים',
                lat: 31.748587,
                lon: 35.219108,
              },
              {
                street: 'רבדים',
                lat: 32.116598,
                lon: 34.836334,
              },
              {
                street: 'רבדים',
                lat: 31.968021,
                lon: 34.818821,
              },
              {
                street: 'Rua Evadim',
                lat: -3.072296,
                lon: -59.916235,
              },
            ],
          },
          {
            id: '61149',
            name: 'Sderot',
            type: 'city',
            streets: [
              {
                street: 'שדרות אליעזר קפלן',
                lat: 32.774347,
                lon: 35.546036,
              },
              {
                street: 'שדרות האצל',
                lat: 33.219305,
                lon: 35.567598,
              },
              {
                street: 'שדרות הארבעה',
                lat: 32.495948,
                lon: 35.50073,
              },
              {
                street: 'שדרת הפיקוסים',
                lat: 32.505053,
                lon: 35.455997,
              },
              {
                street: 'שדרת הפיקוסים',
                lat: 32.587686,
                lon: 35.440321,
              },
            ],
          },
          {
            id: '61150',
            name: 'Shafir',
            type: 'city',
            streets: [
              {
                street: 'Shafit Goxhaj',
                lat: 40.595289,
                lon: 19.731426,
              },
              {
                street: 'שמיר',
                lat: 32.992318,
                lon: 35.263788,
              },
              {
                street: 'שמיר',
                lat: 31.247942,
                lon: 35.197445,
              },
              {
                street: 'שפריר',
                lat: 29.557555,
                lon: 34.936473,
              },
              {
                street: 'שמיר',
                lat: 32.168175,
                lon: 34.904566,
              },
            ],
          },
          {
            id: '61151',
            name: 'Shahar',
            type: 'city',
            streets: [
              {
                street: 'שחר',
                lat: 31.779286,
                lon: 35.193196,
              },
              {
                street: 'שחרית',
                lat: 32.172601,
                lon: 34.833639,
              },
              {
                street: 'שחר',
                lat: 31.820385,
                lon: 34.690807,
              },
              {
                street: 'Shaharanpur Road',
                lat: 30.119549,
                lon: 77.278254,
              },
              {
                street: 'Shahara Road',
                lat: 39.087352,
                lon: -104.784591,
              },
            ],
          },
          {
            id: '61153',
            name: 'Shoval',
            type: 'city',
            streets: [
              {
                street: 'שובל',
                lat: 31.322037,
                lon: 34.928313,
              },
              {
                street: 'שובל',
                lat: 31.299959,
                lon: 34.634277,
              },
              {
                street: 'Hovalstraße',
                lat: 48.196169,
                lon: 14.080516,
              },
              {
                street: 'Soval',
                lat: -33.412186,
                lon: -70.632705,
              },
              {
                street: 'שבל',
                lat: 32.471282,
                lon: 34.952079,
              },
            ],
          },
          {
            id: '61154',
            name: 'Shuva',
            type: 'city',
            streets: [
              {
                street: 'Sheva Court',
                lat: 43.729072,
                lon: -79.259017,
              },
              {
                street: 'Suva Close',
                lat: 52.517418,
                lon: 1.015963,
              },
              {
                street: 'Shuna Walk',
                lat: 51.546661,
                lon: -0.088543,
              },
              {
                street: 'Shuna Croft',
                lat: 52.427917,
                lon: -1.435614,
              },
              {
                street: 'Shuna Square',
                lat: 56.209144,
                lon: -3.174047,
              },
            ],
          },
          {
            id: '61155',
            name: 'Talmei Bilu',
            type: 'city',
            streets: [
              {
                street: 'Bíluky',
                lat: 49.467929,
                lon: 13.422641,
              },
              {
                street: 'Bilumbu',
                lat: 16.448915,
                lon: -95.021966,
              },
              {
                street: 'Билушка',
                lat: 44.787422,
                lon: 20.523304,
              },
              {
                street: 'Bill Americo Brusa',
                lat: -38.701532,
                lon: -62.303313,
              },
              {
                street: 'Ailu',
                lat: -31.802442,
                lon: -65.010023,
              },
            ],
          },
          {
            id: '61157',
            name: 'Tidhar',
            type: 'city',
            streets: [
              {
                street: 'תדהר',
                lat: 32.499516,
                lon: 35.49693,
              },
              {
                street: 'תדהר',
                lat: 32.617492,
                lon: 35.284774,
              },
              {
                street: 'תדהר',
                lat: 33.019381,
                lon: 35.287447,
              },
              {
                street: 'תדהר',
                lat: 32.704308,
                lon: 35.225055,
              },
              {
                street: 'תדהר',
                lat: 31.25914,
                lon: 35.203451,
              },
            ],
          },
          {
            id: '61158',
            name: 'Urim',
            type: 'city',
            streets: [
              {
                street: 'Urimaco-Termotasajero',
                lat: 7.874948,
                lon: -72.613772,
              },
              {
                street: 'Urimendi Kalea',
                lat: 43.346031,
                lon: -3.009819,
              },
              {
                street: 'Urimontie',
                lat: 62.417977,
                lon: 28.067483,
              },
              {
                street: 'Likolahdentie',
                lat: 63.374651,
                lon: 27.95514,
              },
              {
                street: 'Urimolahdentie',
                lat: 63.34775,
                lon: 27.905319,
              },
            ],
          },
          {
            id: '61159',
            name: 'Yad Natan',
            type: 'city',
            streets: [
              {
                street: 'Nataniel Morcillo',
                lat: -31.432176,
                lon: -64.161052,
              },
              {
                street: 'Natanya Boulevard',
                lat: 44.241346,
                lon: -79.456089,
              },
              {
                street: 'Natanaelka',
                lat: 50.11177,
                lon: 14.378595,
              },
              {
                street: 'Natantie',
                lat: 62.980755,
                lon: 23.863352,
              },
              {
                street: 'Natanbury',
                lat: 50.99196,
                lon: -1.7446,
              },
            ],
          },
          {
            id: '61160',
            name: 'Yahel',
            type: 'city',
            streets: [
              {
                street: 'Nahel Huapi',
                lat: -27.43007,
                lon: -55.934946,
              },
              {
                street: 'Rahel-Varnhagen-Promenade',
                lat: 52.50189,
                lon: 13.39011,
              },
              {
                street: 'Rahel-Hirsch-Straße',
                lat: 52.522462,
                lon: 13.368201,
              },
              {
                street: 'Rahel-Hirsch-Weg',
                lat: 52.527231,
                lon: 13.377511,
              },
              {
                street: 'Rahel-Straus-Weg',
                lat: 48.123874,
                lon: 11.635657,
              },
            ],
          },
          {
            id: '61161',
            name: 'Yeruham',
            type: 'city',
            streets: [
              {
                street: 'צייזל ירוחם',
                lat: 32.801532,
                lon: 35.014704,
              },
              {
                street: 'ירוחם משל',
                lat: 32.103051,
                lon: 34.802582,
              },
              {
                street: 'ירוחם',
                lat: 31.814996,
                lon: 34.784615,
              },
            ],
          },
          {
            id: '61162',
            name: 'Yinon',
            type: 'city',
            streets: [
              {
                street: 'יינון',
                lat: 32.067071,
                lon: 34.799003,
              },
              {
                street: 'Pinon Close',
                lat: -28.133283,
                lon: 153.465184,
              },
              {
                street: 'Rua Ninon Müller Piske',
                lat: -25.449525,
                lon: -49.218764,
              },
              {
                street: 'Winon',
                lat: 47.219018,
                lon: 8.194711,
              },
              {
                street: 'Zinon Kitieus',
                lat: 34.796342,
                lon: 33.345463,
              },
            ],
          },
          {
            id: '61163',
            name: 'Yotvata',
            type: 'city',
            streets: [
              {
                street: 'Yotvata',
                lat: 29.893573,
                lon: 35.060246,
              },
              {
                street: 'יוטבתה',
                lat: 32.008272,
                lon: 34.762185,
              },
              {
                street: 'יטבתה',
                lat: 32.010014,
                lon: 34.757908,
              },
              {
                street: 'יטבתה',
                lat: 31.675733,
                lon: 34.579004,
              },
              {
                street: 'יוטבת',
                lat: 32.034219,
                lon: 34.744593,
              },
            ],
          },
          {
            id: '61164',
            name: 'Zofar',
            type: 'city',
            streets: [
              {
                street: 'حارة زيفار',
                lat: 26.908522,
                lon: 31.439687,
              },
              {
                street: 'נופר',
                lat: 32.494299,
                lon: 35.491408,
              },
              {
                street: 'נופר',
                lat: 32.526013,
                lon: 35.332995,
              },
              {
                street: 'נופר',
                lat: 32.613615,
                lon: 35.280621,
              },
              {
                street: 'צוער',
                lat: 31.78275,
                lon: 35.211132,
              },
            ],
          },
          {
            id: '61165',
            name: 'Zohar',
            type: 'city',
            streets: [
              {
                street: 'זוהר',
                lat: 32.787215,
                lon: 34.968568,
              },
              {
                street: 'זהר',
                lat: 31.327405,
                lon: 34.947781,
              },
              {
                street: 'זוהר',
                lat: 32.087235,
                lon: 34.813903,
              },
              {
                street: 'זהרה לביטוב',
                lat: 31.975107,
                lon: 34.794012,
              },
              {
                street: 'זוהר טל',
                lat: 32.171832,
                lon: 34.802821,
              },
            ],
          },
          {
            id: '61166',
            name: 'Barkai',
            type: 'city',
            streets: [
              {
                street: 'Barkaiztegi Bidea',
                lat: 43.293006,
                lon: -1.958534,
              },
              {
                street: 'ברקאי',
                lat: 32.477741,
                lon: 35.002039,
              },
              {
                street: 'ברקאי',
                lat: 32.225825,
                lon: 34.99613,
              },
              {
                street: 'ברקאי',
                lat: 32.076961,
                lon: 34.82634,
              },
              {
                street: 'ברקאי',
                lat: 31.793405,
                lon: 34.719895,
              },
            ],
          },
          {
            id: '61168',
            name: 'Caesarea',
            type: 'city',
            streets: [
              {
                street: 'Καισάρειας',
                lat: 37.933435,
                lon: 22.948181,
              },
              {
                street: 'קיסריה',
                lat: 31.057392,
                lon: 35.037983,
              },
              {
                street: 'קיסריה',
                lat: 32.816042,
                lon: 34.995544,
              },
              {
                street: 'קיסריה',
                lat: 31.249029,
                lon: 34.765535,
              },
              {
                street: 'קיסריה',
                lat: 31.666075,
                lon: 34.580606,
              },
            ],
          },
          {
            id: '61169',
            name: 'Fureidis',
            type: 'city',
            streets: [],
          },
          {
            id: '61170',
            name: 'Gan Hashomron',
            type: 'city',
            streets: [
              {
                street: 'השומרון',
                lat: 32.498999,
                lon: 35.492141,
              },
              {
                street: 'השומרון',
                lat: 32.682963,
                lon: 35.232051,
              },
              {
                street: 'השומרון',
                lat: 32.328735,
                lon: 35.005365,
              },
              {
                street: 'השומרון',
                lat: 32.283647,
                lon: 34.915473,
              },
              {
                street: 'השומרון',
                lat: 32.312402,
                lon: 34.934415,
              },
            ],
          },
          {
            id: '61171',
            name: 'Habonim',
            type: 'city',
            streets: [
              {
                street: 'הבונים',
                lat: 32.708752,
                lon: 35.131733,
              },
              {
                street: 'הבונים',
                lat: 32.781607,
                lon: 35.035275,
              },
              {
                street: 'הבונים החופשיים',
                lat: 32.788123,
                lon: 35.040205,
              },
              {
                street: 'הבונים',
                lat: 32.428137,
                lon: 34.952184,
              },
              {
                street: 'הבונים',
                lat: 32.280224,
                lon: 34.861598,
              },
            ],
          },
          {
            id: '61172',
            name: 'Hadera',
            type: 'city',
            streets: [
              {
                street: 'Haderäckerweg',
                lat: 47.827374,
                lon: 16.272356,
              },
              {
                street: 'Hadera Place',
                lat: 44.244955,
                lon: -79.461131,
              },
              {
                street: 'Haderastraße',
                lat: 49.41818,
                lon: 11.037795,
              },
              {
                street: 'חדרה',
                lat: 32.807695,
                lon: 35.003375,
              },
              {
                street: 'צומת חדרה',
                lat: 32.438582,
                lon: 34.910683,
              },
            ],
          },
          {
            id: '61174',
            name: 'Haifa',
            type: 'city',
            streets: [
              {
                street: 'Haifa Place',
                lat: -27.932372,
                lon: 153.024254,
              },
              {
                street: 'Tarifa Lane',
                lat: -32.10318,
                lon: 115.774229,
              },
              {
                street: 'Haïfastraat',
                lat: 51.262955,
                lon: 4.414221,
              },
              {
                street: 'Avenue Haifa',
                lat: 45.539022,
                lon: -73.753327,
              },
              {
                street: 'Haifa',
                lat: -33.422498,
                lon: -70.733538,
              },
            ],
          },
          {
            id: '61175',
            name: 'Ibtin',
            type: 'city',
            streets: [
              {
                street: 'Itín',
                lat: -30.963449,
                lon: -64.270212,
              },
              {
                street: 'Ibatin',
                lat: -27.25962,
                lon: -65.542238,
              },
              {
                street: 'Bitin Road',
                lat: 17.072422,
                lon: 120.895016,
              },
              {
                street: 'Itin Street',
                lat: 40.46139,
                lon: -79.992974,
              },
              {
                street: 'Bitin Drive',
                lat: 61.999308,
                lon: -149.967652,
              },
            ],
          },
          {
            id: '61176',
            name: 'Kerem Maharal',
            type: 'city',
            streets: [
              {
                street: 'Chemin De Kerem',
                lat: 48.074692,
                lon: -4.335048,
              },
              {
                street: 'כרם',
                lat: 32.749606,
                lon: 35.421997,
              },
              {
                street: 'מהרל',
                lat: 32.443355,
                lon: 34.919536,
              },
              {
                street: 'כרם',
                lat: 31.998679,
                lon: 34.868137,
              },
              {
                street: 'מהרל',
                lat: 32.084921,
                lon: 34.831712,
              },
            ],
          },
          {
            id: '61179',
            name: 'Megadim',
            type: 'city',
            streets: [
              {
                street: 'מגדים',
                lat: 31.777273,
                lon: 35.184113,
              },
              {
                street: 'מגדים',
                lat: 32.08802,
                lon: 34.958303,
              },
              {
                street: 'מגדים',
                lat: 32.088619,
                lon: 34.822161,
              },
              {
                street: 'מגדים',
                lat: 31.696985,
                lon: 34.706261,
              },
            ],
          },
          {
            id: '61180',
            name: 'Mishmarot',
            type: 'city',
            streets: [
              {
                street: 'משמרות',
                lat: 32.483611,
                lon: 34.9821,
              },
            ],
          },
          {
            id: '61181',
            name: 'Nahsholim',
            type: 'city',
            streets: [
              {
                street: 'נחשונים',
                lat: 32.443198,
                lon: 34.922146,
              },
            ],
          },
          {
            id: '61182',
            name: 'Nesher',
            type: 'city',
            streets: [
              {
                street: 'נשר',
                lat: 32.902831,
                lon: 35.290782,
              },
              {
                street: 'נשר',
                lat: 33.002225,
                lon: 35.106797,
              },
              {
                street: 'נשר',
                lat: 32.088171,
                lon: 34.962192,
              },
              {
                street: 'נשר',
                lat: 32.464402,
                lon: 34.950006,
              },
              {
                street: 'נשר',
                lat: 32.185365,
                lon: 34.926435,
              },
            ],
          },
          {
            id: '61183',
            name: 'Oranim',
            type: 'city',
            streets: [
              {
                street: 'אורנים',
                lat: 32.780074,
                lon: 35.52121,
              },
              {
                street: 'אורנים',
                lat: 32.612858,
                lon: 35.28486,
              },
              {
                street: 'אורנים',
                lat: 31.919175,
                lon: 35.017916,
              },
              {
                street: 'אורנים',
                lat: 32.068293,
                lon: 34.846765,
              },
              {
                street: 'אורנים',
                lat: 31.521434,
                lon: 34.59936,
              },
            ],
          },
          {
            id: '61185',
            name: 'Regavim',
            type: 'city',
            streets: [
              {
                street: 'רגבים',
                lat: 32.309046,
                lon: 35.006616,
              },
              {
                street: 'רגבים',
                lat: 31.959284,
                lon: 34.877627,
              },
              {
                street: 'רגבים',
                lat: 32.14954,
                lon: 34.839886,
              },
              {
                street: 'רגבים',
                lat: 31.914084,
                lon: 34.786623,
              },
            ],
          },
          {
            id: '61186',
            name: 'Rekhasim',
            type: 'city',
            streets: [
              {
                street: 'רכסים',
                lat: 31.713426,
                lon: 35.099165,
              },
              {
                street: 'רכסים',
                lat: 30.986423,
                lon: 34.914454,
              },
            ],
          },
          {
            id: '61188',
            name: 'Tirat Carmel',
            type: 'city',
            streets: [
              {
                street: 'Tirat-Carmel-Straße',
                lat: 51.123009,
                lon: 6.903625,
              },
              {
                street: 'Tirat-Carmel Utca',
                lat: 47.079674,
                lon: 17.92597,
              },
              {
                street: 'כרמל',
                lat: 32.216893,
                lon: 34.988745,
              },
              {
                street: 'כרמל',
                lat: 32.307418,
                lon: 34.964281,
              },
              {
                street: 'כרמל',
                lat: 32.260577,
                lon: 34.898422,
              },
            ],
          },
          {
            id: '61190',
            name: 'Umm Al-qutuf',
            type: 'city',
            streets: [
              {
                street: 'Rua 05',
                lat: -10.206391,
                lon: -48.36115,
              },
              {
                street: 'Alameda',
                lat: -0.734308,
                lon: -48.512513,
              },
              {
                street: 'Alameda',
                lat: -0.724042,
                lon: -48.507927,
              },
              {
                street: 'Albertville',
                lat: 51.094679,
                lon: -115.386382,
              },
              {
                street: 'Al',
                lat: 48.089082,
                lon: 8.608823,
              },
            ],
          },
          {
            id: '61191',
            name: 'Umm El Fahm',
            type: 'city',
            streets: [
              {
                street: 'حارة الفحم',
                lat: 30.129931,
                lon: 31.338305,
              },
              {
                street: 'حارة الفهميه',
                lat: 30.035085,
                lon: 31.27263,
              },
              {
                street: 'شارع الفحاميه',
                lat: 30.056858,
                lon: 31.231172,
              },
              {
                street: 'شارع الفحم',
                lat: 29.084804,
                lon: 31.097071,
              },
              {
                street: 'شارع الحاج فهمي عوض',
                lat: 30.161935,
                lon: 31.324079,
              },
            ],
          },
          {
            id: '61192',
            name: 'Usha',
            type: 'city',
            streets: [
              {
                street: 'Ushaia',
                lat: -34.748493,
                lon: -58.71184,
              },
              {
                street: 'Ushaw Road',
                lat: 54.973976,
                lon: -1.50547,
              },
              {
                street: 'Ushaw Terrace',
                lat: 54.779485,
                lon: -1.647161,
              },
              {
                street: 'Ushaw Villas',
                lat: 54.776768,
                lon: -1.647715,
              },
              {
                street: 'אושה',
                lat: 31.780269,
                lon: 35.210327,
              },
            ],
          },
          {
            id: '61193',
            name: 'Yagur',
            type: 'city',
            streets: [
              {
                street: 'יגור',
                lat: 31.075068,
                lon: 35.028902,
              },
              {
                street: 'יגור',
                lat: 32.796208,
                lon: 35.020482,
              },
              {
                street: 'יגור',
                lat: 32.057624,
                lon: 34.796574,
              },
              {
                street: 'Agur Avdullai',
                lat: 40.730251,
                lon: 19.568838,
              },
              {
                street: 'Yapur Chehuen',
                lat: -41.245953,
                lon: -68.707756,
              },
            ],
          },
          {
            id: '61194',
            name: 'Zerufa',
            type: 'city',
            streets: [
              {
                street: 'Zerafa Place',
                lat: -33.722629,
                lon: 150.892497,
              },
            ],
          },
          {
            id: '61196',
            name: 'Beit Meir',
            type: 'city',
            streets: [
              {
                street: 'Meyra Dufin',
                lat: 44.557698,
                lon: 7.354101,
              },
              {
                street: "Localita' Meira Del Mes",
                lat: 44.532039,
                lon: 7.347469,
              },
              {
                street: 'Meira Masserot',
                lat: 44.564086,
                lon: 7.303124,
              },
              {
                street: 'Borgata Meire',
                lat: 44.588298,
                lon: 7.375542,
              },
              {
                street: 'Best Street',
                lat: -26.892708,
                lon: 26.650582,
              },
            ],
          },
          {
            id: '61197',
            name: 'Bet Shemesh',
            type: 'city',
            streets: [
              {
                street: 'Bet-Shemesh-Straße',
                lat: 51.504557,
                lon: 10.817131,
              },
              {
                street: 'עוקף בית שמש',
                lat: 31.729388,
                lon: 35.00593,
              },
              {
                street: "ב'",
                lat: 32.051468,
                lon: 34.807688,
              },
              {
                street: 'Bet',
                lat: 57.794047,
                lon: 13.903033,
              },
              {
                street: 'Sheesh Mahal Apartments',
                lat: 28.723263,
                lon: 77.154246,
              },
            ],
          },
          {
            id: '61199',
            name: 'Gefen',
            type: 'city',
            streets: [
              {
                street: 'דרך גפן',
                lat: 33.219083,
                lon: 35.615995,
              },
              {
                street: 'גפן',
                lat: 32.716242,
                lon: 35.487517,
              },
              {
                street: 'גפן',
                lat: 32.801199,
                lon: 35.50015,
              },
              {
                street: 'גפן',
                lat: 32.685846,
                lon: 35.418423,
              },
              {
                street: 'גפן',
                lat: 32.634849,
                lon: 35.334208,
              },
            ],
          },
          {
            id: '61200',
            name: 'Jerusalem',
            type: 'city',
            streets: [
              {
                street: 'شارع القدس',
                lat: 25.118134,
                lon: 56.340535,
              },
              {
                street: 'Jerusalem',
                lat: -34.668606,
                lon: -58.665413,
              },
              {
                street: 'Jerusalem',
                lat: -37.361097,
                lon: -59.117255,
              },
              {
                street: 'Jerusalem',
                lat: -22.534832,
                lon: -63.790234,
              },
              {
                street: 'Йерусалим',
                lat: 42.657051,
                lon: 23.376725,
              },
            ],
          },
          {
            id: '61201',
            name: 'Luzit',
            type: 'city',
            streets: [
              {
                street: 'Rua Luzitânia Isabel Paes Segallio',
                lat: -22.975872,
                lon: -47.172019,
              },
              {
                street: 'Лузіцька Вулиця',
                lat: 51.777891,
                lon: 32.770371,
              },
              {
                street: 'Luzitania Avenue',
                lat: 42.619034,
                lon: -70.652151,
              },
              {
                street: 'Luzita Lane',
                lat: 29.524689,
                lon: -98.543523,
              },
              {
                street: 'Rua Luzio Gonçalves Trindade',
                lat: -22.878872,
                lon: -42.049101,
              },
            ],
          },
          {
            id: '61203',
            name: 'Mevo Beitar',
            type: 'city',
            streets: [
              {
                street: 'Beitar',
                lat: 32.999896,
                lon: 35.297478,
              },
              {
                street: 'ביתר',
                lat: 31.755238,
                lon: 35.224632,
              },
              {
                street: 'ביתר',
                lat: 32.67159,
                lon: 35.230674,
              },
              {
                street: 'ביתר',
                lat: 32.813384,
                lon: 35.125435,
              },
              {
                street: 'ביתר',
                lat: 32.804333,
                lon: 35.001033,
              },
            ],
          },
          {
            id: '61204',
            name: 'Naham',
            type: 'city',
            streets: [
              {
                street: 'شارع النهام أحمد جاسم بوطبنية',
                lat: 26.24791,
                lon: 50.609054,
              },
              {
                street: 'Nahama Road',
                lat: 44.497241,
                lon: -70.898867,
              },
              {
                street: 'Naham Place',
                lat: 33.153875,
                lon: -80.304848,
              },
              {
                street: 'Nahama Lane',
                lat: 32.924628,
                lon: -117.215373,
              },
              {
                street: 'Aham',
                lat: 48.296415,
                lon: 14.07983,
              },
            ],
          },
          {
            id: '61205',
            name: 'Nahshon',
            type: 'city',
            streets: [
              {
                street: 'נחשון',
                lat: 32.487878,
                lon: 35.493712,
              },
              {
                street: 'נחשון',
                lat: 31.764581,
                lon: 35.226215,
              },
              {
                street: 'נחשון',
                lat: 32.766536,
                lon: 35.035225,
              },
              {
                street: 'נחשון',
                lat: 32.789967,
                lon: 34.985302,
              },
              {
                street: 'נחשון',
                lat: 29.564196,
                lon: 34.948241,
              },
            ],
          },
          {
            id: '61206',
            name: 'Nes Harim',
            type: 'city',
            streets: [
              {
                street: 'נס הרים',
                lat: 32.045544,
                lon: 34.776856,
              },
              {
                street: 'Harima Vkt',
                lat: 59.156407,
                lon: 23.529004,
              },
              {
                street: 'Nes',
                lat: 65.363183,
                lon: -13.761,
              },
              {
                street: 'Nes',
                lat: 65.877816,
                lon: -17.406083,
              },
              {
                street: 'Nes',
                lat: 65.906955,
                lon: -18.090155,
              },
            ],
          },
          {
            id: '61207',
            name: 'Neve Ilan',
            type: 'city',
            streets: [
              {
                street: 'Via Neve',
                lat: 45.477175,
                lon: 9.354313,
              },
              {
                street: 'Nueve',
                lat: 20.487788,
                lon: -97.444411,
              },
              {
                street: 'Rue Neuve',
                lat: 43.81678,
                lon: 4.329538,
              },
              {
                street: 'Calle Nueve',
                lat: 20.533515,
                lon: -97.422517,
              },
              {
                street: 'Calle Nueve',
                lat: 20.54645,
                lon: -97.445161,
              },
            ],
          },
          {
            id: '61208',
            name: 'Shoresh',
            type: 'city',
            streets: [
              {
                street: 'משעול שורש בסלע',
                lat: 33.061913,
                lon: 35.218304,
              },
              {
                street: 'Shores Hill Road',
                lat: 38.453537,
                lon: -78.996794,
              },
              {
                street: 'חורש האלונים',
                lat: 32.708141,
                lon: 35.17377,
              },
              {
                street: 'Horesh',
                lat: 32.722724,
                lon: 35.10686,
              },
              {
                street: 'חורש',
                lat: 31.998279,
                lon: 34.871532,
              },
            ],
          },
          {
            id: '61209',
            name: 'Tarum',
            type: 'city',
            streets: [
              {
                street: 'Tarumá',
                lat: -27.086211,
                lon: -54.835577,
              },
              {
                street: 'Tarumá',
                lat: -27.483431,
                lon: -55.109864,
              },
              {
                street: 'Tarumá',
                lat: -27.467589,
                lon: -55.871009,
              },
              {
                street: 'Rua Tarumirim',
                lat: -19.505274,
                lon: -42.545127,
              },
              {
                street: 'Estrada Tarumã 040',
                lat: -22.761554,
                lon: -50.574373,
              },
            ],
          },
          {
            id: '61210',
            name: 'Tirosh',
            type: 'city',
            streets: [
              {
                street: 'תירוש',
                lat: 33.037623,
                lon: 35.469861,
              },
              {
                street: 'תירוש',
                lat: 32.743057,
                lon: 35.424197,
              },
              {
                street: 'תירוש',
                lat: 32.697563,
                lon: 35.32019,
              },
              {
                street: 'תירוש',
                lat: 32.911418,
                lon: 35.285683,
              },
              {
                street: 'תירוש',
                lat: 32.009873,
                lon: 34.950467,
              },
            ],
          },
          {
            id: '61211',
            name: 'Zanoah',
            type: 'city',
            streets: [
              {
                street: 'Manoah Drive',
                lat: -35.020296,
                lon: 138.686301,
              },
              {
                street: 'Janoah Avenue',
                lat: 42.309208,
                lon: -85.193988,
              },
              {
                street: 'Tanoah Valley Lane',
                lat: 38.422601,
                lon: -92.156004,
              },
              {
                street: 'Panoah Street',
                lat: 42.503321,
                lon: -96.420202,
              },
              {
                street: 'Manoah Ln',
                lat: 41.56459,
                lon: -77.33879,
              },
            ],
          },
          {
            id: '61212',
            name: 'Ahituv',
            type: 'city',
            streets: [],
          },
          {
            id: '61213',
            name: 'Avihayil',
            type: 'city',
            streets: [
              {
                street: 'אביחיל',
                lat: 31.994398,
                lon: 34.746653,
              },
              {
                street: 'אביגיל',
                lat: 32.795127,
                lon: 34.990763,
              },
              {
                street: 'אביחייל',
                lat: 32.346123,
                lon: 34.896848,
              },
              {
                street: 'אביגיל הכרמלית',
                lat: 31.633669,
                lon: 34.771196,
              },
            ],
          },
          {
            id: '61214',
            name: 'Bahan',
            type: 'city',
            streets: [
              {
                street: 'Bahanas Close',
                lat: -31.87528,
                lon: 152.36656,
              },
              {
                street: 'Bahanaga Overbridge',
                lat: 21.341124,
                lon: 86.761059,
              },
              {
                street: 'Bahanpur - Gadankeri Highway',
                lat: 15.734733,
                lon: 75.888871,
              },
              {
                street: 'Bahanpur - Gadankeri Highway',
                lat: 16.047663,
                lon: 75.774804,
              },
              {
                street: 'Bahanpur - Gadankeri Highway',
                lat: 16.159568,
                lon: 75.735183,
              },
            ],
          },
          {
            id: '61215',
            name: 'Bareket',
            type: 'city',
            streets: [
              {
                street: 'ברקת',
                lat: 32.969452,
                lon: 35.51008,
              },
              {
                street: 'Bareket',
                lat: 33.009307,
                lon: 35.436678,
              },
              {
                street: 'ברקת',
                lat: 32.69187,
                lon: 35.419536,
              },
              {
                street: 'ברקת',
                lat: 32.6159,
                lon: 35.30463,
              },
              {
                street: 'ברקת',
                lat: 32.733372,
                lon: 35.246757,
              },
            ],
          },
          {
            id: '61216',
            name: 'Beit Arif',
            type: 'city',
            streets: [
              {
                street: 'נחל בית עריף',
                lat: 31.990151,
                lon: 34.96317,
              },
              {
                street: 'Ариф Мустафа',
                lat: 41.120628,
                lon: 20.791915,
              },
              {
                street: 'Beit',
                lat: -28.732955,
                lon: 24.767263,
              },
              {
                street: 'Weit',
                lat: 46.741757,
                lon: 14.135369,
              },
              {
                street: 'Reit',
                lat: 47.983923,
                lon: 13.051512,
              },
            ],
          },
          {
            id: '61217',
            name: 'Beit Halevi',
            type: 'city',
            streets: [
              {
                street: 'בית הלוי',
                lat: 31.977193,
                lon: 34.77136,
              },
              {
                street: 'הלוי',
                lat: 32.419399,
                lon: 34.946045,
              },
              {
                street: 'הלוי',
                lat: 32.075325,
                lon: 34.881657,
              },
              {
                street: 'Beit',
                lat: -28.732955,
                lon: 24.767263,
              },
              {
                street: 'Балеви Ливади',
                lat: 42.611625,
                lon: 23.341936,
              },
            ],
          },
          {
            id: '61218',
            name: 'Beit Hanan',
            type: 'city',
            streets: [
              {
                street: 'Hanan Pacha',
                lat: -31.333182,
                lon: -64.280658,
              },
              {
                street: 'Hanani',
                lat: 25.644553,
                lon: -100.057959,
              },
              {
                street: 'Beit',
                lat: -28.732955,
                lon: 24.767263,
              },
              {
                street: 'Haanstraße',
                lat: 48.595463,
                lon: 15.664741,
              },
              {
                street: 'Hanau',
                lat: 48.118844,
                lon: 15.458157,
              },
            ],
          },
          {
            id: '61219',
            name: 'Beit Yanai',
            type: 'city',
            streets: [
              {
                street: 'Best Street',
                lat: 44.667875,
                lon: -63.579495,
              },
              {
                street: 'Bent Lane',
                lat: 45.975728,
                lon: -63.905752,
              },
              {
                street: 'Bent Road',
                lat: 44.974029,
                lon: -63.982588,
              },
              {
                street: 'Bent Street',
                lat: 45.648201,
                lon: -64.051203,
              },
              {
                street: 'Best Road',
                lat: 45.041554,
                lon: -64.575724,
              },
            ],
          },
          {
            id: '61222',
            name: 'Burgata',
            type: 'city',
            streets: [
              {
                street: 'Burgatan',
                lat: 57.79143,
                lon: 14.246907,
              },
              {
                street: 'Burgate Lane',
                lat: 52.571226,
                lon: 1.358216,
              },
              {
                street: 'Burgate Close',
                lat: 51.800788,
                lon: 1.119613,
              },
              {
                street: 'Burgate',
                lat: 51.278672,
                lon: 1.082649,
              },
              {
                street: 'Burgate Lane',
                lat: 51.277737,
                lon: 1.084051,
              },
            ],
          },
          {
            id: '61223',
            name: 'Einat',
            type: 'city',
            streets: [
              {
                street: 'Einathweg',
                lat: 47.40821,
                lon: 12.430159,
              },
              {
                street: 'מעבר חקלאי עינת',
                lat: 32.085962,
                lon: 34.933279,
              },
              {
                street: 'Einattenhof',
                lat: 51.556013,
                lon: 5.688739,
              },
              {
                street: 'Einat Cove',
                lat: 35.158765,
                lon: -89.839543,
              },
              {
                street: 'Ebnat',
                lat: 47.884257,
                lon: 13.353814,
              },
            ],
          },
          {
            id: '61226',
            name: 'Elyashiv',
            type: 'city',
            streets: [
              {
                street: 'אלישיב',
                lat: 32.077123,
                lon: 34.917352,
              },
              {
                street: 'אלישיב',
                lat: 32.071645,
                lon: 34.820531,
              },
              {
                street: 'אלישיב',
                lat: 32.069874,
                lon: 34.767437,
              },
            ],
          },
          {
            id: '61227',
            name: 'Even Yehuda',
            type: 'city',
            streets: [
              {
                street: 'County Road 700 East',
                lat: 38.952622,
                lon: -89.122594,
              },
              {
                street: 'Even Avenue',
                lat: 37.057544,
                lon: -94.573018,
              },
              {
                street: 'Yehuda Way',
                lat: 40.04653,
                lon: -74.21612,
              },
              {
                street: 'Even',
                lat: 31.95622,
                lon: -95.26207,
              },
              {
                street: 'Carrer De Yehudi Menuhin',
                lat: 41.628012,
                lon: 0.633682,
              },
            ],
          },
          {
            id: '61228',
            name: 'Eyal',
            type: 'city',
            streets: [
              {
                street: 'מעבר אייל',
                lat: 31.958158,
                lon: 34.893135,
              },
              {
                street: 'Eyalet Sokağı',
                lat: 41.084946,
                lon: 28.999069,
              },
              {
                street: 'Eyal Ave',
                lat: 28.18575,
                lon: -81.15614,
              },
              {
                street: 'Eyan Circuit',
                lat: -13.360076,
                lon: 141.724402,
              },
              {
                street: 'Бял Крем',
                lat: 43.221986,
                lon: 27.912318,
              },
            ],
          },
          {
            id: '61229',
            name: 'Gan Hadarom',
            type: 'city',
            streets: [
              {
                street: 'הדרום',
                lat: 31.516424,
                lon: 34.919219,
              },
              {
                street: 'הדרום',
                lat: 32.048557,
                lon: 34.878568,
              },
              {
                street: 'הדרום',
                lat: 31.603635,
                lon: 34.773775,
              },
              {
                street: 'Gagarinova',
                lat: 48.242419,
                lon: 17.698275,
              },
              {
                street: 'הדרים',
                lat: 32.661664,
                lon: 35.55607,
              },
            ],
          },
          {
            id: '61230',
            name: 'Gan Yavne',
            type: 'city',
            streets: [
              {
                street: 'Gagarinova',
                lat: 48.242419,
                lon: 17.698275,
              },
              {
                street: 'Yaven',
                lat: -53.803244,
                lon: -67.655259,
              },
              {
                street: 'Ravne',
                lat: 44.430729,
                lon: 18.011725,
              },
              {
                street: 'Ravne',
                lat: 44.61296,
                lon: 17.978858,
              },
              {
                street: 'Rua Yvane Bandeira',
                lat: -4.11358,
                lon: -38.486861,
              },
            ],
          },
          {
            id: '61233',
            name: 'Gedera',
            type: 'city',
            streets: [
              {
                street: 'Gederamsevej',
                lat: 55.72874,
                lon: 8.181828,
              },
              {
                street: 'גדרה',
                lat: 31.793981,
                lon: 35.19941,
              },
              {
                street: 'גדרה',
                lat: 32.805451,
                lon: 34.99065,
              },
              {
                street: 'גדרה',
                lat: 32.105301,
                lon: 34.875375,
              },
              {
                street: 'גדרה',
                lat: 32.069625,
                lon: 34.769382,
              },
            ],
          },
          {
            id: '61235',
            name: 'Gibton',
            type: 'city',
            streets: [
              {
                street: 'גבתון',
                lat: 32.100444,
                lon: 34.95842,
              },
              {
                street: 'Gibson',
                lat: -36.401439,
                lon: -56.949547,
              },
              {
                street: 'Gibson',
                lat: -34.799837,
                lon: -58.426388,
              },
              {
                street: 'Gibson',
                lat: -34.638732,
                lon: -58.421316,
              },
              {
                street: 'Ginton Court',
                lat: 43.214532,
                lon: -79.712778,
              },
            ],
          },
          {
            id: '61236',
            name: 'Gimzo',
            type: 'city',
            streets: [
              {
                street: 'Hímző Utca',
                lat: 46.427016,
                lon: 19.485675,
              },
              {
                street: 'Hímző Utca',
                lat: 47.595357,
                lon: 19.059491,
              },
              {
                street: '김포대로',
                lat: 37.666329,
                lon: 126.65146,
              },
            ],
          },
          {
            id: '61238',
            name: 'Hadasim',
            type: 'city',
            streets: [
              {
                street: 'דרך הדסים',
                lat: 33.22197,
                lon: 35.617065,
              },
              {
                street: 'הדסים',
                lat: 32.46443,
                lon: 34.978454,
              },
              {
                street: 'Hadasia Gardens',
                lat: 54.56843,
                lon: -1.349041,
              },
              {
                street: 'הדרים',
                lat: 32.661664,
                lon: 35.55607,
              },
              {
                street: 'הדסים',
                lat: 32.613621,
                lon: 35.286984,
              },
            ],
          },
          {
            id: '61239',
            name: 'Hadid',
            type: 'city',
            streets: [
              {
                street: 'شارع الحديد و النادى',
                lat: 31.253352,
                lon: 32.285985,
              },
              {
                street: 'شارع الحديد و الصلب',
                lat: 29.780935,
                lon: 31.308149,
              },
              {
                street: 'شارع الحديد و الصلب',
                lat: 29.834218,
                lon: 31.298078,
              },
              {
                street: 'شارع الحديد و الصلب القديم',
                lat: 29.839357,
                lon: 31.298065,
              },
              {
                street: 'شارع الحديد و الصلب',
                lat: 29.992728,
                lon: 31.279753,
              },
            ],
          },
          {
            id: '61240',
            name: 'Hagor',
            type: 'city',
            streets: [
              {
                street: 'Hagörsvägen',
                lat: 62.531091,
                lon: 21.146029,
              },
              {
                street: 'הגורן',
                lat: 33.27872,
                lon: 35.581625,
              },
              {
                street: 'הגורן',
                lat: 32.706134,
                lon: 35.507116,
              },
              {
                street: 'הגורן',
                lat: 32.960337,
                lon: 35.511237,
              },
              {
                street: 'הגורן',
                lat: 32.757369,
                lon: 35.409594,
              },
            ],
          },
          {
            id: '61241',
            name: 'Haniel',
            type: 'city',
            streets: [
              {
                street: 'Hanielweg',
                lat: 52.419775,
                lon: 13.361461,
              },
              {
                street: 'Hanielstraße',
                lat: 47.626437,
                lon: 12.992521,
              },
              {
                street: 'Hanielstraße',
                lat: 49.696322,
                lon: 8.35475,
              },
              {
                street: 'Hanielstraße',
                lat: 51.461524,
                lon: 7.259675,
              },
              {
                street: 'Hanielstraße',
                lat: 51.500207,
                lon: 7.045862,
              },
            ],
          },
          {
            id: '61242',
            name: 'Hemed',
            type: 'city',
            streets: [
              {
                street: 'Rua Hemedina Patrício',
                lat: -24.109752,
                lon: -46.663893,
              },
              {
                street: 'חמד',
                lat: 31.996891,
                lon: 34.737847,
              },
              {
                street: 'חמד',
                lat: 31.662936,
                lon: 34.567359,
              },
              {
                street: 'Hemes Close',
                lat: -27.948554,
                lon: 153.318395,
              },
              {
                street: 'Hemet Crescent',
                lat: -34.636609,
                lon: 148.015559,
              },
            ],
          },
          {
            id: '61243',
            name: 'Herut',
            type: 'city',
            streets: [
              {
                street: 'חרות',
                lat: 32.522932,
                lon: 34.951191,
              },
              {
                street: 'חרות',
                lat: 32.090792,
                lon: 34.81433,
              },
              {
                street: 'חירותנו',
                lat: 32.045332,
                lon: 34.791673,
              },
              {
                street: 'חירות',
                lat: 31.866023,
                lon: 34.743588,
              },
              {
                street: 'Herutún',
                lat: 64.210022,
                lon: -20.740257,
              },
            ],
          },
          {
            id: '61244',
            name: 'Hod Hasharon',
            type: 'city',
            streets: [
              {
                street: 'השרון',
                lat: 32.498178,
                lon: 35.492964,
              },
              {
                street: 'הוד',
                lat: 32.881118,
                lon: 35.274889,
              },
              {
                street: 'השרון',
                lat: 32.686991,
                lon: 35.238707,
              },
              {
                street: 'השרון',
                lat: 33.006295,
                lon: 35.104262,
              },
              {
                street: 'שדרות השרון',
                lat: 32.329441,
                lon: 35.014018,
              },
            ],
          },
          {
            id: '61245',
            name: 'Hofit',
            type: 'city',
            streets: [
              {
                street: 'Hofitgasse',
                lat: 46.674907,
                lon: 7.638277,
              },
              {
                street: 'חופית',
                lat: 32.646305,
                lon: 35.194918,
              },
              {
                street: 'חופית',
                lat: 32.81838,
                lon: 35.134946,
              },
              {
                street: 'חופית',
                lat: 32.102796,
                lon: 34.965461,
              },
              {
                street: 'חופית',
                lat: 32.273288,
                lon: 34.93709,
              },
            ],
          },
          {
            id: '61246',
            name: 'Hogla',
            type: 'city',
            streets: [
              {
                street: 'Höglau',
                lat: 47.82197,
                lon: 12.8899,
              },
              {
                street: 'Ylämaankuja',
                lat: 60.355434,
                lon: 25.609002,
              },
              {
                street: 'Ylänkötie',
                lat: 60.321383,
                lon: 25.008782,
              },
              {
                street: 'Ylänkötie',
                lat: 60.24903,
                lon: 24.771172,
              },
              {
                street: 'חוגלה',
                lat: 31.710575,
                lon: 35.100327,
              },
            ],
          },
          {
            id: '61249',
            name: 'Kfar Malal',
            type: 'city',
            streets: [
              {
                street: 'Małałączka',
                lat: 49.561599,
                lon: 18.945422,
              },
              {
                street: 'Mala Cara',
                lat: -34.413975,
                lon: -58.80566,
              },
              {
                street: 'Maal',
                lat: 51.14981,
                lon: 4.97665,
              },
              {
                street: 'Calle Malla Chuma',
                lat: -16.510308,
                lon: -68.226889,
              },
              {
                street: 'Malär',
                lat: 46.982307,
                lon: 9.714198,
              },
            ],
          },
          {
            id: '61250',
            name: 'Kfar Netter',
            type: 'city',
            streets: [
              {
                street: 'כפר נטר',
                lat: 32.273177,
                lon: 34.874096,
              },
              {
                street: 'Nettersheimplein',
                lat: 50.932841,
                lon: 5.572209,
              },
              {
                street: 'Netterville Place',
                lat: 53.527074,
                lon: -6.160763,
              },
              {
                street: 'נטר קרל',
                lat: 32.184176,
                lon: 34.899119,
              },
              {
                street: 'Wetterstraße',
                lat: 47.774385,
                lon: 17.037163,
              },
            ],
          },
          {
            id: '61252',
            name: 'Kfar Yona',
            type: 'city',
            streets: [
              {
                street: 'כפר יונה',
                lat: 32.109275,
                lon: 34.800014,
              },
              {
                street: 'Tona Close',
                lat: -32.919906,
                lon: 151.609005,
              },
              {
                street: 'Lona Close',
                lat: -36.790356,
                lon: 144.289059,
              },
              {
                street: 'Zona Place',
                lat: -31.737613,
                lon: 115.802359,
              },
              {
                street: 'Йонка Маркова',
                lat: 43.99974,
                lon: 22.889236,
              },
            ],
          },
          {
            id: '61253',
            name: 'Kidron',
            type: 'city',
            streets: [
              {
                street: 'Kidron Court',
                lat: 45.470306,
                lon: -66.12682,
              },
              {
                street: 'Kidron Valley Drive',
                lat: 43.756502,
                lon: -79.59315,
              },
              {
                street: 'קדרון',
                lat: 32.606649,
                lon: 35.29878,
              },
              {
                street: 'קדרון',
                lat: 32.790736,
                lon: 34.983291,
              },
              {
                street: 'קידרון',
                lat: 32.037574,
                lon: 34.746446,
              },
            ],
          },
          {
            id: '61254',
            name: 'Lod',
            type: 'city',
            streets: [
              {
                street: 'Loda Bridge',
                lat: 52.82948,
                lon: -9.41758,
              },
              {
                street: 'Lodge Coastal Road',
                lat: 12.371268,
                lon: 92.91846,
              },
              {
                street: 'Lododdi Main Road',
                lat: 17.511235,
                lon: 82.312454,
              },
              {
                street: 'Ldg Road',
                lat: 13.016969,
                lon: 80.22671,
              },
              {
                street: 'Lodhi Khan 4th Street',
                lat: 13.041031,
                lon: 80.242351,
              },
            ],
          },
          {
            id: '61255',
            name: 'Magshimim',
            type: 'city',
            streets: [
              {
                street: 'מגשימים',
                lat: 32.628144,
                lon: 35.320109,
              },
              {
                street: 'מגשימים',
                lat: 31.25123,
                lon: 35.217215,
              },
              {
                street: 'מגשימים',
                lat: 32.472725,
                lon: 35.000601,
              },
              {
                street: 'מגשימים',
                lat: 32.398902,
                lon: 34.916791,
              },
              {
                street: 'מגשימים',
                lat: 32.121506,
                lon: 34.804051,
              },
            ],
          },
          {
            id: '61256',
            name: 'Mazkeret Batya',
            type: 'city',
            streets: [
              {
                street: 'Rue De Mazkeret Batya',
                lat: 48.783428,
                lon: 2.226684,
              },
              {
                street: 'מזכרת בתיה',
                lat: 32.101276,
                lon: 34.874411,
              },
              {
                street: 'מזכרת בתיה',
                lat: 31.781152,
                lon: 34.659215,
              },
              {
                street: 'Batya Ct',
                lat: 36.03303,
                lon: -86.55012,
              },
              {
                street: 'Batea Mahuida',
                lat: -39.228916,
                lon: -70.92218,
              },
            ],
          },
          {
            id: '61257',
            name: 'Mazor',
            type: 'city',
            streets: [
              {
                street: 'Mazoratti',
                lat: -33.25557,
                lon: -60.305628,
              },
              {
                street: 'Pasaje Mazorca',
                lat: -33.375703,
                lon: -70.685703,
              },
              {
                street: 'Calle Mazorca',
                lat: 41.949408,
                lon: -0.468945,
              },
              {
                street: 'Lg Mazorras (Valdenoceda)',
                lat: 42.830807,
                lon: -3.642495,
              },
              {
                street: 'מזור',
                lat: 32.184841,
                lon: 34.89575,
              },
            ],
          },
          {
            id: '61258',
            name: 'Meishar',
            type: 'city',
            streets: [
              {
                street: 'נחל מישר',
                lat: 30.56125,
                lon: 34.994724,
              },
              {
                street: 'מישר',
                lat: 32.092504,
                lon: 34.97714,
              },
              {
                street: 'Meisham',
                lat: 47.941251,
                lon: 12.37755,
              },
              {
                street: 'מישר',
                lat: 31.969243,
                lon: 34.780068,
              },
              {
                street: '梅山路',
                lat: 24.631122,
                lon: 121.728234,
              },
            ],
          },
          {
            id: '61260',
            name: 'Misgav Dov',
            type: 'city',
            streets: [
              {
                street: 'Dovskygasse',
                lat: 48.174607,
                lon: 16.291917,
              },
              {
                street: 'Dovlići',
                lat: 43.821789,
                lon: 18.47938,
              },
              {
                street: 'Dover',
                lat: -33.539044,
                lon: -70.567243,
              },
              {
                street: 'Pasaje Dover',
                lat: -33.36949,
                lon: -70.682734,
              },
              {
                street: 'Dover',
                lat: -36.782772,
                lon: -73.109166,
              },
            ],
          },
          {
            id: '61261',
            name: 'Mishmar Hasharon',
            type: 'city',
            streets: [
              {
                street: 'השרון',
                lat: 32.498178,
                lon: 35.492964,
              },
              {
                street: 'משמר',
                lat: 32.530765,
                lon: 35.484325,
              },
              {
                street: 'משמר',
                lat: 31.26822,
                lon: 35.2261,
              },
              {
                street: 'השרון',
                lat: 32.686991,
                lon: 35.238707,
              },
              {
                street: 'השרון',
                lat: 33.006295,
                lon: 35.104262,
              },
            ],
          },
          {
            id: '61262',
            name: 'Mishmeret',
            type: 'city',
            streets: [
              {
                street: 'משמרת חיים',
                lat: 31.796609,
                lon: 35.200286,
              },
            ],
          },
          {
            id: '61264',
            name: 'Nehalim',
            type: 'city',
            streets: [
              {
                street: 'נחלים',
                lat: 33.228533,
                lon: 35.64546,
              },
              {
                street: 'נחלים',
                lat: 32.876536,
                lon: 35.272568,
              },
              {
                street: 'נחלים',
                lat: 32.731885,
                lon: 35.245047,
              },
              {
                street: 'Nehalem Point',
                lat: 40.060838,
                lon: -86.035818,
              },
              {
                street: 'Nehalem Drive',
                lat: 39.634931,
                lon: -119.697954,
              },
            ],
          },
          {
            id: '61266',
            name: 'Ness Ziona',
            type: 'city',
            streets: [
              {
                street: 'Ness-Ziona-Straße',
                lat: 51.146498,
                lon: 7.015535,
              },
              {
                street: 'נס ציונה',
                lat: 32.10254,
                lon: 34.872922,
              },
              {
                street: 'Ness Road',
                lat: 51.477223,
                lon: 0.211357,
              },
              {
                street: 'Neß',
                lat: 53.548591,
                lon: 9.993221,
              },
              {
                street: 'Ness',
                lat: 58.953267,
                lon: -3.297955,
              },
            ],
          },
          {
            id: '61267',
            name: 'Netanya',
            type: 'city',
            streets: [
              {
                street: 'Netanyastraße',
                lat: 50.570827,
                lon: 8.730138,
              },
              {
                street: 'Natanya Drive',
                lat: -38.055769,
                lon: 145.4898,
              },
              {
                street: 'Natanya Way',
                lat: -37.807906,
                lon: 145.302907,
              },
              {
                street: 'Natanya Boulevard',
                lat: 44.241346,
                lon: -79.456089,
              },
              {
                street: 'Netana Road',
                lat: -38.141984,
                lon: 177.004658,
              },
            ],
          },
          {
            id: '61268',
            name: 'Nir Eliyahu',
            type: 'city',
            streets: [
              {
                street: "אליהו פרומנצ'קו",
                lat: 32.962349,
                lon: 35.498825,
              },
              {
                street: 'אליהו לנקין',
                lat: 31.750175,
                lon: 35.22552,
              },
              {
                street: 'שמעא',
                lat: 31.776025,
                lon: 35.224113,
              },
              {
                street: 'ניר',
                lat: 32.721667,
                lon: 35.108968,
              },
              {
                street: 'אליהו הנביא',
                lat: 32.801201,
                lon: 35.104634,
              },
            ],
          },
          {
            id: '61271',
            name: 'Pedaya',
            type: 'city',
            streets: [
              {
                street: 'Pedayangod-Pattakkal Road',
                lat: 11.989902,
                lon: 75.565783,
              },
              {
                street: 'Педя Човек',
                lat: 42.612529,
                lon: 23.395908,
              },
              {
                street: 'Pedaja',
                lat: 57.966367,
                lon: 27.631743,
              },
              {
                street: 'Pedaja',
                lat: 57.601912,
                lon: 27.225469,
              },
              {
                street: 'Pedaja Tee',
                lat: 58.013103,
                lon: 27.078739,
              },
            ],
          },
          {
            id: '61273',
            name: 'Petahya',
            type: 'city',
            streets: [
              {
                street: 'Petaya Lane',
                lat: 31.879365,
                lon: -106.560045,
              },
            ],
          },
          {
            id: '61274',
            name: 'Porat',
            type: 'city',
            streets: [
              {
                street: 'Portage Road East',
                lat: 54.586368,
                lon: -101.378859,
              },
              {
                street: 'Poratzstraße',
                lat: 52.846418,
                lon: 13.816426,
              },
              {
                street: 'Poratz',
                lat: 53.062961,
                lon: 13.791879,
              },
              {
                street: 'Poratie',
                lat: 65.957135,
                lon: 29.115682,
              },
              {
                street: 'Poratie',
                lat: 60.501277,
                lon: 26.863501,
              },
            ],
          },
          {
            id: '61276',
            name: 'Ramat Hakovesh',
            type: 'city',
            streets: [
              {
                street: 'הכובש',
                lat: 31.969214,
                lon: 34.811595,
              },
              {
                street: 'Kramat',
                lat: 47.461835,
                lon: 12.34811,
              },
              {
                street: 'Ramal',
                lat: -2.655797,
                lon: -59.66665,
              },
              {
                street: 'Estrada De Acesso À Pista De Pouso',
                lat: -0.780804,
                lon: -60.069069,
              },
              {
                street: 'Rabat',
                lat: -33.546343,
                lon: -70.769374,
              },
            ],
          },
          {
            id: '61277',
            name: 'Ramla',
            type: 'city',
            streets: [
              {
                street: 'شارع رَملة دَخنة',
                lat: 24.074371,
                lon: 55.867226,
              },
              {
                street: 'شارع رَملة حِفِير',
                lat: 24.057088,
                lon: 55.846584,
              },
              {
                street: 'شارع رَملة الرَّده',
                lat: 24.323524,
                lon: 55.811646,
              },
              {
                street: 'شارع رَملة نمَيلان',
                lat: 24.156898,
                lon: 55.721855,
              },
              {
                street: 'شارع رَملة البُحُوث',
                lat: 24.115119,
                lon: 55.713191,
              },
            ],
          },
          {
            id: '61278',
            name: 'Ramot Hashavim',
            type: 'city',
            streets: [
              {
                street: 'רמות',
                lat: 31.982262,
                lon: 34.761646,
              },
              {
                street: 'השחפים',
                lat: 32.19156,
                lon: 34.853921,
              },
              {
                street: 'השביט',
                lat: 32.184972,
                lon: 34.858393,
              },
              {
                street: 'השואבים',
                lat: 32.025489,
                lon: 34.794076,
              },
              {
                street: 'Ramos',
                lat: -27.940709,
                lon: -58.789322,
              },
            ],
          },
          {
            id: '61279',
            name: 'Rehovot',
            type: 'city',
            streets: [
              {
                street: 'Rehovotstraße',
                lat: 49.401103,
                lon: 8.666326,
              },
              {
                street: 'רחובות הבוכרים',
                lat: 31.792188,
                lon: 35.218622,
              },
              {
                street: 'רחובות',
                lat: 32.103962,
                lon: 34.875711,
              },
              {
                street: 'רחובות הנהר',
                lat: 32.089606,
                lon: 34.806073,
              },
              {
                street: 'רחובות',
                lat: 31.782587,
                lon: 34.663712,
              },
            ],
          },
          {
            id: '61280',
            name: 'Rishon Le Ziyyon',
            type: 'city',
            streets: [
              {
                street: "Le'",
                lat: 46.613089,
                lon: 11.923518,
              },
              {
                street: 'Lè',
                lat: 46.656638,
                lon: 11.913502,
              },
              {
                street: 'Pine Tree Road',
                lat: 32.039555,
                lon: -88.965942,
              },
              {
                street: 'L East Road',
                lat: 39.183297,
                lon: -107.90367,
              },
              {
                street: 'Rishøj',
                lat: 55.669517,
                lon: 12.006146,
              },
            ],
          },
          {
            id: '61281',
            name: 'Rishpon',
            type: 'city',
            streets: [
              {
                street: 'Rishon Avenue',
                lat: -37.846212,
                lon: 145.150935,
              },
              {
                street: 'Rishon-Le-Zion-Ring',
                lat: 51.962405,
                lon: 7.602361,
              },
              {
                street: 'Rishton Road',
                lat: 52.476754,
                lon: 1.749991,
              },
              {
                street: 'Rishton Close',
                lat: 54.586322,
                lon: -1.342852,
              },
              {
                street: 'Rishton Square',
                lat: 54.927791,
                lon: -1.433955,
              },
            ],
          },
          {
            id: '61282',
            name: 'Rosh Haayin',
            type: 'city',
            streets: [
              {
                street: 'ראש העין',
                lat: 32.100915,
                lon: 34.91912,
              },
              {
                street: 'Yaayin Way',
                lat: -32.95993,
                lon: 151.630203,
              },
              {
                street: 'Haalin Silta',
                lat: 60.513568,
                lon: 23.179709,
              },
              {
                street: 'היין',
                lat: 32.574725,
                lon: 34.957802,
              },
              {
                street: 'היין',
                lat: 32.517959,
                lon: 34.943977,
              },
            ],
          },
          {
            id: '61283',
            name: 'Savyon',
            type: 'city',
            streets: [
              {
                street: 'סביון',
                lat: 33.215119,
                lon: 35.580845,
              },
              {
                street: 'סביון',
                lat: 32.977077,
                lon: 35.534199,
              },
              {
                street: 'סביונים',
                lat: 32.783171,
                lon: 35.522384,
              },
              {
                street: 'סביון',
                lat: 32.715051,
                lon: 35.488461,
              },
              {
                street: 'סביון',
                lat: 32.525597,
                lon: 35.331386,
              },
            ],
          },
          {
            id: '61284',
            name: 'Shefayim',
            type: 'city',
            streets: [
              {
                street: 'שפיים',
                lat: 32.164264,
                lon: 34.815169,
              },
            ],
          },
          {
            id: '61285',
            name: 'Taibe',
            type: 'city',
            streets: [
              {
                street: 'Taite Close',
                lat: -37.758056,
                lon: 145.342074,
              },
              {
                street: 'Taibo',
                lat: -33.048853,
                lon: -71.59734,
              },
              {
                street: 'Laibe',
                lat: 51.458021,
                lon: 9.308327,
              },
              {
                street: 'Haibe',
                lat: 48.651712,
                lon: 8.776639,
              },
              {
                street: 'Taibó',
                lat: 43.370264,
                lon: -8.275794,
              },
            ],
          },
          {
            id: '61286',
            name: 'Tel Mond',
            type: 'city',
            streets: [
              {
                street: 'Calle El Mondongo',
                lat: 8.848861,
                lon: -82.963291,
              },
              {
                street: 'El Mondalón',
                lat: 28.038598,
                lon: -15.443194,
              },
              {
                street: 'Vicolo Del Mondezzaio',
                lat: 41.839583,
                lon: 12.829173,
              },
              {
                street: 'Via Del Mondiglio',
                lat: 43.387739,
                lon: 10.457806,
              },
              {
                street: 'Rua Tenente Modino',
                lat: -22.572284,
                lon: -47.402929,
              },
            ],
          },
          {
            id: '61287',
            name: 'Tira',
            type: 'city',
            streets: [
              {
                street: 'Тирана',
                lat: 43.405173,
                lon: 24.629596,
              },
              {
                street: 'Тирана',
                lat: 42.661517,
                lon: 23.398642,
              },
              {
                street: 'Tiradentes Passage',
                lat: -8.114751,
                lon: -35.016434,
              },
              {
                street: 'Rua Tiradentes',
                lat: -7.863214,
                lon: -35.594678,
              },
              {
                street: 'Rua Tira Chapéu',
                lat: -10.592125,
                lon: -38.369622,
              },
            ],
          },
          {
            id: '61288',
            name: 'Tirat Yehuda',
            type: 'city',
            streets: [
              {
                street: 'טירת יהודה',
                lat: 32.017536,
                lon: 34.937961,
              },
              {
                street: 'טירת יהודה',
                lat: 32.010811,
                lon: 34.92895,
              },
              {
                street: 'יהודה המכבי',
                lat: 32.183801,
                lon: 34.941638,
              },
              {
                street: 'יהודה',
                lat: 32.275229,
                lon: 34.947268,
              },
              {
                street: 'יהודה הלוי',
                lat: 32.313668,
                lon: 34.926108,
              },
            ],
          },
          {
            id: '61289',
            name: 'Yad Binyamin',
            type: 'city',
            streets: [
              {
                street: 'בנימין מזר',
                lat: 31.791132,
                lon: 35.244057,
              },
              {
                street: 'בנימין',
                lat: 31.757422,
                lon: 35.216817,
              },
              {
                street: 'בנימין',
                lat: 32.837166,
                lon: 35.088548,
              },
              {
                street: 'בנימין',
                lat: 32.935973,
                lon: 35.089702,
              },
              {
                street: 'בנימין',
                lat: 32.849616,
                lon: 35.066697,
              },
            ],
          },
          {
            id: '61290',
            name: 'Yad Rambam',
            type: 'city',
            streets: [
              {
                street: 'רמבם',
                lat: 33.214573,
                lon: 35.5751,
              },
              {
                street: 'רמבם',
                lat: 32.978944,
                lon: 35.544051,
              },
              {
                street: 'רמבם',
                lat: 31.799034,
                lon: 35.164433,
              },
              {
                street: 'רמבם',
                lat: 33.009273,
                lon: 35.107524,
              },
              {
                street: 'רמבם',
                lat: 32.834198,
                lon: 35.075792,
              },
            ],
          },
          {
            id: '61291',
            name: 'Yagel',
            type: 'city',
            streets: [
              {
                street: 'Yaegl Crescent',
                lat: -29.397555,
                lon: 153.354253,
              },
              {
                street: 'Nagelstrasse',
                lat: 47.051249,
                lon: 8.606517,
              },
              {
                street: 'Hägelstrasse',
                lat: 47.443807,
                lon: 8.39035,
              },
              {
                street: 'Wangelen',
                lat: 46.980773,
                lon: 7.727454,
              },
              {
                street: 'Hagel',
                lat: -32.748865,
                lon: -70.717688,
              },
            ],
          },
          {
            id: '61292',
            name: 'Yanuv',
            type: 'city',
            streets: [
              {
                street: 'Yanum Place',
                lat: -27.48974,
                lon: 152.949787,
              },
              {
                street: 'Yanuk Road',
                lat: -38.623762,
                lon: 143.068195,
              },
              {
                street: 'Janův Hrad',
                lat: 48.804635,
                lon: 16.83205,
              },
              {
                street: 'Panuntie',
                lat: 60.220139,
                lon: 24.948525,
              },
              {
                street: 'יניב',
                lat: 32.07358,
                lon: 34.89335,
              },
            ],
          },
          {
            id: '61293',
            name: 'Yaqum',
            type: 'city',
            streets: [
              {
                street: 'Yaqui',
                lat: -38.881961,
                lon: -71.178206,
              },
              {
                street: 'Yaque Del Sur',
                lat: 18.682601,
                lon: -71.050437,
              },
              {
                street: 'Yaum Al Ard',
                lat: 32.227979,
                lon: 34.95062,
              },
              {
                street: '야음로6번길',
                lat: 35.523039,
                lon: 129.325991,
              },
              {
                street: 'Andador Yaqui',
                lat: 19.153776,
                lon: -98.258593,
              },
            ],
          },
          {
            id: '61294',
            name: 'Yarhiv',
            type: 'city',
            streets: [
              {
                street: 'Karhintie',
                lat: 64.016674,
                lon: 23.495869,
              },
              {
                street: 'Karhintie',
                lat: 63.982088,
                lon: 23.427379,
              },
            ],
          },
          {
            id: '61295',
            name: 'Yashresh',
            type: 'city',
            streets: [
              {
                street: 'ישרש',
                lat: 32.042426,
                lon: 34.791047,
              },
            ],
          },
          {
            id: '61296',
            name: 'Yavne',
            type: 'city',
            streets: [
              {
                street: 'יבנה',
                lat: 33.017428,
                lon: 35.094322,
              },
              {
                street: 'יבנאלי',
                lat: 32.818934,
                lon: 35.074479,
              },
              {
                street: 'יבנה',
                lat: 32.831536,
                lon: 35.070555,
              },
              {
                street: 'יבנה',
                lat: 32.180866,
                lon: 34.914839,
              },
              {
                street: 'יבנה',
                lat: 32.094378,
                lon: 34.90444,
              },
            ],
          },
          {
            id: '61297',
            name: 'Yehud',
            type: 'city',
            streets: [
              {
                street: 'Yehudi-Menuhin-Weg',
                lat: 48.295656,
                lon: 16.422452,
              },
              {
                street: 'Carrer De Yehudi Menuhin',
                lat: 41.628012,
                lon: 0.633682,
              },
              {
                street: 'יהודה הנשיא',
                lat: 32.794939,
                lon: 35.529584,
              },
              {
                street: 'יהודה',
                lat: 31.256234,
                lon: 35.210959,
              },
              {
                street: 'יהודה',
                lat: 31.756334,
                lon: 35.221731,
              },
            ],
          },
          {
            id: '61298',
            name: 'Yesodot',
            type: 'city',
            streets: [],
          },
          {
            id: '61299',
            name: 'Zeitan',
            type: 'city',
            streets: [
              {
                street: 'Zeiten',
                lat: 54.305512,
                lon: 13.312935,
              },
              {
                street: 'זיתן',
                lat: 29.557527,
                lon: 34.936008,
              },
              {
                street: 'איתן',
                lat: 31.949684,
                lon: 34.860113,
              },
              {
                street: 'איתן יונה',
                lat: 32.053713,
                lon: 34.851016,
              },
              {
                street: 'איתן לבני',
                lat: 32.080532,
                lon: 34.869644,
              },
            ],
          },
          {
            id: '61300',
            name: 'Zofit',
            type: 'city',
            streets: [
              {
                street: 'Zofia Place',
                lat: -37.557639,
                lon: 143.808241,
              },
              {
                street: 'Žofie Podlipské',
                lat: 49.859007,
                lon: 18.272276,
              },
              {
                street: 'Žofín',
                lat: 50.887087,
                lon: 14.558523,
              },
              {
                street: 'Žofín',
                lat: 49.483793,
                lon: 14.532703,
              },
              {
                street: 'Žofie Podlipské',
                lat: 49.969827,
                lon: 14.3906,
              },
            ],
          },
          {
            id: '61301',
            name: 'Azor',
            type: 'city',
            streets: [
              {
                street: 'Azorín',
                lat: -34.435869,
                lon: -59.120555,
              },
              {
                street: 'Azor Grimaut',
                lat: -31.35868,
                lon: -64.212914,
              },
              {
                street: 'Azorin',
                lat: -24.181967,
                lon: -65.320748,
              },
              {
                street: 'Azorenstraat',
                lat: 51.327342,
                lon: 3.194935,
              },
              {
                street: 'Azores Norte',
                lat: -33.593485,
                lon: -70.586865,
              },
            ],
          },
          {
            id: '61302',
            name: 'Bat Yam',
            type: 'city',
            streets: [
              {
                street: 'Bat-Yam-Platz',
                lat: 52.425553,
                lon: 13.462389,
              },
              {
                street: 'בת ים',
                lat: 32.034221,
                lon: 34.744314,
              },
              {
                street: 'Via Bat-Yam',
                lat: 43.520274,
                lon: 10.320294,
              },
              {
                street: 'Rue De Bat Yam',
                lat: 45.773373,
                lon: 4.864479,
              },
              {
                street: 'Chemin Du Bâtiment',
                lat: 43.238429,
                lon: 1.149463,
              },
            ],
          },
          {
            id: '61304',
            name: 'Glil Yam',
            type: 'city',
            streets: [
              {
                street: 'Gill Drive',
                lat: 18.50993,
                lon: -77.877295,
              },
              {
                street: 'Rue Gill',
                lat: 46.068877,
                lon: -72.808425,
              },
              {
                street: 'Yamuna Street',
                lat: 13.062056,
                lon: 80.198882,
              },
              {
                street: 'Avenida Yaml Rios U.',
                lat: 11.50218,
                lon: -85.886211,
              },
              {
                street: 'Ghil',
                lat: 51.408675,
                lon: 4.93188,
              },
            ],
          },
          {
            id: '61306',
            name: 'Holon',
            type: 'city',
            streets: [
              {
                street: 'Holonweg',
                lat: 51.400526,
                lon: 9.661428,
              },
              {
                street: 'Holonsuontie',
                lat: 61.007914,
                lon: 25.815295,
              },
              {
                street: 'Hølondvegen',
                lat: 63.282146,
                lon: 10.277582,
              },
              {
                street: 'Hølondvegen',
                lat: 63.171945,
                lon: 10.113698,
              },
              {
                street: 'Hølondvegen',
                lat: 63.157483,
                lon: 9.953073,
              },
            ],
          },
          {
            id: '61307',
            name: 'Or Yehuda',
            type: 'city',
            streets: [
              {
                street: 'Ortona Street',
                lat: 52.110801,
                lon: -106.730295,
              },
              {
                street: 'Or',
                lat: 46.45932,
                lon: 8.904626,
              },
              {
                street: 'Oer',
                lat: 54.43273,
                lon: 9.436426,
              },
              {
                street: 'אור',
                lat: 32.723602,
                lon: 35.11557,
              },
              {
                street: 'Ör',
                lat: 59.04739,
                lon: 17.514028,
              },
            ],
          },
          {
            id: '61309',
            name: 'Ramat Gan',
            type: 'city',
            streets: [
              {
                street: 'Ramal Gancho',
                lat: -1.762432,
                lon: -47.64854,
              },
              {
                street: 'Carrer Ramat',
                lat: 38.970732,
                lon: -0.19382,
              },
              {
                street: 'Ganymede Place',
                lat: 35.215621,
                lon: -80.701022,
              },
              {
                street: 'Gander Cove Lane',
                lat: 35.131018,
                lon: -80.736858,
              },
              {
                street: 'רבאט',
                lat: 31.931014,
                lon: 34.885678,
              },
            ],
          },
          {
            id: '61310',
            name: 'Ramat Hasharon',
            type: 'city',
            streets: [
              {
                street: 'Kramat',
                lat: 47.461835,
                lon: 12.34811,
              },
              {
                street: 'Ramal',
                lat: -2.655797,
                lon: -59.66665,
              },
              {
                street: 'Estrada De Acesso À Pista De Pouso',
                lat: -0.780804,
                lon: -60.069069,
              },
              {
                street: 'Rabat',
                lat: -33.546343,
                lon: -70.769374,
              },
              {
                street: 'Ramal',
                lat: 8.585751,
                lon: -72.716061,
              },
            ],
          },
          {
            id: '61311',
            name: 'Tel Aviv',
            type: 'city',
            streets: [
              {
                street: 'Tel Aviv',
                lat: -34.649956,
                lon: -58.700481,
              },
              {
                street: 'Rua Tel Aviv',
                lat: -20.151206,
                lon: -40.238162,
              },
              {
                street: 'Rua Tel Aviv',
                lat: -22.708054,
                lon: -43.30274,
              },
              {
                street: 'Rua Tel Aviv',
                lat: -19.991653,
                lon: -44.028406,
              },
              {
                street: 'Rua Tel Aviv',
                lat: -23.611005,
                lon: -46.839272,
              },
            ],
          },
          {
            id: '61312',
            name: 'Abu Sinan',
            type: 'city',
            streets: [
              {
                street: 'شارع أبي سنان بن محسن',
                lat: 25.35164,
                lon: 55.408672,
              },
              {
                street: 'Синаново',
                lat: 42.72143,
                lon: 21.687664,
              },
              {
                street: 'Sinanan Pl',
                lat: 28.30743,
                lon: -81.41159,
              },
              {
                street: 'Rruga Isnan Korça',
                lat: 40.616141,
                lon: 20.769544,
              },
              {
                street: 'Siban Jalil',
                lat: -37.048293,
                lon: -69.873729,
              },
            ],
          },
          {
            id: '61313',
            name: 'Acre',
            type: 'city',
            streets: [
              {
                street: 'Acre',
                lat: -33.411843,
                lon: -70.556855,
              },
              {
                street: 'Acre',
                lat: 21.131477,
                lon: -101.642621,
              },
              {
                street: 'Acres',
                lat: 44.44917,
                lon: -68.10573,
              },
              {
                street: 'Hillcrest Circle',
                lat: 44.264181,
                lon: -68.328209,
              },
              {
                street: 'Island-700 Acre',
                lat: 44.254098,
                lon: -68.954031,
              },
            ],
          },
          {
            id: '61314',
            name: 'Adamit',
            type: 'city',
            streets: [
              {
                street: 'אדמית',
                lat: 33.016409,
                lon: 35.092744,
              },
              {
                street: 'Adamita',
                lat: 25.784252,
                lon: -100.565711,
              },
              {
                street: 'Adamite Drive',
                lat: -43.616577,
                lon: 172.397748,
              },
              {
                street: 'Adamitz Lane',
                lat: 43.009744,
                lon: -90.458469,
              },
              {
                street: 'Adamite Ter',
                lat: 33.78637,
                lon: -118.2991,
              },
            ],
          },
          {
            id: '61315',
            name: 'Afikim',
            type: 'city',
            streets: [
              {
                street: 'אפיקים בנגב',
                lat: 31.224837,
                lon: 34.798387,
              },
              {
                street: 'אפיקים בנגב',
                lat: 31.321486,
                lon: 34.627038,
              },
            ],
          },
          {
            id: '61316',
            name: 'Afula',
            type: 'city',
            streets: [
              {
                street: 'כביש עוקף עפולה מזרח',
                lat: 32.636656,
                lon: 35.311473,
              },
              {
                street: 'Anula Crescent',
                lat: -27.892297,
                lon: 153.272355,
              },
              {
                street: 'Adula',
                lat: 46.621123,
                lon: 9.181348,
              },
              {
                street: 'Adulastraße',
                lat: 49.779857,
                lon: 6.691829,
              },
              {
                street: 'Arula — Sihva',
                lat: 58.026789,
                lon: 26.39655,
              },
            ],
          },
          {
            id: '61317',
            name: 'Ahihud',
            type: 'city',
            streets: [
              {
                street: 'דרך נוף יער אחיהוד',
                lat: 32.918124,
                lon: 35.20149,
              },
              {
                street: 'אחיהוד בן שלומי',
                lat: 33.073872,
                lon: 35.141798,
              },
            ],
          },
          {
            id: '61318',
            name: 'Akko',
            type: 'city',
            streets: [
              {
                street: 'Akkonplatz',
                lat: 48.201668,
                lon: 16.315374,
              },
              {
                street: "Rue De L'accord - Akkoordstraat",
                lat: 50.853889,
                lon: 4.321077,
              },
              {
                street: 'Akkoyunlu Sokak',
                lat: 35.18864,
                lon: 33.368532,
              },
              {
                street: 'Akkolantie',
                lat: 62.421148,
                lon: 30.167804,
              },
              {
                street: 'Akkosalmentie',
                lat: 63.553258,
                lon: 29.160044,
              },
            ],
          },
          {
            id: '61319',
            name: 'Almagor',
            type: 'city',
            streets: [
              {
                street: 'אלמגור',
                lat: 32.119238,
                lon: 34.843206,
              },
              {
                street: 'Almagordo Street',
                lat: 36.098929,
                lon: -115.079592,
              },
              {
                street: 'Almagränd',
                lat: 63.666202,
                lon: 22.700904,
              },
              {
                street: 'Álmakór',
                lat: 64.086945,
                lon: -21.840009,
              },
              {
                street: 'Calle Almagro A',
                lat: 19.15047,
                lon: -98.254177,
              },
            ],
          },
          {
            id: '61320',
            name: 'Alonim',
            type: 'city',
            streets: [
              {
                street: 'אלונים',
                lat: 32.797209,
                lon: 35.531625,
              },
              {
                street: 'אלונים',
                lat: 32.724448,
                lon: 35.243015,
              },
              {
                street: 'אלונים',
                lat: 32.719447,
                lon: 35.129409,
              },
              {
                street: 'אלונים',
                lat: 32.697638,
                lon: 34.992532,
              },
              {
                street: 'אלונים',
                lat: 32.570897,
                lon: 34.946729,
              },
            ],
          },
          {
            id: '61321',
            name: 'Alumot',
            type: 'city',
            streets: [
              {
                street: 'אלומות',
                lat: 32.960099,
                lon: 35.510737,
              },
              {
                street: 'אלומות',
                lat: 33.006202,
                lon: 35.276598,
              },
              {
                street: 'אלומות',
                lat: 32.682558,
                lon: 35.244427,
              },
              {
                street: 'אלומות',
                lat: 32.475993,
                lon: 34.998304,
              },
              {
                street: 'אלומות',
                lat: 32.320239,
                lon: 34.888579,
              },
            ],
          },
          {
            id: '61322',
            name: 'Amirim',
            type: 'city',
            streets: [
              {
                street: 'אמירים',
                lat: 32.055187,
                lon: 34.87532,
              },
              {
                street: 'אמירים',
                lat: 32.118065,
                lon: 34.833517,
              },
              {
                street: 'אמירים',
                lat: 32.153674,
                lon: 34.841931,
              },
              {
                street: 'Mirim Lane',
                lat: -32.140694,
                lon: 115.846342,
              },
              {
                street: 'Rua Amorim Paiva',
                lat: -3.805634,
                lon: -38.546054,
              },
            ],
          },
          {
            id: '61323',
            name: 'Arbel',
            type: 'city',
            streets: [
              {
                street: 'Arbell Crescent',
                lat: -35.041494,
                lon: 138.584151,
              },
              {
                street: 'Rua Arbelino Silvano Pereira',
                lat: -19.689878,
                lon: -41.465672,
              },
              {
                street: 'Arbeláez-Fusagaugá',
                lat: 4.32339,
                lon: -74.39242,
              },
              {
                street: 'Arbelaez',
                lat: 6.281452,
                lon: -75.33177,
              },
              {
                street: 'Arbela - Los Planes',
                lat: 2.140597,
                lon: -76.763198,
              },
            ],
          },
          {
            id: '61324',
            name: 'Avital',
            type: 'city',
            streets: [
              {
                street: 'אביטל',
                lat: 31.752142,
                lon: 35.211576,
              },
              {
                street: 'אביטל',
                lat: 32.756881,
                lon: 35.141616,
              },
              {
                street: 'אביטל',
                lat: 32.638956,
                lon: 35.088271,
              },
              {
                street: 'אביטל',
                lat: 32.457786,
                lon: 35.040333,
              },
              {
                street: 'אביטל',
                lat: 31.889656,
                lon: 35.022594,
              },
            ],
          },
          {
            id: '61325',
            name: 'Ayelet Hashahar',
            type: 'city',
            streets: [
              {
                street: 'איילת השחר',
                lat: 32.576086,
                lon: 34.951338,
              },
              {
                street: 'איילת השחר',
                lat: 32.1504,
                lon: 34.893504,
              },
              {
                street: 'איילת השחר',
                lat: 32.270154,
                lon: 34.886258,
              },
              {
                street: 'איילת השחר',
                lat: 32.024233,
                lon: 34.854952,
              },
              {
                street: 'איילת השחר',
                lat: 32.058421,
                lon: 34.782729,
              },
            ],
          },
          {
            id: '61326',
            name: 'Beit Alfa',
            type: 'city',
            streets: [
              {
                street: 'בית אלפא',
                lat: 32.836754,
                lon: 35.059805,
              },
              {
                street: 'בית אלפא',
                lat: 32.06555,
                lon: 34.788712,
              },
              {
                street: 'Reit',
                lat: 47.983923,
                lon: 13.051512,
              },
              {
                street: 'Reit',
                lat: 47.973424,
                lon: 12.94553,
              },
              {
                street: 'Reit',
                lat: 48.021254,
                lon: 12.923811,
              },
            ],
          },
          {
            id: '61327',
            name: 'Beit Jann',
            type: 'city',
            streets: [
              {
                street: 'Beil Lane',
                lat: 36.464738,
                lon: -94.194351,
              },
              {
                street: 'Beith Lane',
                lat: 36.483059,
                lon: -94.344162,
              },
              {
                street: 'Jännugassu',
                lat: 46.287051,
                lon: 7.726003,
              },
              {
                street: 'شارع جانوسان',
                lat: 26.230045,
                lon: 50.494001,
              },
              {
                street: 'Beit',
                lat: -28.732955,
                lon: 24.767263,
              },
            ],
          },
          {
            id: '61328',
            name: 'Beit Yosef',
            type: 'city',
            streets: [
              {
                street: 'בית יוסף',
                lat: 32.967509,
                lon: 35.492697,
              },
              {
                street: 'בית יוסף',
                lat: 32.091102,
                lon: 34.840123,
              },
              {
                street: 'בית יוסף',
                lat: 31.963559,
                lon: 34.814381,
              },
              {
                street: 'בית יוסף',
                lat: 32.070192,
                lon: 34.769006,
              },
              {
                street: 'בית יוסף',
                lat: 31.617315,
                lon: 34.754266,
              },
            ],
          },
          {
            id: '61329',
            name: 'Birya',
            type: 'city',
            streets: [
              {
                street: 'Biryani Place',
                lat: -27.613028,
                lon: 153.044947,
              },
              {
                street: 'ביריה',
                lat: 32.071588,
                lon: 34.837702,
              },
              {
                street: 'ביריה',
                lat: 32.113744,
                lon: 34.838087,
              },
              {
                street: 'Biryani Gali',
                lat: 22.722909,
                lon: 88.345762,
              },
              {
                street: '비량로',
                lat: 35.345375,
                lon: 128.046273,
              },
            ],
          },
          {
            id: '61330',
            name: 'Bustan Hagalil',
            type: 'city',
            streets: [
              {
                street: 'בוסתן',
                lat: 32.928916,
                lon: 35.607299,
              },
              {
                street: 'الجليل',
                lat: 32.967077,
                lon: 35.597397,
              },
              {
                street: 'בוסתן',
                lat: 33.225911,
                lon: 35.587289,
              },
              {
                street: 'הגליל',
                lat: 32.968354,
                lon: 35.548712,
              },
              {
                street: 'הגליל',
                lat: 32.784258,
                lon: 35.54181,
              },
            ],
          },
          {
            id: '61331',
            name: 'Dafna',
            type: 'city',
            streets: [
              {
                street: 'דפנה',
                lat: 32.701465,
                lon: 35.502462,
              },
              {
                street: 'דפנה',
                lat: 32.681679,
                lon: 35.419785,
              },
              {
                street: 'דפנה',
                lat: 32.615055,
                lon: 35.295894,
              },
              {
                street: 'דפנה',
                lat: 32.998605,
                lon: 35.276084,
              },
              {
                street: 'דפנה',
                lat: 32.678222,
                lon: 35.250516,
              },
            ],
          },
          {
            id: '61332',
            name: 'Dalia',
            type: 'city',
            streets: [
              {
                street: 'Dalia',
                lat: -27.308007,
                lon: -58.551202,
              },
              {
                street: 'Dalia',
                lat: -34.305658,
                lon: -58.76783,
              },
              {
                street: 'Dalias',
                lat: -35.434819,
                lon: -58.787544,
              },
              {
                street: 'Dalias',
                lat: -35.131417,
                lon: -60.489931,
              },
              {
                street: 'Dalia',
                lat: -33.013485,
                lon: -60.655082,
              },
            ],
          },
          {
            id: '61333',
            name: 'Dan',
            type: 'city',
            streets: [
              {
                street: 'Dandy Hill',
                lat: 54.084115,
                lon: -4.760826,
              },
              {
                street: 'Danų G.',
                lat: 54.659223,
                lon: 25.340339,
              },
              {
                street: 'Dangeručio G.',
                lat: 54.780847,
                lon: 25.289253,
              },
              {
                street: 'Dano Pozniako G.',
                lat: 54.617776,
                lon: 25.289114,
              },
              {
                street: 'Danielių G.',
                lat: 54.699053,
                lon: 25.249072,
              },
            ],
          },
          {
            id: '61334',
            name: 'Deir Hanna',
            type: 'city',
            streets: [
              {
                street: 'Hannah Road',
                lat: 33.165509,
                lon: -89.247743,
              },
              {
                street: 'Deer Trail',
                lat: 41.54036,
                lon: -106.308776,
              },
              {
                street: 'Deer Road',
                lat: 41.486197,
                lon: -79.965473,
              },
              {
                street: 'Deer Drive',
                lat: 40.435849,
                lon: -84.371098,
              },
              {
                street: 'Hannah Street',
                lat: 53.70427,
                lon: -2.193007,
              },
            ],
          },
          {
            id: '61335',
            name: 'Dovev',
            type: 'city',
            streets: [
              {
                street: 'דובב מישרים',
                lat: 31.796555,
                lon: 35.214137,
              },
              {
                street: 'דובב',
                lat: 31.944407,
                lon: 34.875511,
              },
              {
                street: 'דובב',
                lat: 31.678353,
                lon: 34.581135,
              },
              {
                street: 'Dövevägen',
                lat: 55.859199,
                lon: 14.173207,
              },
              {
                street: 'Doveview Drive',
                lat: 39.160268,
                lon: -75.545933,
              },
            ],
          },
          {
            id: '61336',
            name: 'Dovrat',
            type: 'city',
            streets: [
              {
                street: 'Dorat Road',
                lat: -13.374951,
                lon: 131.146443,
              },
              {
                street: 'Dorat Road',
                lat: -13.658057,
                lon: 131.252229,
              },
              {
                street: 'Dorat',
                lat: 15.960311,
                lon: -61.259051,
              },
              {
                street: 'Sörängesvägen',
                lat: 58.987232,
                lon: 14.986525,
              },
              {
                street: 'Dovravägen',
                lat: 58.977671,
                lon: 14.96733,
              },
            ],
          },
          {
            id: '61337',
            name: 'Eilon',
            type: 'city',
            streets: [
              {
                street: 'Eilontie',
                lat: 62.904737,
                lon: 22.347765,
              },
              {
                street: 'אילון גרושקביץ הזבולני',
                lat: 32.832632,
                lon: 35.071506,
              },
              {
                street: 'אילון',
                lat: 32.145968,
                lon: 34.847498,
              },
              {
                street: 'אילון',
                lat: 31.965967,
                lon: 34.778779,
              },
              {
                street: 'Eilon Avenue',
                lat: 26.6701,
                lon: -80.721801,
              },
            ],
          },
          {
            id: '61338',
            name: 'Elifelet',
            type: 'city',
            streets: [
              {
                street: 'אליפלט',
                lat: 32.056396,
                lon: 34.764773,
              },
            ],
          },
          {
            id: '61339',
            name: 'Fassuta',
            type: 'city',
            streets: [
              {
                street: 'Rua José Cassuta Pantaleão',
                lat: -23.091356,
                lon: -45.706358,
              },
              {
                street: 'Rua Fassula',
                lat: -24.833324,
                lon: -53.115381,
              },
            ],
          },
          {
            id: '61340',
            name: 'Gadish',
            type: 'city',
            streets: [
              {
                street: 'גדיש',
                lat: 32.045939,
                lon: 34.793155,
              },
              {
                street: 'Gladish Grove',
                lat: 43.550978,
                lon: -79.728912,
              },
              {
                street: 'גביש',
                lat: 32.924236,
                lon: 35.339373,
              },
              {
                street: 'גביש',
                lat: 32.943968,
                lon: 35.28643,
              },
              {
                street: 'גביש',
                lat: 32.731527,
                lon: 35.246266,
              },
            ],
          },
          {
            id: '61341',
            name: 'Gadot',
            type: 'city',
            streets: [
              {
                street: 'גדות',
                lat: 32.423661,
                lon: 34.952251,
              },
              {
                street: 'Gadotti Drive',
                lat: 45.784259,
                lon: -122.952147,
              },
              {
                street: 'Gadoy Cruz',
                lat: -34.834196,
                lon: -67.921509,
              },
              {
                street: 'Gadonstraße',
                lat: 47.194838,
                lon: 9.65439,
              },
              {
                street: 'Gadölalpweg',
                lat: 47.232549,
                lon: 9.423973,
              },
            ],
          },
          {
            id: '61342',
            name: 'Gazit',
            type: 'city',
            streets: [
              {
                street: 'Gazitegiko Bidea',
                lat: 43.159259,
                lon: -0.893928,
              },
              {
                street: 'Route Du Saloir',
                lat: 43.242048,
                lon: -1.22835,
              },
              {
                street: 'Gazitegiko Bidea',
                lat: 43.337271,
                lon: -1.449542,
              },
              {
                street: 'גזית',
                lat: 32.92577,
                lon: 35.346195,
              },
              {
                street: 'גזית',
                lat: 32.732834,
                lon: 35.246361,
              },
            ],
          },
          {
            id: '61343',
            name: 'Gesher Haziv',
            type: 'city',
            streets: [
              {
                street: 'גשר',
                lat: 31.966095,
                lon: 34.781392,
              },
              {
                street: 'Gesler',
                lat: 47.325588,
                lon: 9.309566,
              },
              {
                street: 'נשר',
                lat: 32.902831,
                lon: 35.290782,
              },
              {
                street: 'נשר',
                lat: 33.002225,
                lon: 35.106797,
              },
              {
                street: 'נשר',
                lat: 32.088171,
                lon: 34.962192,
              },
            ],
          },
          {
            id: '61345',
            name: 'Gonen',
            type: 'city',
            streets: [
              {
                street: 'Gönen Sokak',
                lat: 35.198752,
                lon: 33.374467,
              },
              {
                street: 'Gönen Sokak',
                lat: 35.331598,
                lon: 33.304702,
              },
              {
                street: 'גונן',
                lat: 32.636625,
                lon: 35.086507,
              },
              {
                street: 'גונן',
                lat: 32.091054,
                lon: 34.855568,
              },
              {
                street: 'גונן',
                lat: 32.177324,
                lon: 34.862842,
              },
            ],
          },
          {
            id: '61346',
            name: 'Goren',
            type: 'city',
            streets: [
              {
                street: 'Goreng Trig Trail',
                lat: -32.442388,
                lon: 152.06171,
              },
              {
                street: 'Gorenstraat',
                lat: 51.050096,
                lon: 5.199406,
              },
              {
                street: 'Goerenstraat',
                lat: 50.97391,
                lon: 5.151568,
              },
              {
                street: 'Gorenstraat',
                lat: 51.045995,
                lon: 5.09717,
              },
              {
                street: 'Gorenstraat',
                lat: 51.330518,
                lon: 4.881476,
              },
            ],
          },
          {
            id: '61347',
            name: 'Gvat',
            type: 'city',
            streets: [
              {
                street: 'גבתון',
                lat: 31.369592,
                lon: 34.807843,
              },
              {
                street: 'Rua Evat Davi Da Silva',
                lat: -27.298052,
                lon: -48.858172,
              },
              {
                street: 'Glat',
                lat: 47.126362,
                lon: 9.479984,
              },
              {
                street: 'Svat. Čecha',
                lat: 49.901142,
                lon: 18.35225,
              },
              {
                street: 'Svat. Čecha',
                lat: 49.027322,
                lon: 17.635257,
              },
            ],
          },
          {
            id: '61348',
            name: 'Hagoshrim',
            type: 'city',
            streets: [
              {
                street: 'התחלת מסלול קייאקי מעין-הגושרים',
                lat: 33.217494,
                lon: 35.612342,
              },
              {
                street: 'הגושרים',
                lat: 32.421093,
                lon: 34.954327,
              },
            ],
          },
          {
            id: '61349',
            name: 'Hanita',
            type: 'city',
            streets: [
              {
                street: 'חניתה',
                lat: 32.719143,
                lon: 35.246416,
              },
              {
                street: 'חניתה',
                lat: 33.016457,
                lon: 35.094838,
              },
              {
                street: 'חניתה',
                lat: 32.829594,
                lon: 35.082127,
              },
              {
                street: 'חניתה',
                lat: 32.788285,
                lon: 35.015626,
              },
              {
                street: 'חניתן',
                lat: 29.555086,
                lon: 34.936643,
              },
            ],
          },
          {
            id: '61350',
            name: 'Haon',
            type: 'city',
            streets: [
              {
                street: 'האניה ארינפורה',
                lat: 32.347586,
                lon: 34.856135,
              },
              {
                street: 'האון',
                lat: 31.959738,
                lon: 34.811694,
              },
              {
                street: 'העונות',
                lat: 31.670607,
                lon: 34.575449,
              },
              {
                street: 'Haonani Drive',
                lat: 35.0888,
                lon: -78.950053,
              },
              {
                street: 'Haona Street',
                lat: 21.56855,
                lon: -158.11959,
              },
            ],
          },
          {
            id: '61351',
            name: 'Hararit',
            type: 'city',
            streets: [
              {
                street: 'הררי',
                lat: 32.058423,
                lon: 34.831302,
              },
              {
                street: 'הררי',
                lat: 32.104494,
                lon: 34.793885,
              },
              {
                street: 'הררי',
                lat: 32.025637,
                lon: 34.770144,
              },
              {
                street: 'Harare Street',
                lat: -31.917431,
                lon: 26.856064,
              },
            ],
          },
          {
            id: '61352',
            name: 'Harashim',
            type: 'city',
            streets: [
              {
                street: 'האחים בכר',
                lat: 32.096099,
                lon: 34.88025,
              },
              {
                street: 'شارع الهواشم',
                lat: 26.85884,
                lon: 31.527263,
              },
            ],
          },
          {
            id: '61354',
            name: 'Hayogev',
            type: 'city',
            streets: [
              {
                street: 'היוגב',
                lat: 32.472372,
                lon: 34.982584,
              },
              {
                street: 'היוגב',
                lat: 32.048155,
                lon: 34.921361,
              },
              {
                street: 'היוגב',
                lat: 32.140372,
                lon: 34.837359,
              },
              {
                street: 'היוגב',
                lat: 32.013332,
                lon: 34.805719,
              },
              {
                street: 'היוגב',
                lat: 32.094258,
                lon: 34.81967,
              },
            ],
          },
          {
            id: '61355',
            name: 'Hever',
            type: 'city',
            streets: [
              {
                street: 'Heversham Drive',
                lat: -38.111531,
                lon: 145.140564,
              },
              {
                street: 'Heversham Grove',
                lat: -37.636555,
                lon: 144.885936,
              },
              {
                street: 'Heverbaan',
                lat: 50.986546,
                lon: 4.563914,
              },
              {
                street: 'Heverova',
                lat: 50.023972,
                lon: 15.206565,
              },
              {
                street: 'Øvre Heverbrua',
                lat: 62.748818,
                lon: 9.95937,
              },
            ],
          },
          {
            id: '61356',
            name: 'Hosen',
            type: 'city',
            streets: [
              {
                street: 'Hosenhansl-Haiderweg',
                lat: 47.287837,
                lon: 15.732907,
              },
              {
                street: 'Hosenweg',
                lat: 46.721345,
                lon: 15.480291,
              },
              {
                street: 'Hosenruckerstrasse',
                lat: 47.481751,
                lon: 9.111005,
              },
              {
                street: 'Hosenaer Landstr.',
                lat: 51.458924,
                lon: 13.93627,
              },
              {
                street: 'Hosenbein',
                lat: 51.079934,
                lon: 11.210056,
              },
            ],
          },
          {
            id: '61357',
            name: 'Hulata',
            type: 'city',
            streets: [
              {
                street: 'חולתה',
                lat: 32.44417,
                lon: 34.901984,
              },
              {
                street: 'Кулата',
                lat: 42.422598,
                lon: 27.6933,
              },
              {
                street: 'Кулата',
                lat: 42.097608,
                lon: 24.874613,
              },
              {
                street: 'Кулата',
                lat: 42.267576,
                lon: 23.125423,
              },
              {
                street: 'Хумата',
                lat: 42.723467,
                lon: 23.128566,
              },
            ],
          },
          {
            id: '61358',
            name: 'Hurfeish',
            type: 'city',
            streets: [],
          },
          {
            id: '61359',
            name: 'Iksal',
            type: 'city',
            streets: [
              {
                street: 'إكسال',
                lat: 32.698283,
                lon: 35.301824,
              },
              {
                street: 'Isal Street',
                lat: -12.19428,
                lon: 136.775037,
              },
              {
                street: 'Isla De Pascua',
                lat: -38.709177,
                lon: -72.547537,
              },
              {
                street: 'İkbal Sokak',
                lat: 35.134927,
                lon: 33.927412,
              },
              {
                street: 'Isal Baltra',
                lat: 0.3619,
                lon: -78.126918,
              },
            ],
          },
          {
            id: '61360',
            name: 'Jish',
            type: 'city',
            streets: [
              {
                street: '季水路',
                lat: 24.672903,
                lon: 121.832491,
              },
              {
                street: '濟世街',
                lat: 24.130684,
                lon: 120.678413,
              },
              {
                street: '積善橋',
                lat: 24.114818,
                lon: 120.671054,
              },
              {
                street: 'Jishin Terrace',
                lat: 37.356215,
                lon: -121.894555,
              },
              {
                street: 'دوار السمك',
                lat: 25.269706,
                lon: 55.316433,
              },
            ],
          },
          {
            id: '61361',
            name: 'Julis',
            type: 'city',
            streets: [
              {
                street: 'Julista Court',
                lat: -27.54705,
                lon: 153.268906,
              },
              {
                street: 'Julis Street',
                lat: -37.909896,
                lon: 145.079115,
              },
              {
                street: 'Rua Juliska Hengedes Ferreira',
                lat: -22.948403,
                lon: -47.135564,
              },
              {
                street: 'Julistajanpolku',
                lat: 63.079816,
                lon: 21.711627,
              },
              {
                street: 'Impasse Julisson Casimir',
                lat: 4.8464,
                lon: -52.331821,
              },
            ],
          },
          {
            id: '61362',
            name: 'Kabri',
            type: 'city',
            streets: [
              {
                street: 'Kabricht',
                lat: 50.835275,
                lon: 5.589313,
              },
              {
                street: 'כברי',
                lat: 32.826814,
                lon: 35.063758,
              },
              {
                street: 'Kabri Road',
                lat: 29.417541,
                lon: 76.944509,
              },
              {
                street: 'Kabrioletu',
                lat: 52.334994,
                lon: 20.965373,
              },
              {
                street: 'Kabristan Sokağı',
                lat: 39.34504,
                lon: 36.400368,
              },
            ],
          },
          {
            id: '61363',
            name: 'Kabul',
            type: 'city',
            streets: [
              {
                street: 'Kabul',
                lat: -34.811422,
                lon: -58.241159,
              },
              {
                street: 'Kabul',
                lat: -31.323572,
                lon: -64.509362,
              },
              {
                street: 'Kabul Close',
                lat: -33.803384,
                lon: 150.778925,
              },
              {
                street: 'Kabul',
                lat: -32.880594,
                lon: -71.237325,
              },
              {
                street: 'Kabul',
                lat: -0.054358,
                lon: -78.423635,
              },
            ],
          },
          {
            id: '61365',
            name: 'Karmiel',
            type: 'city',
            streets: [
              {
                street: 'Karmel Street',
                lat: -34.013043,
                lon: 150.749494,
              },
              {
                street: 'Kamiel Dekelverstraat',
                lat: 50.944487,
                lon: 4.977465,
              },
              {
                street: 'Kamiel Van Baelenstraat',
                lat: 51.325105,
                lon: 4.965011,
              },
              {
                street: 'Kamiel Huysmansstraat',
                lat: 51.080425,
                lon: 4.399576,
              },
              {
                street: 'Kamiel De Cockstraat',
                lat: 51.175505,
                lon: 4.309359,
              },
            ],
          },
          {
            id: '61366',
            name: 'Kefar Shammay',
            type: 'city',
            streets: [
              {
                street: 'שמאי',
                lat: 31.781177,
                lon: 35.219301,
              },
              {
                street: 'Кедър',
                lat: 42.773794,
                lon: 23.317574,
              },
              {
                street: 'Kefa',
                lat: 24.588864,
                lon: 46.749177,
              },
              {
                street: 'شارع الشمر',
                lat: 25.487376,
                lon: 55.599607,
              },
              {
                street: 'شارع الشَّمَّار',
                lat: 24.370793,
                lon: 54.737513,
              },
            ],
          },
          {
            id: '61367',
            name: 'Kfar Tavor',
            type: 'city',
            streets: [
              {
                street: 'כפר תבור',
                lat: 32.283192,
                lon: 34.850066,
              },
              {
                street: 'תבור',
                lat: 32.689616,
                lon: 35.416902,
              },
              {
                street: 'תבור',
                lat: 32.708122,
                lon: 35.327138,
              },
              {
                street: 'תבור',
                lat: 32.915203,
                lon: 35.293991,
              },
              {
                street: 'תבור',
                lat: 32.996357,
                lon: 35.265401,
              },
            ],
          },
          {
            id: '61368',
            name: 'Lavi',
            type: 'city',
            streets: [
              {
                street: 'Lavirinthou',
                lat: 34.926919,
                lon: 33.593942,
              },
              {
                street: 'Lavindsgårdsvej',
                lat: 55.357133,
                lon: 10.577609,
              },
              {
                street: 'Lavista Topaz',
                lat: 29.383404,
                lon: 32.548182,
              },
              {
                street: 'Lávíkabrekka',
                lat: 62.227461,
                lon: -6.59342,
              },
              {
                street: 'Lavina Close',
                lat: 51.27367,
                lon: 0.411093,
              },
            ],
          },
          {
            id: '61369',
            name: 'Liman',
            type: 'city',
            streets: [
              {
                street: 'Liman Miluka',
                lat: 41.610933,
                lon: 20.009215,
              },
              {
                street: 'Rruga Liman Kaba',
                lat: 41.313992,
                lon: 19.808912,
              },
              {
                street: 'Limandus Crescent',
                lat: -38.123554,
                lon: 145.239278,
              },
              {
                street: 'Liman Sokak',
                lat: 35.125498,
                lon: 33.943057,
              },
              {
                street: 'Liman Sokak',
                lat: 35.398965,
                lon: 33.740021,
              },
            ],
          },
          {
            id: '61371',
            name: 'Mahanayim',
            type: 'city',
            streets: [
              {
                street: 'מחניים',
                lat: 32.803846,
                lon: 34.98505,
              },
              {
                street: 'מחניים',
                lat: 32.06346,
                lon: 34.829082,
              },
              {
                street: 'מחניים',
                lat: 31.961343,
                lon: 34.774558,
              },
              {
                street: 'Mahanaim Pl',
                lat: 38.112995,
                lon: -81.00434,
              },
              {
                street: 'Calle Mahanaim',
                lat: 10.183012,
                lon: -83.77989,
              },
            ],
          },
          {
            id: '61372',
            name: 'Manot',
            type: 'city',
            streets: [
              {
                street: 'Rua Menotti Buzanella',
                lat: -26.367688,
                lon: -48.853528,
              },
              {
                street: 'Manotick Station Road',
                lat: 45.222042,
                lon: -75.603876,
              },
              {
                street: 'Manotick Main Street',
                lat: 45.22215,
                lon: -75.679784,
              },
              {
                street: 'Pasaje Manot',
                lat: -33.59291,
                lon: -70.593356,
              },
              {
                street: 'Manot',
                lat: -33.051337,
                lon: -71.598597,
              },
            ],
          },
          {
            id: '61374',
            name: 'Megiddo',
            type: 'city',
            streets: [
              {
                street: 'Megiddo Way',
                lat: -31.843417,
                lon: 115.777399,
              },
              {
                street: 'מגידו',
                lat: 32.709658,
                lon: 35.309672,
              },
              {
                street: 'מגידו',
                lat: 32.909634,
                lon: 35.295753,
              },
              {
                street: 'מגידו',
                lat: 32.853477,
                lon: 35.081464,
              },
              {
                street: 'מגידו',
                lat: 32.804089,
                lon: 34.988502,
              },
            ],
          },
          {
            id: '61375',
            name: 'Meron',
            type: 'city',
            streets: [
              {
                street: 'Meron Street',
                lat: -27.974961,
                lon: 153.413611,
              },
              {
                street: 'Meron Court',
                lat: -27.92503,
                lon: 153.19641,
              },
              {
                street: 'Meron Street',
                lat: -27.444361,
                lon: 153.149931,
              },
              {
                street: 'Merong Place',
                lat: -35.155099,
                lon: 147.354703,
              },
              {
                street: 'Meron Court',
                lat: -37.716166,
                lon: 145.102781,
              },
            ],
          },
          {
            id: '61376',
            name: 'Mesilot',
            type: 'city',
            streets: [
              {
                street: 'מסילות',
                lat: 32.298652,
                lon: 34.860618,
              },
              {
                street: 'מסילות',
                lat: 31.961,
                lon: 34.776584,
              },
              {
                street: 'Melilot Close',
                lat: 53.84597,
                lon: -1.819656,
              },
              {
                street: 'מסילת ישרים',
                lat: 31.781944,
                lon: 35.21494,
              },
              {
                street: 'מסילת ישרים',
                lat: 32.79749,
                lon: 34.964447,
              },
            ],
          },
          {
            id: '61377',
            name: 'Metula',
            type: 'city',
            streets: [
              {
                street: 'מטולה',
                lat: 32.482135,
                lon: 34.993487,
              },
              {
                street: 'מטולה',
                lat: 32.10129,
                lon: 34.871473,
              },
              {
                street: 'מטולה',
                lat: 32.327079,
                lon: 34.877583,
              },
              {
                street: 'מטולה',
                lat: 32.064548,
                lon: 34.829183,
              },
              {
                street: 'מטולה',
                lat: 32.003304,
                lon: 34.789315,
              },
            ],
          },
          {
            id: '61378',
            name: 'Migdal',
            type: 'city',
            streets: [
              {
                street: 'Migdale Road',
                lat: -37.832925,
                lon: 144.718241,
              },
              {
                street: 'East Migdale Road',
                lat: 57.89841,
                lon: -4.308838,
              },
              {
                street: 'Migdale Park',
                lat: 57.891708,
                lon: -4.334769,
              },
              {
                street: 'Migdale Road',
                lat: 57.893175,
                lon: -4.343604,
              },
              {
                street: 'Migdale Gardens',
                lat: 57.89222,
                lon: -4.3344,
              },
            ],
          },
          {
            id: '61380',
            name: 'Misgav',
            type: 'city',
            streets: [
              {
                street: 'משגב',
                lat: 32.918848,
                lon: 35.2981,
              },
              {
                street: 'משגב עם',
                lat: 32.429496,
                lon: 34.954883,
              },
              {
                street: 'משגב עם',
                lat: 32.074792,
                lon: 34.805702,
              },
              {
                street: 'משגב עם',
                lat: 32.041861,
                lon: 34.774289,
              },
              {
                street: 'Mistavägen',
                lat: 58.508687,
                lon: 13.30424,
              },
            ],
          },
          {
            id: '61381',
            name: 'Mishmar Hayarden',
            type: 'city',
            streets: [
              {
                street: 'משמר הירדן',
                lat: 32.609716,
                lon: 35.284333,
              },
              {
                street: 'משמר הירדן',
                lat: 32.282776,
                lon: 34.921936,
              },
              {
                street: 'משמר הירדן',
                lat: 32.090175,
                lon: 34.893997,
              },
              {
                street: 'משמר הירדן',
                lat: 32.326201,
                lon: 34.874971,
              },
              {
                street: 'משמר הירדן',
                lat: 32.064947,
                lon: 34.8292,
              },
            ],
          },
          {
            id: '61382',
            name: 'Moledet',
            type: 'city',
            streets: [
              {
                street: 'מולדת',
                lat: 32.568052,
                lon: 34.960193,
              },
              {
                street: 'מולדת',
                lat: 32.157714,
                lon: 34.901959,
              },
              {
                street: 'מולדת',
                lat: 32.168091,
                lon: 34.859202,
              },
              {
                street: 'מולדת',
                lat: 32.196459,
                lon: 34.858312,
              },
              {
                street: 'מולדת',
                lat: 31.255175,
                lon: 34.778971,
              },
            ],
          },
          {
            id: '61383',
            name: 'Moran',
            type: 'city',
            streets: [
              {
                street: 'Morančani',
                lat: 44.491123,
                lon: 18.619595,
              },
              {
                street: 'Morantie',
                lat: 66.670223,
                lon: 25.039862,
              },
              {
                street: 'Mörän Metsätie',
                lat: 61.338066,
                lon: 25.022009,
              },
              {
                street: 'Moreenitie',
                lat: 60.234474,
                lon: 25.011478,
              },
              {
                street: 'Moreenikuja',
                lat: 60.318747,
                lon: 24.839034,
              },
            ],
          },
          {
            id: '61384',
            name: 'Nahalal',
            type: 'city',
            streets: [
              {
                street: 'נהלל',
                lat: 32.83327,
                lon: 34.979469,
              },
              {
                street: 'נהלל',
                lat: 32.43222,
                lon: 34.93215,
              },
              {
                street: 'נהלל',
                lat: 32.076807,
                lon: 34.801029,
              },
              {
                street: 'נהלל',
                lat: 31.78101,
                lon: 34.65956,
              },
              {
                street: 'נחלת שבעה',
                lat: 31.781321,
                lon: 35.220274,
              },
            ],
          },
          {
            id: '61385',
            name: 'Nahariya',
            type: 'city',
            streets: [
              {
                street: 'Nahariyastraße',
                lat: 52.392064,
                lon: 13.419948,
              },
              {
                street: 'Nahariyastraße',
                lat: 52.029625,
                lon: 8.535487,
              },
              {
                street: 'עוקף נהריה',
                lat: 32.977723,
                lon: 35.118162,
              },
              {
                street: 'נהריה',
                lat: 32.015699,
                lon: 34.789157,
              },
              {
                street: 'Вулиця Назарія Яремчука',
                lat: 48.521374,
                lon: 25.067295,
              },
            ],
          },
          {
            id: '61387',
            name: 'Nazareth',
            type: 'city',
            streets: [
              {
                street: 'Nazareth',
                lat: -27.800678,
                lon: -64.291847,
              },
              {
                street: 'Nazareth',
                lat: -22.458267,
                lon: -68.946241,
              },
              {
                street: 'Nazareth',
                lat: -33.364843,
                lon: -70.506193,
              },
              {
                street: 'Nazareth',
                lat: -33.444249,
                lon: -70.569765,
              },
              {
                street: 'Nazaret',
                lat: -32.803101,
                lon: -70.57112,
              },
            ],
          },
          {
            id: '61388',
            name: 'Nazareth Illit',
            type: 'city',
            streets: [
              {
                street: 'Strada Nazareth Illit',
                lat: 46.087168,
                lon: 23.548674,
              },
              {
                street: 'Nazareth',
                lat: -27.800678,
                lon: -64.291847,
              },
              {
                street: 'Nazareth',
                lat: -22.458267,
                lon: -68.946241,
              },
              {
                street: 'Nazareth',
                lat: -33.364843,
                lon: -70.506193,
              },
              {
                street: 'Nazareth',
                lat: -33.444249,
                lon: -70.569765,
              },
            ],
          },
          {
            id: '61390',
            name: 'Nurit',
            type: 'city',
            streets: [
              {
                street: 'Nüritt Schwendi',
                lat: 46.726027,
                lon: 7.874994,
              },
              {
                street: 'נורית',
                lat: 33.212032,
                lon: 35.580728,
              },
              {
                street: 'נורית',
                lat: 32.497175,
                lon: 35.490116,
              },
              {
                street: 'נורית',
                lat: 32.717938,
                lon: 35.486981,
              },
              {
                street: 'סמטת הנורית',
                lat: 32.840534,
                lon: 35.493524,
              },
            ],
          },
          {
            id: '61391',
            name: 'Omen',
            type: 'city',
            streets: [
              {
                street: 'Rua Omenio Granja Alencar Antiga 43',
                lat: -9.381795,
                lon: -40.539569,
              },
              {
                street: 'Omeniuk Drive',
                lat: 49.984058,
                lon: -97.03376,
              },
              {
                street: 'Omen Road',
                lat: 49.895019,
                lon: -118.056957,
              },
              {
                street: 'Omenica Street',
                lat: 55.238384,
                lon: -127.650528,
              },
              {
                street: 'Omenica Street',
                lat: 54.044246,
                lon: -128.659848,
              },
            ],
          },
          {
            id: '61392',
            name: 'Parod',
            type: 'city',
            streets: [
              {
                street: 'Parodi',
                lat: -27.385575,
                lon: -55.910266,
              },
              {
                street: 'Parodi',
                lat: -34.67635,
                lon: -58.346207,
              },
              {
                street: 'Parodi',
                lat: -34.868132,
                lon: -58.389514,
              },
              {
                street: 'Parodi',
                lat: -34.601029,
                lon: -58.724916,
              },
              {
                street: 'Parodi',
                lat: -35.441295,
                lon: -58.832349,
              },
            ],
          },
          {
            id: '61394',
            name: 'Qiryat Shemona',
            type: 'city',
            streets: [
              {
                street: 'Вулиця Шімона Голлоші',
                lat: 47.943338,
                lon: 23.879017,
              },
              {
                street: 'Вулиця Шімона Голлоші',
                lat: 47.943338,
                lon: 23.879017,
              },
              {
                street: 'Shamona Circle',
                lat: 40.045989,
                lon: -75.693579,
              },
              {
                street: 'South Browns Dam Drive',
                lat: 39.917848,
                lon: -77.043578,
              },
              {
                street: 'Viale Semona',
                lat: 41.946031,
                lon: 14.299334,
              },
            ],
          },
          {
            id: '61395',
            name: 'Rama',
            type: 'city',
            streets: [
              {
                street: 'Ramaseck',
                lat: 48.112252,
                lon: 16.211688,
              },
              {
                street: 'Rua Ramalho Vicente Ferreira',
                lat: -7.166835,
                lon: -34.905568,
              },
              {
                street: 'Ramal Da Copa',
                lat: -8.038186,
                lon: -34.992299,
              },
              {
                street: 'Ramal Arena Pernambuco',
                lat: -8.027373,
                lon: -34.997008,
              },
              {
                street: 'Rua Ramalho Carneiro Leal',
                lat: -6.962033,
                lon: -35.792458,
              },
            ],
          },
          {
            id: '61397',
            name: 'Ramat Hashofet',
            type: 'city',
            streets: [
              {
                street: 'השופט חיים כהן',
                lat: 31.744531,
                lon: 35.21896,
              },
              {
                street: 'השופט ברנדייס',
                lat: 32.097376,
                lon: 34.890068,
              },
              {
                street: 'השופט חיים כהן',
                lat: 32.11034,
                lon: 34.788079,
              },
              {
                street: 'השופט אלקיס',
                lat: 31.24299,
                lon: 34.764726,
              },
              {
                street: 'Kramat',
                lat: 47.461835,
                lon: 12.34811,
              },
            ],
          },
          {
            id: '61398',
            name: 'Ramot Menashe',
            type: 'city',
            streets: [
              {
                street: 'רמות',
                lat: 31.982262,
                lon: 34.761646,
              },
              {
                street: 'Menache Place',
                lat: 33.956341,
                lon: -116.948507,
              },
              {
                street: 'Mehashe Crescent',
                lat: -34.021972,
                lon: 18.663082,
              },
              {
                street: 'Ramos',
                lat: -27.940709,
                lon: -58.789322,
              },
              {
                street: 'Ramos',
                lat: -39.07874,
                lon: -67.159939,
              },
            ],
          },
          {
            id: '61399',
            name: 'Ramot Naftali',
            type: 'city',
            streets: [
              {
                street: 'רמות נפתלי',
                lat: 32.114588,
                lon: 34.836607,
              },
              {
                street: 'רמות',
                lat: 31.982262,
                lon: 34.761646,
              },
              {
                street: 'Natali',
                lat: 19.16149,
                lon: -96.157148,
              },
              {
                street: 'Ramos',
                lat: -27.940709,
                lon: -58.789322,
              },
              {
                street: 'Ramos',
                lat: -39.07874,
                lon: -67.159939,
              },
            ],
          },
          {
            id: '61400',
            name: 'Regba',
            type: 'city',
            streets: [
              {
                street: 'Regastrasse',
                lat: 46.798783,
                lon: 9.701537,
              },
              {
                street: 'Regia',
                lat: 46.18885,
                lon: 8.840545,
              },
              {
                street: 'Regastraße',
                lat: 51.030887,
                lon: 6.891512,
              },
              {
                street: 'Calle De Fray Juan Regla',
                lat: 41.650454,
                lon: -0.914222,
              },
              {
                street: 'Cl Regla (Berciano Par)',
                lat: 42.382273,
                lon: -5.703947,
              },
            ],
          },
          {
            id: '61401',
            name: 'Rehov',
            type: 'city',
            streets: [
              {
                street: 'Řehovice',
                lat: 49.72052,
                lon: 14.539222,
              },
              {
                street: 'Rehovotstraße',
                lat: 49.401103,
                lon: 8.666326,
              },
              {
                street: 'רחובות הבוכרים',
                lat: 31.792188,
                lon: 35.218622,
              },
              {
                street: 'רחובות',
                lat: 32.103962,
                lon: 34.875711,
              },
              {
                street: 'רחובות הנהר',
                lat: 32.089606,
                lon: 34.806073,
              },
            ],
          },
          {
            id: '61402',
            name: 'Reshafim',
            type: 'city',
            streets: [
              {
                street: 'רשפים',
                lat: 32.049874,
                lon: 34.796881,
              },
            ],
          },
          {
            id: '61405',
            name: 'Safed',
            type: 'city',
            streets: [
              {
                street: 'צפת',
                lat: 32.795053,
                lon: 35.536335,
              },
              {
                street: 'צפת',
                lat: 31.779596,
                lon: 35.21131,
              },
              {
                street: 'צפת',
                lat: 32.811376,
                lon: 34.993981,
              },
              {
                street: 'צפת',
                lat: 32.422295,
                lon: 34.94787,
              },
              {
                street: 'צפת',
                lat: 32.069882,
                lon: 34.91536,
              },
            ],
          },
          {
            id: '61406',
            name: 'Sajur',
            type: 'city',
            streets: [
              {
                street: 'Sajur Court',
                lat: 43.217003,
                lon: -79.223683,
              },
              {
                street: 'Sajuriana',
                lat: -33.066235,
                lon: -71.571713,
              },
              {
                street: 'Sauerstraße',
                lat: 52.344294,
                lon: 14.513802,
              },
              {
                street: 'Sauerstraße',
                lat: 51.079411,
                lon: 14.432347,
              },
              {
                street: 'Sauerstraße',
                lat: 53.660423,
                lon: 13.551087,
              },
            ],
          },
          {
            id: '61407',
            name: 'Sakhnin',
            type: 'city',
            streets: [],
          },
          {
            id: '61408',
            name: 'Sallama',
            type: 'city',
            streets: [
              {
                street: 'Allama Avenue',
                lat: -37.722119,
                lon: 144.689488,
              },
              {
                street: 'Kallama Parade',
                lat: -20.737729,
                lon: 116.823736,
              },
              {
                street: 'Hallama Drive',
                lat: 49.771174,
                lon: -97.045262,
              },
              {
                street: 'Calle Salamá Viejo',
                lat: 8.815834,
                lon: -83.291904,
              },
              {
                street: 'شارع سلامه الشرقى',
                lat: 29.976107,
                lon: 32.543408,
              },
            ],
          },
          {
            id: '61409',
            name: 'Sarid',
            type: 'city',
            streets: [
              {
                street: 'Saridakis Court',
                lat: -27.626489,
                lon: 152.881313,
              },
              {
                street: 'שריד',
                lat: 32.043157,
                lon: 34.75009,
              },
              {
                street: 'สฤษดิ์ภูมินารถ 3',
                lat: 6.614993,
                lon: 100.071932,
              },
              {
                street: 'Sarıdere Köyü Tarım Yolu',
                lat: 40.830749,
                lon: 31.005721,
              },
              {
                street: 'Sarıdere - Kemeryanı Ana Yolu',
                lat: 40.824001,
                lon: 31.01271,
              },
            ],
          },
          {
            id: '61410',
            name: 'Sasa',
            type: 'city',
            streets: [
              {
                street: 'Sasachtweg',
                lat: 51.199413,
                lon: 4.987782,
              },
              {
                street: 'Sasanková',
                lat: 49.80732,
                lon: 18.171224,
              },
              {
                street: 'Sasanková',
                lat: 50.719175,
                lon: 15.062469,
              },
              {
                street: 'Sasanková',
                lat: 50.127808,
                lon: 14.659486,
              },
              {
                street: 'Sasanková',
                lat: 49.417982,
                lon: 14.637253,
              },
            ],
          },
          {
            id: '61412',
            name: 'Shamir',
            type: 'city',
            streets: [
              {
                street: 'Shamir Avenue',
                lat: 45.392111,
                lon: -75.624922,
              },
              {
                street: 'Shamir Crescent',
                lat: 43.559676,
                lon: -79.652292,
              },
              {
                street: 'שמיר',
                lat: 32.992318,
                lon: 35.263788,
              },
              {
                street: 'שמיר',
                lat: 31.247942,
                lon: 35.197445,
              },
              {
                street: 'שמיר',
                lat: 32.168175,
                lon: 34.904566,
              },
            ],
          },
          {
            id: '61413',
            name: 'Sharona',
            type: 'city',
            streets: [
              {
                street: 'שרונה',
                lat: 31.960644,
                lon: 34.775959,
              },
              {
                street: 'Sharena Lane',
                lat: 44.632348,
                lon: -85.832758,
              },
              {
                street: 'Sharona Drive',
                lat: 40.221699,
                lon: -76.28974,
              },
              {
                street: 'Sharona Ln',
                lat: 35.50072,
                lon: -82.7272,
              },
              {
                street: 'Sarona Cove',
                lat: 49.504639,
                lon: -96.88138,
              },
            ],
          },
          {
            id: '61414',
            name: 'Shefer',
            type: 'city',
            streets: [
              {
                street: 'סמטת שפר',
                lat: 32.067229,
                lon: 34.769249,
              },
              {
                street: "Sheffer's Road",
                lat: 41.809623,
                lon: -74.319162,
              },
              {
                street: 'Sheher',
                lat: 41.666907,
                lon: 19.879151,
              },
              {
                street: 'Rua Hefer Pelizzon',
                lat: -23.489964,
                lon: -47.520117,
              },
              {
                street: 'Sheffer Terrace',
                lat: 43.767507,
                lon: -79.502677,
              },
            ],
          },
          {
            id: '61417',
            name: 'Shomera',
            type: 'city',
            streets: [
              {
                street: 'שומרה',
                lat: 31.974232,
                lon: 34.780225,
              },
              {
                street: 'Sõmera',
                lat: 58.581426,
                lon: 24.785751,
              },
              {
                street: 'Sõmera',
                lat: 59.435041,
                lon: 24.722703,
              },
              {
                street: 'Sõmera',
                lat: 58.37251,
                lon: 24.608937,
              },
              {
                street: 'Sõmera — Kärla — Uduvere',
                lat: 58.331065,
                lon: 22.423291,
              },
            ],
          },
          {
            id: '61418',
            name: 'Shorashim',
            type: 'city',
            streets: [
              {
                street: 'שורשים',
                lat: 32.747303,
                lon: 35.497929,
              },
              {
                street: 'שורשים',
                lat: 32.044605,
                lon: 34.792378,
              },
              {
                street: 'שורשים',
                lat: 31.695772,
                lon: 34.580544,
              },
            ],
          },
          {
            id: '61419',
            name: 'Sulam',
            type: 'city',
            streets: [
              {
                street: 'Rua Sulamita Enes De Queiroz',
                lat: -6.11555,
                lon: -37.940916,
              },
              {
                street: 'Rua Sulamita Portela',
                lat: -3.729693,
                lon: -38.601701,
              },
              {
                street: 'Rua Sulmaio Do Carmo',
                lat: -17.867892,
                lon: -51.708387,
              },
              {
                street: 'Rua Sulamita Bratowski',
                lat: -30.105984,
                lon: -51.951903,
              },
              {
                street: 'Sulamith-Wülfing-Straße',
                lat: 51.239993,
                lon: 7.161556,
              },
            ],
          },
          {
            id: '61420',
            name: 'Tamra',
            type: 'city',
            streets: [
              {
                street: 'Tamra Close',
                lat: -33.440226,
                lon: 115.61136,
              },
              {
                street: 'Tamrac Street',
                lat: 52.861813,
                lon: -102.383092,
              },
              {
                street: 'حارة تمراز',
                lat: 30.729096,
                lon: 31.80184,
              },
              {
                street: 'شارع تمراز',
                lat: 30.734067,
                lon: 31.804798,
              },
              {
                street: 'شارع تمراز',
                lat: 30.716226,
                lon: 31.241413,
              },
            ],
          },
          {
            id: '61422',
            name: 'Tiberias',
            type: 'city',
            streets: [
              {
                street: 'Tiberias Road',
                lat: -42.443593,
                lon: 147.359981,
              },
              {
                street: 'Tiberias Rise',
                lat: -32.12943,
                lon: 115.862119,
              },
              {
                street: 'Tiberiasplatz',
                lat: 49.63122,
                lon: 8.356514,
              },
              {
                street: 'טבריה',
                lat: 31.780081,
                lon: 35.211218,
              },
              {
                street: 'טבריה',
                lat: 32.811468,
                lon: 34.990692,
              },
            ],
          },
          {
            id: '61423',
            name: 'Timrat',
            type: 'city',
            streets: [
              {
                street: 'Tirat-Carmel-Straße',
                lat: 51.123009,
                lon: 6.903625,
              },
              {
                street: 'Tirat-Carmel Utca',
                lat: 47.079674,
                lon: 17.92597,
              },
              {
                street: 'טירת יהודה',
                lat: 32.017536,
                lon: 34.937961,
              },
              {
                street: 'טירת יהודה',
                lat: 32.010811,
                lon: 34.92895,
              },
              {
                street: 'טירת צבי',
                lat: 32.180948,
                lon: 34.917413,
              },
            ],
          },
          {
            id: '61425',
            name: 'Tuval',
            type: 'city',
            streets: [
              {
                street: 'Tuvalstraße',
                lat: 47.709691,
                lon: 13.07334,
              },
              {
                street: 'Tuvalu Street',
                lat: -27.927299,
                lon: 153.326074,
              },
              {
                street: 'תובל',
                lat: 33.017845,
                lon: 35.272473,
              },
              {
                street: 'תובל',
                lat: 32.664632,
                lon: 35.242371,
              },
              {
                street: 'תובל',
                lat: 31.791529,
                lon: 35.204534,
              },
            ],
          },
          {
            id: '61426',
            name: 'Yardena',
            type: 'city',
            streets: [
              {
                street: 'ירדנה ארזי',
                lat: 32.782079,
                lon: 35.002049,
              },
              {
                street: 'Mardena Crescent',
                lat: 49.80647,
                lon: -97.117556,
              },
              {
                street: 'Cl Bardena Sabinar',
                lat: 42.085252,
                lon: -1.233135,
              },
              {
                street: 'Bardena',
                lat: 42.195215,
                lon: -1.632675,
              },
              {
                street: 'Cardena',
                lat: 19.173966,
                lon: -99.388042,
              },
            ],
          },
          {
            id: '61429',
            name: 'Yuval',
            type: 'city',
            streets: [
              {
                street: 'יובלים',
                lat: 33.229446,
                lon: 35.650413,
              },
              {
                street: 'דרך יובלים',
                lat: 33.231476,
                lon: 35.6355,
              },
              {
                street: 'יובל יניב',
                lat: 33.195192,
                lon: 35.572993,
              },
              {
                street: 'יובל',
                lat: 32.908612,
                lon: 35.301935,
              },
              {
                street: 'יובלים',
                lat: 32.730347,
                lon: 35.246983,
              },
            ],
          },
          {
            id: '96404',
            name: 'Alumim',
            type: 'city',
            streets: [
              {
                street: 'עלומים',
                lat: 32.471418,
                lon: 35.00123,
              },
              {
                street: 'עלומים',
                lat: 31.320941,
                lon: 34.936966,
              },
              {
                street: 'עלומים',
                lat: 32.04426,
                lon: 34.886067,
              },
              {
                street: 'עלומים',
                lat: 32.062564,
                lon: 34.821855,
              },
              {
                street: 'עלומים',
                lat: 32.120623,
                lon: 34.807287,
              },
            ],
          },
          {
            id: '96405',
            name: 'Arugot',
            type: 'city',
            streets: [
              {
                street: 'ערוגות',
                lat: 32.609106,
                lon: 35.297684,
              },
              {
                street: 'ערוגות',
                lat: 32.996044,
                lon: 35.269779,
              },
              {
                street: 'ערוגות',
                lat: 32.308914,
                lon: 35.003332,
              },
              {
                street: 'ערוגות',
                lat: 31.333564,
                lon: 34.938781,
              },
              {
                street: 'ערוגות',
                lat: 31.957929,
                lon: 34.877961,
              },
            ],
          },
          {
            id: '96406',
            name: 'Ashalim',
            type: 'city',
            streets: [
              {
                street: 'אשלים',
                lat: 31.233411,
                lon: 34.791192,
              },
            ],
          },
          {
            id: '96408',
            name: 'Ashkelon',
            type: 'city',
            streets: [
              {
                street: 'Croissant Ashkelon',
                lat: 45.476355,
                lon: -73.670495,
              },
              {
                street: 'אשקלון',
                lat: 32.814702,
                lon: 34.994643,
              },
              {
                street: 'אשקלון',
                lat: 32.05804,
                lon: 34.762923,
              },
            ],
          },
          {
            id: '96411',
            name: 'Bat Hadar',
            type: 'city',
            streets: [
              {
                street: 'Hadar',
                lat: -33.481629,
                lon: -70.730672,
              },
              {
                street: 'Chemin Du Bâtiment',
                lat: 43.238429,
                lon: 1.149463,
              },
              {
                street: 'Bat',
                lat: 50.776827,
                lon: 5.699616,
              },
              {
                street: 'Hadarvegen',
                lat: 62.339875,
                lon: 5.872282,
              },
              {
                street: 'حضارة',
                lat: 24.780845,
                lon: 46.833449,
              },
            ],
          },
          {
            id: '96418',
            name: 'Eilot',
            type: 'city',
            streets: [
              {
                street: 'Eilot',
                lat: 31.723925,
                lon: 35.108186,
              },
              {
                street: 'אילות',
                lat: 32.798166,
                lon: 34.983607,
              },
              {
                street: 'אילות',
                lat: 32.091713,
                lon: 34.962624,
              },
              {
                street: 'אילות',
                lat: 29.559525,
                lon: 34.949231,
              },
              {
                street: 'Rruga Eliot Engel',
                lat: 42.383003,
                lon: 20.152095,
              },
            ],
          },
          {
            id: '96423',
            name: 'Ezer',
            type: 'city',
            streets: [
              {
                street: 'Ezero Street',
                lat: -37.664313,
                lon: 144.984612,
              },
              {
                street: 'Ezerets',
                lat: 43.596604,
                lon: 28.533067,
              },
              {
                street: 'Езеровско Шосе',
                lat: 42.037145,
                lon: 25.283346,
              },
              {
                street: 'Езерна',
                lat: 42.504666,
                lon: 24.325496,
              },
              {
                street: 'Ezeroto',
                lat: 42.904774,
                lon: 23.890382,
              },
            ],
          },
          {
            id: '96436',
            name: 'Lehavim',
            type: 'city',
            streets: [
              {
                street: 'להבית',
                lat: 29.558005,
                lon: 34.937511,
              },
            ],
          },
          {
            id: '96442',
            name: 'Nahal Oz',
            type: 'city',
            streets: [
              {
                street: 'נחל עוז',
                lat: 32.039557,
                lon: 34.759091,
              },
              {
                street: 'Rua Natal',
                lat: -23.505298,
                lon: -46.779659,
              },
              {
                street: 'Naval Road',
                lat: -29.096489,
                lon: 26.24324,
              },
              {
                street: 'Ozo',
                lat: 50.372824,
                lon: 5.55451,
              },
              {
                street: 'Ozogoche',
                lat: -2.121826,
                lon: -79.579538,
              },
            ],
          },
          {
            id: '96445',
            name: 'Netiv Haasara',
            type: 'city',
            streets: [
              {
                street: 'נתיב העשרה',
                lat: 31.980067,
                lon: 34.762438,
              },
              {
                street: 'حارة معصرة البلابسة',
                lat: 30.730048,
                lon: 31.799937,
              },
              {
                street: 'شارع المعصره الخلفى',
                lat: 30.191012,
                lon: 31.135261,
              },
              {
                street: 'Netín',
                lat: 49.414959,
                lon: 15.951555,
              },
              {
                street: 'Nativ',
                lat: 32.016653,
                lon: 34.859379,
              },
            ],
          },
          {
            id: '96453',
            name: 'Ohad',
            type: 'city',
            streets: [
              {
                street: 'Ohad Drive',
                lat: -37.812347,
                lon: 144.661939,
              },
              {
                street: 'Ohadi Drive',
                lat: 40.149724,
                lon: -74.066457,
              },
              {
                street: 'Ohad Street',
                lat: -26.559132,
                lon: 27.939581,
              },
              {
                street: 'Rua Nohad Yussef Nabhan',
                lat: -23.499202,
                lon: -46.365738,
              },
              {
                street: 'Ohař',
                lat: 49.54536,
                lon: 14.037042,
              },
            ],
          },
          {
            id: '96458',
            name: 'Rahat',
            type: 'city',
            streets: [
              {
                street: 'Rahat E1 Street',
                lat: 25.024575,
                lon: 55.268674,
              },
              {
                street: 'Rahat Sokak',
                lat: 35.199458,
                lon: 33.320356,
              },
              {
                street: 'Rahatie',
                lat: 64.253324,
                lon: 23.955141,
              },
              {
                street: 'Rahat Sokağı',
                lat: 40.933509,
                lon: 29.276828,
              },
              {
                street: 'Rahat Sokak',
                lat: 40.885681,
                lon: 29.218933,
              },
            ],
          },
          {
            id: '96466',
            name: 'Shekef',
            type: 'city',
            streets: [
              {
                street: 'شارع شكيب',
                lat: 31.042287,
                lon: 31.38593,
              },
              {
                street: 'شارع شكيب',
                lat: 30.971203,
                lon: 31.17918,
              },
              {
                street: 'شارع شكيب',
                lat: 30.965955,
                lon: 31.167548,
              },
              {
                street: 'شارع شكيب ارسلان',
                lat: 31.230129,
                lon: 29.954007,
              },
              {
                street: 'שלף',
                lat: 32.559277,
                lon: 34.959718,
              },
            ],
          },
          {
            id: '96473',
            name: 'Tifrah',
            type: 'city',
            streets: [
              {
                street: 'Tigrah Road',
                lat: -31.809279,
                lon: 151.959298,
              },
              {
                street: 'Tirah Street',
                lat: -30.642811,
                lon: 116.011034,
              },
              {
                street: 'شارع اليِفْرَة',
                lat: 24.147012,
                lon: 55.649008,
              },
              {
                street: 'شارع اليِفْرَة',
                lat: 24.497258,
                lon: 54.582026,
              },
              {
                street: 'South Tiarah-Essence Lane',
                lat: 44.783781,
                lon: -85.653381,
              },
            ],
          },
          {
            id: '96477',
            name: 'Yated',
            type: 'city',
            streets: [
              {
                street: 'Rua Iate Clube Itaipu',
                lat: -23.702774,
                lon: -46.73378,
              },
              {
                street: 'Gated - Sometimes Locked',
                lat: 45.14109,
                lon: -76.704437,
              },
              {
                street: 'Yates',
                lat: -41.477582,
                lon: -72.965685,
              },
              {
                street: 'Yates Close',
                lat: 50.933599,
                lon: 0.803988,
              },
              {
                street: 'Yates Gardens',
                lat: 51.29969,
                lon: 0.40413,
              },
            ],
          },
          {
            id: '96479',
            name: 'Yesha',
            type: 'city',
            streets: [
              {
                street: 'ישעיהו',
                lat: 32.785618,
                lon: 35.507678,
              },
              {
                street: 'ישעיהו',
                lat: 31.78592,
                lon: 35.217585,
              },
              {
                street: 'ישעיה',
                lat: 31.079863,
                lon: 35.036279,
              },
              {
                street: 'ישעיהו הנביא',
                lat: 31.915933,
                lon: 35.002796,
              },
              {
                street: 'ישעיהו הנביא',
                lat: 31.7025,
                lon: 34.989824,
              },
            ],
          },
          {
            id: '96497',
            name: 'Kiryat Bialik',
            type: 'city',
            streets: [
              {
                street: 'ביאליק',
                lat: 32.797559,
                lon: 35.530267,
              },
              {
                street: 'ביאליק',
                lat: 32.979176,
                lon: 35.507872,
              },
              {
                street: 'ביאליק',
                lat: 31.779201,
                lon: 35.189078,
              },
              {
                street: 'ביאליק',
                lat: 32.815155,
                lon: 35.08514,
              },
              {
                street: 'ביאליק',
                lat: 33.021442,
                lon: 35.096104,
              },
            ],
          },
          {
            id: '96503',
            name: 'Or Akiva',
            type: 'city',
            streets: [
              {
                street: 'Ortona Street',
                lat: 52.110801,
                lon: -106.730295,
              },
              {
                street: 'Or',
                lat: 46.45932,
                lon: 8.904626,
              },
              {
                street: 'Oer',
                lat: 54.43273,
                lon: 9.436426,
              },
              {
                street: 'אור',
                lat: 32.723602,
                lon: 35.11557,
              },
              {
                street: 'Ör',
                lat: 59.04739,
                lon: 17.514028,
              },
            ],
          },
          {
            id: '96522',
            name: 'Gizo',
            type: 'city',
            streets: [
              {
                street: 'Gizollweg',
                lat: 47.776477,
                lon: 13.091054,
              },
              {
                street: "Rond-Point De L'égalité Homme-Femme",
                lat: 43.241527,
                lon: -0.870191,
              },
              {
                street: 'Gizów',
                lat: 52.22588,
                lon: 20.944734,
              },
              {
                street: 'Rruga Gino Berri',
                lat: 42.055316,
                lon: 19.525376,
              },
              {
                street: 'Gido Spano',
                lat: -34.781849,
                lon: -58.291609,
              },
            ],
          },
          {
            id: '96537',
            name: 'Aseret',
            type: 'city',
            streets: [
              {
                street: 'עשרת הרוגי מלכות',
                lat: 32.506146,
                lon: 34.91702,
              },
              {
                street: 'עשרת הרוגי מלכות',
                lat: 31.962496,
                lon: 34.894221,
              },
              {
                street: 'עשרת השבטים',
                lat: 31.674466,
                lon: 34.583884,
              },
              {
                street: 'Серет',
                lat: 42.702773,
                lon: 23.287189,
              },
              {
                street: 'Chemin Asaret',
                lat: 45.431882,
                lon: -75.911973,
              },
            ],
          },
          {
            id: '96541',
            name: 'Bat Hefer',
            type: 'city',
            streets: [
              {
                street: 'Heferen Crescent',
                lat: -19.241242,
                lon: 146.62652,
              },
              {
                street: 'Rua Hefer Pelizzon',
                lat: -23.489964,
                lon: -47.520117,
              },
              {
                street: 'Hefersweiler Straße',
                lat: 49.58628,
                lon: 7.607921,
              },
              {
                street: 'Chemin Du Bâtiment',
                lat: 43.238429,
                lon: 1.149463,
              },
              {
                street: 'חפר',
                lat: 31.999142,
                lon: 34.942934,
              },
            ],
          },
          {
            id: '96564',
            name: 'Hadar Am',
            type: 'city',
            streets: [
              {
                street: 'Amberwood Drive',
                lat: 42.027376,
                lon: -97.386793,
              },
              {
                street: 'Haar',
                lat: 49.478266,
                lon: 11.718063,
              },
              {
                street: 'Vadar Street',
                lat: 35.530549,
                lon: -78.430859,
              },
              {
                street: 'Radar',
                lat: 37.16679,
                lon: -84.570079,
              },
              {
                street: 'Nadar',
                lat: 32.590716,
                lon: -97.054519,
              },
            ],
          },
          {
            id: '96575',
            name: 'Kadima',
            type: 'city',
            streets: [
              {
                street: 'קדימה',
                lat: 32.801549,
                lon: 34.979711,
              },
              {
                street: 'קדימה',
                lat: 32.435339,
                lon: 34.88288,
              },
              {
                street: 'קדימה',
                lat: 32.168735,
                lon: 34.860242,
              },
              {
                street: 'Kadima Circle',
                lat: 30.441364,
                lon: -86.63549,
              },
              {
                street: 'Kadima',
                lat: -25.822882,
                lon: 28.262836,
              },
            ],
          },
          {
            id: '96590',
            name: 'Mishmar David',
            type: 'city',
            streets: [
              {
                street: 'Rruga David Selenica',
                lat: 40.605426,
                lon: 20.783277,
              },
              {
                street: 'Rruga David Selenica',
                lat: 41.315947,
                lon: 19.791271,
              },
              {
                street: 'Rruga David Selenica',
                lat: 41.314404,
                lon: 19.478706,
              },
              {
                street: 'Давид',
                lat: 43.839597,
                lon: 25.95467,
              },
              {
                street: "David's Walk",
                lat: 34.80796,
                lon: 32.451228,
              },
            ],
          },
          {
            id: '96602',
            name: 'Olesh',
            type: 'city',
            streets: [
              {
                street: 'עולש',
                lat: 32.691439,
                lon: 35.307502,
              },
              {
                street: 'עולש',
                lat: 32.475938,
                lon: 34.961763,
              },
              {
                street: 'עולש מצוי',
                lat: 32.273227,
                lon: 34.842151,
              },
              {
                street: 'עולש',
                lat: 32.043836,
                lon: 34.749641,
              },
              {
                street: 'Олешківська Вулиця',
                lat: 48.700214,
                lon: 37.51374,
              },
            ],
          },
          {
            id: '96633',
            name: 'Zemer',
            type: 'city',
            streets: [
              {
                street: 'Zemerweg',
                lat: 50.424295,
                lon: 6.691017,
              },
              {
                street: 'זמר עברי',
                lat: 32.907801,
                lon: 35.3285,
              },
              {
                street: 'Zemery Lane',
                lat: 35.499808,
                lon: -82.967538,
              },
              {
                street: 'Zederstraße',
                lat: 48.098553,
                lon: 14.116748,
              },
              {
                street: 'Земен',
                lat: 42.690232,
                lon: 23.352456,
              },
            ],
          },
          {
            id: '96662',
            name: 'Barak',
            type: 'city',
            streets: [
              {
                street: 'Barakaldo',
                lat: -27.451632,
                lon: -58.785096,
              },
              {
                street: 'Бараковачка',
                lat: 44.980798,
                lon: 16.836939,
              },
              {
                street: 'Barakų G.',
                lat: 54.492531,
                lon: 24.981668,
              },
              {
                street: 'Barakas Ceļš',
                lat: 57.025093,
                lon: 26.750479,
              },
              {
                street: 'Baraku Ceļš',
                lat: 56.431953,
                lon: 25.303621,
              },
            ],
          },
          {
            id: '96670',
            name: 'Dalton',
            type: 'city',
            streets: [
              {
                street: 'Dalton',
                lat: -34.478909,
                lon: -58.696447,
              },
              {
                street: 'Dalton',
                lat: -34.688827,
                lon: -58.78058,
              },
              {
                street: 'Dalton',
                lat: -31.46499,
                lon: -64.09963,
              },
              {
                street: 'Rua Dalton Tenorio',
                lat: -9.783333,
                lon: -36.097157,
              },
              {
                street: 'Rua Antônio Ivan',
                lat: -3.840875,
                lon: -38.52242,
              },
            ],
          },
          {
            id: '96678',
            name: 'Evron',
            type: 'city',
            streets: [
              {
                street: 'Evron Place',
                lat: 51.828231,
                lon: -113.237502,
              },
              {
                street: 'Evron Place',
                lat: 51.79629,
                lon: -0.078383,
              },
              {
                street: 'עברונה',
                lat: 32.069659,
                lon: 34.807553,
              },
              {
                street: 'Evron',
                lat: 50.062662,
                lon: 19.539084,
              },
              {
                street: 'Evronoz Sokak',
                lat: 40.860725,
                lon: 26.626516,
              },
            ],
          },
          {
            id: '96695',
            name: 'Hazon',
            type: 'city',
            streets: [
              {
                street: 'Hazon Way',
                lat: 51.336666,
                lon: -0.270619,
              },
              {
                street: 'חזון ישראל',
                lat: 32.985893,
                lon: 35.546915,
              },
              {
                street: 'חזון איש',
                lat: 32.78163,
                lon: 35.511503,
              },
              {
                street: 'חזון ציון',
                lat: 31.782156,
                lon: 35.19457,
              },
              {
                street: 'חזון איש',
                lat: 31.738527,
                lon: 34.99304,
              },
            ],
          },
          {
            id: '96711',
            name: 'Livnim',
            type: 'city',
            streets: [
              {
                street: 'ליבנים',
                lat: 32.216277,
                lon: 34.988718,
              },
              {
                street: 'ליבנית',
                lat: 32.620193,
                lon: 35.334933,
              },
              {
                street: 'ליבנית',
                lat: 32.645292,
                lon: 35.186256,
              },
            ],
          },
          {
            id: '96742',
            name: 'Ravid',
            type: 'city',
            streets: [
              {
                street: 'Ravida Way',
                lat: -37.521771,
                lon: 144.903377,
              },
              {
                street: 'Ravida Avenue',
                lat: -37.667152,
                lon: 144.537472,
              },
              {
                street: 'רביד',
                lat: 32.481481,
                lon: 34.994164,
              },
              {
                street: 'רביד',
                lat: 31.981582,
                lon: 34.784746,
              },
              {
                street: 'Ravida Circle',
                lat: 28.56296,
                lon: -81.242001,
              },
            ],
          },
          {
            id: '96771',
            name: 'Yirka',
            type: 'city',
            streets: [
              {
                street: 'Pirka Way',
                lat: -37.924492,
                lon: 144.64169,
              },
              {
                street: 'Pirka',
                lat: 48.661165,
                lon: 13.17724,
              },
              {
                street: 'Pirka',
                lat: 49.103573,
                lon: 12.885783,
              },
              {
                street: 'Pirka',
                lat: 48.530325,
                lon: 12.681458,
              },
              {
                street: 'Pirka',
                lat: 48.375524,
                lon: 12.055441,
              },
            ],
          },
        ],
      },
      regions: {
        data: [
          {
            name: 'null',
            type: 'region',
          },
        ],
      },
    },
  },
];
