export const cx = (classNames = {}) => {
  if (typeof classNames !== 'object' || Array.isArray(classNames)) {
    throw new Error('ClassNames should be object');
  }

  return Object.keys(classNames)?.reduce((acc, className, index) => {
    if (classNames[className]) {
      return index !== 0 ? acc + ' ' + className : acc + className;
    }

    return acc;
  }, '');
};

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export const uuid = () => {
  return self.crypto.randomUUID();
};
