import React from 'react';

const SearchIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path
        fill="#A09F9F"
        d="m14.213 13.352-4.058-4.058c.63-.814.97-1.81.97-2.857a4.66 4.66 0 0 0-1.373-3.314A4.65 4.65 0 0 0 6.437 1.75a4.66 4.66 0 0 0-3.314 1.373A4.65 4.65 0 0 0 1.75 6.438c0 1.251.49 2.43 1.373 3.314a4.65 4.65 0 0 0 3.315 1.373c1.046 0 2.04-.34 2.854-.969l4.058 4.057a.13.13 0 0 0 .181 0l.681-.68a.13.13 0 0 0 0-.181m-5.3-4.44a3.48 3.48 0 0 1-2.476 1.025 3.48 3.48 0 0 1-2.475-1.024 3.48 3.48 0 0 1-1.025-2.476c0-.934.365-1.814 1.026-2.475a3.48 3.48 0 0 1 2.475-1.025c.934 0 1.814.363 2.475 1.026a3.48 3.48 0 0 1 1.024 2.475c0 .934-.364 1.814-1.024 2.475"
      />
    </svg>
  );
};

export default SearchIcon;
