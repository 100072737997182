import React, { createContext, useEffect, useState } from 'react';
import './topbar.scss';
import Button from '@components/Button/Button';
import MenuIcon from '../../Images/MenuIcon.svg';
import Logo from '../../Images/logo.svg';
import { Popover, PopoverTrigger, PopperContent } from '@components/Popover';
import { useLocation } from 'react-router-dom';
import Content from './component/Content';
import { actionHandler, PlannerActions } from '@store/actions';
import { connect } from 'react-redux';
import TopBarSearchInput from './component/TopBarSearchInput';
import { getMapboxInstance, resetMapInstance } from '@utils/mapboxInstance';
import { getNormalizeCountryMap } from '@apps/PlannerScreen/utils';

export const TopBarContext = createContext({});

const Topbar = ({ dispatchAction }) => {
  const initalValue = {
    countryTab: {
      cityList: [],
      countryMap: {},
      streetListMap: {},
    },
    selectedState: {
      countryMap: {},
      cityMap: {},
      location: {},
    },
  };

  const location = useLocation();
  const isPlannnerScreen = location?.pathname?.includes('planner-screen');
  const [isOpen, setIsOpen] = useState(false);
  const [overlayState, setOverlayState] = useState(initalValue);
  const [topBarSearchInput, setTopBarSearchInput] = useState('');
  const [countryLoading, setCountryLoading] = useState('');

  useEffect(() => {
    dispatchAction(PlannerActions.FETCH_COUNTRY_LIST, {
      setLoading: setCountryLoading,
      onSuccess: (response) => {
        setOverlayState((prevState) => {
          return {
            ...prevState,
            countryTab: {
              ...prevState.countryTab,
              countryMap: getNormalizeCountryMap(response),
            },
          };
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isPlannnerScreen) {
      let mapInstance = getMapboxInstance();
      setTopBarSearchInput('');
      setIsOpen(false);
      if (mapInstance) {
        resetMapInstance();
      }
      setOverlayState((prevState) => {
        return {
          ...prevState,
          countryTab: {
            ...prevState.countryTab,
            cityMap: {},
          },
          selectedState: {
            cityMap: {},
            countyMap: {},
            location: {},
          },
        };
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlannnerScreen]);

  const topbarStyles = isPlannnerScreen
    ? {
        padding: '16px 27px 16px 0px',
      }
    : {
        padding: '10.7px 27px 10.7px 0px',
        marginBottom: '40px',
      };

  return (
    <div className="topbar__container" style={topbarStyles}>
      <Button
        variant="ghost"
        style={{ padding: isPlannnerScreen ? '0px 16px' : '0px 19px' }}
      >
        <img src={MenuIcon} width="20px" height="20px" alt="side_menu_button" />
      </Button>

      <img src={Logo} width="94.587px" height="22.34px" alt="verve_logo" />
      {isPlannnerScreen ? (
        <Popover
          isOpen={isOpen}
          onChange={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <PopoverTrigger>
            {(triggerProps) => {
              return (
                <TopBarSearchInput
                  triggerProps={triggerProps}
                  isOpen={isOpen}
                  topBarSearchInput={topBarSearchInput}
                  setTopBarSearchInput={setTopBarSearchInput}
                />
              );
            }}
          </PopoverTrigger>
          <PopperContent
            className="flex w-100 topbar__popover-wrapper"
            popoverPosition={'bottom'}
          >
            <TopBarContext.Provider
              value={{
                topBarSearchInput,
                setTopBarSearchInput,
                overlayState,
                setOverlayState,
                countryLoading,
              }}
            >
              <Content setIsOpen={setIsOpen} dispatchAction={dispatchAction} />
            </TopBarContext.Provider>
          </PopperContent>
        </Popover>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(null, mapDispatchToProps)(Topbar);
