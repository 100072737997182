import { countryList } from '@constants/index';
import React, { useTransition } from 'react';

const CountryList = ({ setSelectedCountryId, selectedCountryId }) => {
  const [_isPending, startTransition] = useTransition();

  const handleSetupChange = (id) => {
    startTransition(() => {
      setSelectedCountryId(id);
    });
  };

  return (
    <>
      <h1 className="welcome-screen__heading">
        Where do you plan to set up the campaign?
      </h1>
      <ul className="card-container">
        {countryList.map((image) => {
          const isSelected = String(selectedCountryId) === image.id;

          return (
            <li
              key={image.id}
              className="card"
              onClick={() => handleSetupChange(image.id)}
              role="presentation"
            >
              <div className="card__image-container">
                <div
                  style={{
                    border: '1px solid #0000001A',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    boxShadow: '0px 11px 29.4px 0px #E6E7ED',
                    background: isSelected ? '#F7F6F2' : '#FFF',
                    width: '168px',
                    height: '168px',
                  }}
                >
                  {isSelected ? image.selectedImage : image.defaultImage}
                </div>
                <div
                  className="flex align-center"
                  style={{
                    gap: '8px',
                  }}
                >
                  <input
                    id={`name-${image.id}`}
                    type="radio"
                    className="welcome-screen__radio"
                    name="setupImage"
                    value={image.id}
                    style={{
                      margin: 'unset',
                      visibility: isSelected ? 'visible' : 'hidden',
                    }}
                    checked={isSelected}
                  />
                  <p className="welcome-screen__card-subtitle">{image.label}</p>
                </div>
              </div>

              <p className="selected-city">{image.description}</p>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default CountryList;
