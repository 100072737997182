import { actionHandler, PlannerActions } from '@store/actions';
import { getScreenListSelector, getTableListSelector } from '@store/selectors';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import checkbox from '../../Images/outdoor_icon.png';
import indoor from '../../Images/indoor.png';
import { useParams } from 'react-router-dom';
import { CountryMap } from '@constants/index';
import { getMapboxInstance, initializeMapbox } from '@utils/mapboxInstance';

function PlannerScreen({ screenList, dispatchAction }) {
  const mapContainerRef = useRef(null);

  const { type, campaignLocation } = useParams();

  useEffect(() => {
    const mapInstance = initializeMapbox('map', {
      zoom: CountryMap[type]?.zoomlevel || 1.5,
      center: CountryMap[type]?.center || [10, 45],
    });

    // disable map rotation using right click + drag
    mapInstance.dragRotate.disable();

    // disable map rotation using touch rotation gesture
    mapInstance.touchZoomRotate.disableRotation();

    mapInstance.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    mapInstance.on('load', () => {
      [
        { url: checkbox, name: 'outdoor_image' },
        { url: indoor, name: 'indoor_image' },
      ].map((item) => {
        mapInstance.loadImage(item?.url, (error, image) => {
          if (error) throw error;
          mapInstance.addImage(item?.name, image);
        });
      });

      const featureCollection = {
        type: 'FeatureCollection',
        features: screenList,
      };

      mapInstance.addSource('screenType', {
        type: 'geojson',
        data: featureCollection,
      });

      mapInstance.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'screenType',
        layout: {
          'icon-image': [
            'match',
            ['get', 'screenType'],
            'out',
            'outdoor_image',
            'in',
            'indoor_image',
            'default-image',
          ],
          'icon-allow-overlap': true,
          'icon-size': 0.25,
        },
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!screenList?.length) {
      dispatchAction(PlannerActions.FETCH_PLANNER_SCREENS, {
        payload: {
          regionId: Number(type),
          campaign_location: Number(campaignLocation),
        },
        onSuccess: (screenData) => {
          const mapInstance = getMapboxInstance();

          const featureCollection = {
            type: 'FeatureCollection',
            features: screenData,
          };

          mapInstance?.getSource('screenType')?.setData(featureCollection);
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenList]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}
      className="planner-map--wrapper"
    >
      <div ref={mapContainerRef} id="map" style={{ height: '611px' }}></div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    screenDataList: getTableListSelector(state),
    screenList: getScreenListSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlannerScreen);
