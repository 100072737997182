import Topbar from '@components/Topbar';
import React from 'react';
import { Outlet } from 'react-router-dom';

const MainWrapper = () => {
  return (
    <>
      <Topbar />
      <Outlet />
    </>
  );
};

export default MainWrapper;
