import {
  getNormalizeCountryMap,
  getNormalizedPlannerScreen,
} from '@apps/PlannerScreen/utils';
import { PlannerActions } from '@store/actions';
import { isEmpty } from 'lodash';

const initialState = {
  tableList: [],
  screenData: [],
  locationList: [],
  countryRegionMap: {},
  fullLocationList: [],
  locationSearchMap: [],
  cityList: [],
  countryIdMap: {},
  geofilterOverlayState: {
    countryIds: {},
    cityIds: {},
    latlang: {},
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PlannerActions.FETCH_DOOH_SCREENS_SUCCESS: {
      const data = action.payload;

      return {
        ...state,
        tableList: data,
      };
    }

    case PlannerActions.FETCH_PLANNER_SCREENS_SUCCESS: {
      const screenData = action.payload;

      return {
        ...state,
        screenData: getNormalizedPlannerScreen({ screenData }),
      };
    }

    case PlannerActions.FETCH_COUNTRY_LIST_SUCCESS: {
      const countryRegionMap = getNormalizeCountryMap(action.payload);
      return {
        ...state,
        countryRegionMap: countryRegionMap,
      };
    }

    case PlannerActions.FETCH_CITY_LIST_SUCCESS: {
      return {
        ...state,
        cityList: action.payload
          .flat(1)
          .reduce(
            (acc, item) => [...acc, ...item.relationships.cities.data],
            []
          ),
      };
    }

    case PlannerActions.UPDATE_CITY_LIST: {
      return {
        ...state,
        cityList: action.payload.payload,
      };
    }

    case PlannerActions.UPDATE_GEOFILTER_OVERLAY_STATE: {
      const { selectedLocation, selectedCountries, selectedCity } =
        action.payload;

      const latlang = selectedLocation
        ? { ...state.geofilterOverlayState.latlang, ...selectedLocation }
        : state.geofilterOverlayState.latlang;

      return {
        ...state,
        geofilterOverlayState: {
          countryIds:
            selectedCountries || state.geofilterOverlayState.countryIds,
          cityIds: selectedCity || state.geofilterOverlayState.cityIds,
          latlang: isEmpty(latlang) ? {} : latlang,
        },
      };
    }
    default:
      return state;
  }
}
