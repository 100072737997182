import React from 'react';

const GroupallIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" fill="none">
      <path
        fill="#EFEDE8"
        d="M41.311 44.81v3.495h7v-7h-7v3.505M24.31 48.305a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M3.81 48.305a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M44.81 27.805a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M20.811 24.31v3.495h7v-7h-7v3.505M3.81 27.805a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M44.81 7.305a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M24.31 7.305a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7M.311.305v7h7v-7z"
      />
    </svg>
  );
};

export default GroupallIcon;
