import { useContext } from 'react';
import { PopoverContext } from './utils';
import { cx } from '@utils/helpers';

const PopoverTrigger = ({ children, className, onClick, ...rest }) => {
  const popoverTriggerClassname = cx({
    trigger: true,
    [className]: Boolean(className),
  });

  const context = useContext(PopoverContext);

  const handleOnTriggerClick = (e) => {
    if (context.showContentOnHover) return null;

    const oldIsMenuOpen = context.isPopOverOpen;

    if (!oldIsMenuOpen) {
      setTimeout(() => {
        context.popoverElement && context.popoverElement.focus();
      });
    }

    context.handleTogglePopoverOpen(!context.isPopOverOpen);

    onClick && onClick(e);
  };

  const onKeyDown = (e) => {
    const selectFirstMenuKeys = [' ', 'Enter'];

    context.onKeyDown && context.onKeyDown(e);

    if (e.key === 'Tab') {
      if (context.isPopOverOpen) {
        e.preventDefault();
      }
    }

    if (selectFirstMenuKeys.includes(e.key)) {
      e.preventDefault();

      context.handleTogglePopoverOpen(true);

      // Browser cannot focus element which is not in-view, that's we are telling browser, focus element on next update using setTimeout.
      setTimeout(() => {
        context.popoverElement && context.popoverElement.focus();
      });
    }
  };

  if (typeof children !== 'function') {
    throw new Error('PopoverTrigger accept children as Render Prop.');
  }

  return children({
    className: popoverTriggerClassname,
    ref: context.setTriggerElement,
    selected: context.isPopOverOpen,
    onKeyDown,
    onClick: handleOnTriggerClick,
    ...rest,
  });
};

export default PopoverTrigger;
