import React from 'react';

const IndoorIcon = ({ fill = '#EFEDE8' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="43" fill="none">
      <path
        fill={fill}
        d="M33.381 29.364c0-1.204-1.085-2.126-2.503-2.126H28.29c-.334-2.197-2.42-3.898-4.924-4.181v-.78c0-.425-.334-.709-.835-.709s-.834.284-.834.71v.779c-2.587.283-4.59 2.055-4.924 4.181h-2.587c-1.418 0-2.503.921-2.503 2.126s1.084 2.126 2.503 2.126h.835v11.34h1.669v-8.505h5.007v8.505h1.669v-8.505h5.007v8.505h1.669V31.49h.834c1.42 0 2.504-.92 2.504-2.126m-10.848-4.961c2.002 0 3.671 1.205 4.089 2.835h-8.178c.417-1.63 2.086-2.835 4.089-2.835m-9.18 4.961c0-.425.334-.708.834-.708h16.69c.501 0 .835.283.835.708s-.334.709-.834.709h-16.69c-.502 0-.835-.284-.835-.709m15.021 3.544H16.691V31.49h11.683z"
      />
      <path
        fill={fill}
        d="M10.85 32.908H3.34c-.083-2.977-.668-5.883-1.669-8.718l-1.669.426c1.085 2.905 1.669 5.953 1.669 9v9.214H3.34v-5.67h8.345v5.67h1.67v-7.797c0-1.204-1.086-2.126-2.504-2.126m-7.51 2.835v-1.418h7.51c.501 0 .835.284.835.709v.709zM43.396 24.19c-1.002 2.835-1.586 5.74-1.67 8.718h-7.51c-1.418 0-2.503.921-2.503 2.126v7.796h1.669v-5.67h8.345v5.67h1.67v-9.213c0-3.048.583-6.095 1.668-9zM33.382 35.743v-.71c0-.424.334-.708.834-.708h7.51v1.418zM15.023 12.282v.071c0 1.985 1.836 3.544 4.173 3.544 1.335 0 2.587-.567 3.338-1.418.75.85 2.003 1.418 3.338 1.418 2.336 0 4.172-1.56 4.172-3.544v-.07c1.419-.284 2.504-1.418 2.504-2.765V6.683h-6.676v2.835c0 1.347 1.085 2.41 2.503 2.764v.071c0 1.205-1.084 2.126-2.503 2.126s-2.504-.921-2.504-2.126V4.557h1.335L27.207.305H17.86l2.504 4.252h1.335v7.796c0 1.205-1.085 2.126-2.503 2.126-1.42 0-2.504-.921-2.504-2.126v-.07c1.419-.284 2.504-1.418 2.504-2.765V6.683h-6.677v2.835c0 1.347 1.085 2.41 2.504 2.764m12.518-2.764V8.101h3.338v1.417c0 .78-.75 1.418-1.669 1.418-.918 0-1.669-.638-1.669-1.418m-7.01-7.796h4.006l-.835 1.418h-2.336zM14.19 8.1h3.338v1.418c0 .78-.751 1.417-1.67 1.417-.917 0-1.668-.638-1.668-1.417z"
      />
    </svg>
  );
};

export default IndoorIcon;
