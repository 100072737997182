import React from 'react';

const OutdoorIcon = ({ fill = '#EFEDE8' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="67" height="43" fill="none">
      <path
        fill={fill}
        d="M58.868 37.323V27.88h-8.106V.305H33.118V13.85l-8.106-7.633v21.664h-6.677V14.15H8.8v23.174H.691v5.504h66.284v-5.504zm-25.75-21.66v1.982h-6.676V9.376l6.676 6.288zm-6.676 5.405V18.86h6.676v2.21zm6.676 1.215v2.208h-6.676v-2.208zm-6.676 3.423h6.676v2.209h-6.676zm0 10.271h6.676v2.21h-6.676zm0-1.214v-2.21h6.676v2.21zm0-3.424v-2.208h6.676v2.208zM8.798 41.611H2.12v-3.075h6.677zm8.106 0h-1.255v-3.19h-1.43v3.19h-1.304v-3.19h-1.43v3.19h-1.256V15.363h6.677v26.249zm8.106 0h-6.676V29.093h6.676zm1.43 0V39.4h6.677v2.21zm22.89 0h-2.896v-3.18h-1.43v3.18h-2.35v-3.18h-1.43v3.18h-2.349v-3.18h-1.43v3.18h-2.897V1.519h14.784v40.093zm8.107 0h-6.676V29.093h6.676zm8.106 0h-6.676v-3.075h6.676z"
      />
    </svg>
  );
};

export default OutdoorIcon;
