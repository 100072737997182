import React from 'react';

const ArrowDownIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
      <path
        stroke="#535353"
        strokeWidth="2"
        d="M.991.852 5.29 5.148 9.587.852"
      />
    </svg>
  );
};

export default ArrowDownIcon;
