import React, { forwardRef } from 'react';
import './styles.scss';
import { cx } from '@utils/helpers';

const variantMap = {
  solid: 'solid',
  outline: 'outline',
  ghost: 'ghost',
};

const colorMap = {
  primary: 'primary',
  seconday: 'secondary',
};

const Button = forwardRef(
  (
    {
      children,
      onClick,
      style,
      disabled = false,
      variant,
      color,
      className,
      ...rest
    },
    ref
  ) => {
    const buttonVariant = variantMap[variant] || 'solid';
    const buttonColor = colorMap[color] || 'primary';

    const buttonClassNames = cx({
      [className]: Boolean(className),
      button: true,
      [`button--${buttonVariant}`]: true,
      [`button--${buttonColor}`]: true,
      [`button--${buttonColor}--disabled`]: disabled,
    });

    return (
      <button
        disabled={disabled}
        ref={ref}
        onClick={onClick}
        style={style}
        className={buttonClassNames}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

export default Button;
