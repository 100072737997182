import mapboxgl from 'mapbox-gl';

let mapInstance = null;

export function initializeMapbox(mapContainer = 'map', options) {
  if (!mapInstance) {
    mapboxgl.accessToken = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN;
    mapInstance = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/mapbox/light-v11',
      projection: 'mercator',
      ...options,
    });
  }
  return mapInstance;
}

export function getMapboxInstance() {
  return mapInstance;
}

export function resetMapInstance() {
  mapInstance = null;
}
