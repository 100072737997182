import React from 'react';

const GroupallSelectedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" fill="none">
      <path
        fill="#1A016E"
        stroke="#fff"
        d="M43.06 42.286a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5z"
      />
      <path
        fill="#20C9FF"
        stroke="#fff"
        d="M24.559 50.286a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM4.559 50.286a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM45.559 30.286a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
      <path
        fill="#1A016E"
        stroke="#fff"
        d="M22.06 22.286a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5z"
      />
      <path
        fill="#20C9FF"
        stroke="#fff"
        d="M4.559 30.286a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM45.559 9.286a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM24.559 9.286a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
      <path
        fill="#1A016E"
        stroke="#fff"
        d="M2.06 1.286a1.5 1.5 0 0 0-1.5 1.5v5a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-5a1.5 1.5 0 0 0-1.5-1.5z"
      />
    </svg>
  );
};

export default GroupallSelectedIcon;
