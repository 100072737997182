import { cx } from '@utils/helpers';
import React from 'react';
import './heading.scss';

const Heading = ({ children, className, ...rest }) => {
  const headingClassNames = cx({
    heading: true,
    [className]: Boolean(className),
  });

  return (
    <div className={headingClassNames} {...rest}>
      {children}
    </div>
  );
};

export default Heading;
