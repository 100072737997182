import { cx } from '@utils/helpers';
import React from 'react';
import './text.scss';

const Text = ({
  children,
  className,
  selected,
  selectable = false,
  ...rest
}) => {
  const textClassNames = cx({
    text: true,
    'text--selected': selected ? true : false,
    'text--selectable': selectable ? true : false,
    [className]: Boolean(className),
  });

  return (
    <div className={textClassNames} {...rest}>
      {children}
    </div>
  );
};

export default Text;
