import React from 'react';

const CrossIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" fill="none">
      <path
        stroke="#C1BEBE"
        strokeWidth="2"
        d="m12.596 12.594-4.3-4.297L4 12.594M4 4l4.299 4.297L12.596 4"
      />
    </svg>
  );
};

export default CrossIcon;
