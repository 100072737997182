import { Input, Spin } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import SearchIcon from '../../../Images/Icons/SearchIcon';
import Text from '@components/Text';
import Heading from '@components/Heading';
import Button from '@components/Button/Button';
import CrossIcon from '../../../Images/Icons/CrossIcon';
import { connect } from 'react-redux';
import {
  getCityListSelector,
  getcountryRegionMapSelector,
  getGeoFilterStateSelector,
} from '@store/selectors';
import { AutoSizer, List } from 'react-virtualized';
import { actionHandler, PlannerActions } from '@store/actions';
import { useParams } from 'react-router-dom';
import { clone, isEmpty, isEqual } from 'lodash';
import { TopBarContext } from '..';
import { countryList } from '@constants/index';
import { getNormalizeCityList } from '@apps/PlannerScreen/utils';
import { removeCityForCountry } from '../utils';

const placeholderMap = {
  countryMap: 'Search Countries..',
  cityMap: 'Search Cities',
  streetMap: 'Search Streets',
};

function TabContent({ isLoading, name = '', list, onChange }) {
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const isCountryTab = name === 'countryMap';
  const { overlayState, setOverlayState } = useContext(TopBarContext);
  const { type } = useParams();
  const summaryList = Object.entries(overlayState?.selectedState[name] || {});

  const handleSearch = (searchKeyword) => {
    if (searchKeyword.length) {
      const filteredList = list.filter((item) => {
        const value = item?.attributes?.name || item?.name || item?.street;
        return value
          ?.toLowerCase()
          ?.includes(searchKeyword?.toLocaleLowerCase());
      });

      setData(filteredList);
    } else {
      setData(list);
    }
  };
  const showBorder = data?.length < 7 ? true : false;

  const countryTabContainerStyles = isCountryTab
    ? {
        paddingLeft: '12px',
        paddingTop: '4px',
        borderRight: showBorder ? '1px solid #E7E7E7' : 'unset',
      }
    : {
        borderRight: showBorder ? '1px solid #E7E7E7' : 'unset',
      };
  useEffect(() => {
    setData(list);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  function deleteSelectedListItem(id) {
    const clonnedObject = clone(overlayState.selectedState[name]);
    delete clonnedObject[id];
    let payload = { [name]: clonnedObject };

    if (name === 'countryMap') {
      const newSelectedCityMap = removeCityForCountry(
        overlayState.selectedState.cityMap,
        id
      );
      payload = { [name]: clonnedObject, cityMap: newSelectedCityMap };
    }
    setOverlayState((prev) => {
      return {
        ...prev,
        selectedState: {
          ...prev.selectedState,
          ...payload,
        },
      };
    });

    onChange?.(clonnedObject);
  }

  function renderRow({ index, key, style }) {
    const rowItem = data[index];
    const itemId =
      name === 'countryMap'
        ? rowItem.id
        : `${rowItem.country_id}-${rowItem.id}`;

    function addSelectedListItem(id) {
      setOverlayState((prev) => {
        return {
          ...prev,
          selectedState: {
            ...prev.selectedState,
            [name]: {
              ...prev.selectedState[name],
              [id]: rowItem,
            },
          },
        };
      });
    }

    function handleClickOnListItem() {
      const isListItemSelected = overlayState.selectedState?.[name]?.[itemId];

      if (isListItemSelected) {
        deleteSelectedListItem(itemId);
      } else {
        addSelectedListItem(itemId);
        const payload = {
          ...overlayState.selectedState.countryMap,
          [itemId]: rowItem,
        };
        onChange?.(payload);
      }
    }

    return (
      <Text
        key={key}
        style={{ ...style, color: '#535353' }}
        selectable
        selected={overlayState?.selectedState[name]?.[itemId] || false}
        onClick={() => {
          handleClickOnListItem();
        }}
      >
        {rowItem?.attributes?.name || rowItem?.name || rowItem?.street}
      </Text>
    );
  }

  return (
    <div className="flex-1">
      <>
        <div
          style={{
            borderBottom: '1px solid #e7e7e7',
            padding: '0px 0px 0px 12px',
          }}
        >
          <div style={{ paddingRight: '15px' }}>
            <Input
              variant="borderless"
              className="topbar__popover-content-tab__search-input"
              placeholder={placeholderMap[name]}
              prefix={<SearchIcon />}
              value={searchValue}
              style={{ paddingLeft: isCountryTab ? '12px' : '0px' }}
              onChange={(e) => {
                setSearchValue(e.target.value);
                handleSearch(e.target.value);
              }}
            />
          </div>
          {isCountryTab ? (
            <Text
              style={{
                color: '#B9B9B9',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '22px',
                textAlign: 'left',
                marginTop: '8px',
                paddingLeft: '12px',
                borderRight: showBorder ? '1px solid #E7E7E7' : 'unset',
              }}
            >
              {countryList[type - 1]?.label}
            </Text>
          ) : null}

          <div
            className="topbar__country-tab-list-container "
            style={countryTabContainerStyles}
          >
            {isLoading ? (
              <div
                style={{
                  display: ' flex',
                  justifyContent: 'center',
                  width: '100%',
                  alignItems: 'center',
                  borderRight: '1px solid #e7e7e7',
                  height: '175px',
                }}
              >
                <Spin />
              </div>
            ) : (
              <div
                style={{
                  width: '100%',
                  height: isCountryTab ? 150 : 175,
                }}
              >
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      width={width}
                      height={height}
                      rowHeight={18}
                      rowRenderer={(props) => {
                        return renderRow(props);
                      }}
                      rowCount={data?.length}
                    />
                  )}
                </AutoSizer>
              </div>
            )}

            <div
              className="fade-bottom"
              style={{ height: isCountryTab ? '30.5px' : '31.0px' }}
            />
          </div>
        </div>
        <div
          className="topbar__country-summary-list-container topbar__country-tab-list-container"
          style={{
            padding: isCountryTab
              ? '8.5px 0px 20px 24px'
              : '8.5px 0px 20px 12px',
          }}
        >
          <div className="flex align-center space-between">
            {isCountryTab ? <Heading>Summary</Heading> : <Heading></Heading>}
            <Button
              variant="ghost"
              onClick={() => {
                setOverlayState((prev) => {
                  return {
                    ...prev,
                    selectedState: {
                      ...prev.selectedState,
                      [name]: {},
                    },
                  };
                });
                onChange?.({});
              }}
              disabled={!summaryList.length}
              style={{
                color: !summaryList.length ? '#bbbbbb' : '#1a016e',
                fontSize: '10px',
                fontStyle: 'normal',
                textDecoration: 'underline',
                paddingRight: '8px',
              }}
            >
              Remove
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '6px',
              overflow: 'auto',
            }}
            className="topbar__country-tab-list-container "
          >
            {summaryList?.map((item) => {
              const [key, value] = item;
              return (
                <>
                  <div
                    className="flex align-center "
                    style={{ height: '18px' }}
                  >
                    <Text key={key} style={{ color: '#000000E0' }}>
                      {value?.attributes?.name || value?.name || value?.street}
                    </Text>
                    <Button
                      variant="ghost"
                      style={{ paddingLeft: '4px' }}
                      onClick={() => {
                        deleteSelectedListItem(key);
                      }}
                    >
                      <CrossIcon />
                    </Button>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </>
    </div>
  );
}

const CountryTab = ({
  dispatchAction,
  setIsOpen,
  handleSubmit,
  isSubmitting,
}) => {
  const [cityListLoading, setCityListLoading] = useState(false);
  const { _topBarSearchInput, overlayState, setOverlayState, countryLoading } =
    useContext(TopBarContext);
  const [isDirty, setIsDirty] = useState(false);
  const initalOverlayRef = useRef(null);

  // const isEdit = !Boolean(topBarSearchInput.length);
  const hasFormValues =
    isEmpty(overlayState.selectedState.countryMap) &&
    isEmpty(overlayState.selectedState.cityMap) &&
    isEmpty(overlayState.selectedState.streetMap);

  useEffect(() => {
    initalOverlayRef.current = overlayState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !isEqual(
        overlayState.selectedState,
        initalOverlayRef.current.selectedState
      )
    ) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [overlayState.selectedState]);

  const { type } = useParams();

  function resetOverlay() {
    setOverlayState((prev) => {
      return {
        countryTab: {
          ...prev.countryTab,
          cityList: [],
        },
        selectedState: {
          ...prev.selectedState,
          countryMap: {},
          cityMap: {},
        },
      };
    });
  }

  const handleOnCountrySelect = (selectedCountries) => {
    if (isEmpty(selectedCountries)) {
      resetOverlay();
      return;
    }

    const countryIds = Object.keys(selectedCountries).join(',');

    dispatchAction(PlannerActions.FETCH_CITY_LIST, {
      setLoading: setCityListLoading,
      countryIds,
      onSuccess: (response) => {
        setOverlayState((prev) => {
          return {
            ...prev,
            countryTab: {
              ...prev.countryTab,
              cityList: getNormalizeCityList(response),
            },
          };
        });
      },
    });
  };

  const handleOnCitySelect = () => {};

  return (
    <>
      <div className="flex">
        <TabContent
          name="countryMap"
          list={overlayState?.countryTab?.countryMap[type]}
          isLoading={countryLoading}
          onChange={handleOnCountrySelect}
        />
        <TabContent
          name="cityMap"
          isLoading={cityListLoading}
          list={overlayState?.countryTab?.cityList}
          onChange={handleOnCitySelect}
        />
        <TabContent name="streetMap" list={[]} />
      </div>
      <div
        style={{
          display: 'Flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '16px',
          marginBottom: '20px',
        }}
      >
        <div className="topbar__location-tab-buttons">
          <Button
            variant="outline"
            color="secondary"
            onClick={() => {
              setIsOpen(false);
              setOverlayState(initalOverlayRef.current);
            }}
            style={{
              fontFamily: "'Inter', sans-serif",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting || !isDirty}
            onClick={() => {
              handleSubmit();
            }}
            style={{
              fontFamily: "'Inter', sans-serif",
            }}
          >
            Apply Settings
          </Button>
        </div>

        <Button
          variant="ghost"
          onClick={() => {
            resetOverlay();
          }}
          disabled={hasFormValues}
          style={{
            color: hasFormValues ? '#BBBBBB' : '#1a016e',
            fontSize: '10px',
            fontStyle: 'normal',
            textDecoration: 'underline',
            padding: '0px',
            position: 'absolute',
            right: '18px',
          }}
        >
          Remove All Settings
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    countryRegionMap: getcountryRegionMapSelector(state),
    cityList: getCityListSelector(state),
    overlayState: getGeoFilterStateSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CountryTab);
