export const getNormalizePayload = ({ state, campaignLocation, type }) => {
  if (
    Object.keys(state.location).length === 0 &&
    Object.keys(state.countryMap).length === 0 &&
    Object.keys(state.cityMap).length === 0
  ) {
    return {
      regionId: type,
      campaign_location: campaignLocation,
    };
  }

  return {
    latLang:
      Object.keys(state.location).map((key) => {
        return [
          String(state.location[key]?.attributes?.lat),
          String(state.location[key]?.attributes?.long),
        ];
      }) || [],
    countryIds: Object.keys(state.countryMap) || [],
    cityIds: Object.keys(state.cityMap) || [],
  };
};

export const getTopBarSearchValue = (state) => {
  const finalArray = [
    ...Object.values(state.countryMap),
    ...Object.values(state.cityMap),
  ];
  return finalArray
    .map((item) => item?.attributes?.name || item?.name || item?.street)
    .join(', ');
};

export function removeCityForCountry(obj, deleteKey) {
  const newObj = { ...obj };

  return Object.entries(newObj).reduce((acc, [key, value]) => {
    if (!key.includes(deleteKey)) {
      return { ...acc, [key]: value };
    }
    return acc;
  }, {});
}
