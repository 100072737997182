import { createSelector } from 'reselect';

const getTableList = (state) => state.planner.tableList;

const getScreenList = (state) => state.planner.screenData;

const getLocationList = (state) => state.planner.locationList;

const getCountryRegionMap = (state) => state.planner.countryRegionMap;

const getCompleteLocationListMap = (state) => state.planner.fullLocationList;

const getLocationSearchMap = (state) => state.planner.locationSearchMap;

const getCityList = (state) => state.planner.cityList;

const getGeoFilterState = (state) => state.planner.geofilterOverlayState;

export const getTableListSelector = createSelector(
  [getTableList],
  (tableData) => tableData
);

export const getScreenListSelector = createSelector(
  [getScreenList],
  (screenList) => screenList
);

export const getLocationListSelector = createSelector(
  [getLocationList],
  (locationList) => locationList
);

export const getcountryRegionMapSelector = createSelector(
  [getCountryRegionMap],
  (countryMap) => countryMap
);

export const getCompleteLocationListMapSelector = createSelector(
  [getCompleteLocationListMap],
  (locationList) => locationList
);

export const getCityListSelector = createSelector(
  [getCityList],
  (cityList) => cityList
);

export const getLocationSearchMapSelector = createSelector(
  [getLocationSearchMap],
  (searchMap) => searchMap
);

export const getGeoFilterStateSelector = createSelector(
  [getGeoFilterState],
  (geoFilterState) => geoFilterState
);
