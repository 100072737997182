import React, { useContext, useEffect, useState } from 'react';

import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { PopoverContext } from './utils';
import { canUseDOM, cx } from '@utils/helpers';

const Portal = ({ children }) => {
  if (canUseDOM) {
    let menuRoot = document.getElementById('popover-portal');

    if (!menuRoot) {
      menuRoot = document.createElement('div');
      menuRoot.setAttribute('id', 'popover-portal');
      document.body.appendChild(menuRoot);
    }

    return createPortal(children, menuRoot);
  }
  return children;
};

const PopoverContent = ({
  children,
  className,
  popoverPosition = 'bottom-start',
  dataQaSelector,
  ...rest
}) => {
  const context = useContext(PopoverContext);
  const [showContentOnHover, setShowContentOnHover] = useState(false);

  const popoverItemClassname = cx({
    content: true,
    'content-show': context.isPopOverOpen || showContentOnHover,

    [className]: Boolean(className),
  });

  const handleOnBackdropClick = (e) => {
    e.stopPropagation();
    context.handleTogglePopoverOpen(false);
    setTimeout(() => {
      // eslint-disable-next-line no-unused-expressions
      context?.triggerElement?.focus();
    }, 0);
  };

  const { styles, attributes, forceUpdate } = usePopper(
    context.triggerElement,
    context.popoverElement,
    {
      placement: popoverPosition,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundary: 'scrollParent',
            escapeWithReference: true,
          },
        },
        {
          name: 'flip',
          options: {
            boundariesElement: 'scrollParent',
            flipVariations: true,
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        { name: 'eventListeners', enabled: true },
      ],
    }
  );

  const showEvents = ['mouseenter', 'focus'];
  const hideEvents = ['mouseleave', 'blur'];

  function show() {
    setShowContentOnHover(true);
  }

  function hide() {
    setShowContentOnHover(false);
  }

  useEffect(() => {
    showEvents.forEach((event) => {
      return context?.triggerElement?.addEventListener(event, show);
    });

    hideEvents.forEach((event) => {
      return context?.triggerElement?.addEventListener(event, hide);
    });

    return () => {
      showEvents.forEach((event) => {
        return context?.triggerElement?.removeEventListener(event, show);
      });

      hideEvents.forEach((event) => {
        return context?.triggerElement?.removeEventListener(event, hide);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.triggerElement]);

  useEffect(() => {
    if (context.isPopOverOpen) {
      if (forceUpdate) {
        forceUpdate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.isPopOverOpen]);

  if (!context.isPopOverOpen && context.showContentOnHover !== true)
    return null;

  return (
    <Portal>
      {/* <div
        className="backdrop"
        onClick={handleOnBackdropClick}
        role="presentation"
        style={{
          display: context.isPopOverOpen ? 'block' : 'none',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 9999,
          background: 'transparent',
        }}
      /> */}

      <div
        role="presentation"
        ref={context.setPopoverElement}
        className={popoverItemClassname}
        onKeyDown={context.onKeyDown}
        style={{
          outline: 'none',
          ...styles.popper,
        }}
        data-qa-selector={dataQaSelector}
        {...attributes.popper}
        {...rest}
      >
        {children}
      </div>
    </Portal>
  );
};

export default PopoverContent;
