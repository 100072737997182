import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionHandler, PlannerActions } from '@store/actions';
import { connect } from 'react-redux';
import CountryList from './components/CountryList';
import CampaginLocation from './components/CampaginLocation';
import Button from '@components/Button/Button';
import { countryList, screenTypeList } from '@constants/index';

const WelcomeScreen = ({ dispatchAction }) => {
  const [submitting, setIsSubmitting] = useState(false);
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [campaginTypeId, setCampaignTypeId] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const selectedCountry = countryList.find(
      (country) => country?.id === selectedCountryId
    );
    const campaginType = screenTypeList.find(
      (screen) => screen?.id === campaginTypeId
    );

    dispatchAction(PlannerActions.FETCH_PLANNER_SCREENS, {
      setLoading: setIsSubmitting,
      payload: {
        regionId: Number(selectedCountry?.type),
        campaign_location: Number(campaginType?.type),
      },
      onSuccess: () => {
        navigate(
          `/planner-screen/${selectedCountry.type}/${campaginType.type}`
        );
      },
    });
  };

  const isButtonDisabled = !(selectedCountryId && campaginTypeId) || submitting;

  return (
    <div className="welcome-screen__container">
      <form onSubmit={handleSubmit} className="welcome-screen__form">
        <CountryList
          setSelectedCountryId={setSelectedCountryId}
          selectedCountryId={selectedCountryId}
        />
        <CampaginLocation
          setCampaignTypeId={setCampaignTypeId}
          campaginTypeId={campaginTypeId}
        />
        <Button
          type="submit"
          disabled={isButtonDisabled}
          style={{ margin: 'auto' }}
        >
          Next
        </Button>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(null, mapDispatchToProps)(WelcomeScreen);
