import PlannerScreen from '@apps/PlannerScreen';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import WelcomeScreen from '@apps/WelcomeScreen';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MainWrapper />}>
        <Route path="" index element={<WelcomeScreen />} />
        <Route
          path="/planner-screen/:type/:campaignLocation"
          element={<PlannerScreen />}
        />
      </Route>
    </Routes>
  );
};

export default AppRouter;
