import React, { useState } from 'react';
import { PopoverContext } from './utils';
import useFocusLock from '../../hooks/useFocusLock';
import './popover.scss';

const Popover = ({
  children,
  isOpen,
  onChange,
  showContentOnHover = false,
}) => {
  const [isPopOverOpen, toggleIsPopoverOpen] = React.useState(false);
  const [triggerElement, setTriggerElement] = React.useState(null);
  const [popoverElement, setPopoverElement] = useState(null);
  const isControlled = isOpen !== undefined;

  const handleTogglePopoverOpen = (isOpen) => {
    if (!isControlled) {
      toggleIsPopoverOpen(isOpen);
    }
    // eslint-disable-next-line no-unused-expressions
    onChange?.(isOpen);
  };

  const { firstFocusableElement, lastFocusableElement } = useFocusLock(
    { current: popoverElement },
    popoverElement
  );

  const handleOnKeyDown = (e) => {
    const isEscapeKey = e.key === 'Escape';
    const isTabKey = e.key === 'Tab';
    const isShiftKey = e.shiftKey;
    const isFirstElementFocused =
      document.activeElement === firstFocusableElement.current;
    const isLastElementFocused =
      document.activeElement === lastFocusableElement.current;

    if (isEscapeKey) {
      e.stopPropagation();
      handleTogglePopoverOpen(false);

      setTimeout(() => {
        if (triggerElement) {
          triggerElement.focus();
        }
      }, 0);
    }

    if (isTabKey && isPopOverOpen) {
      if (!firstFocusableElement.current && !lastFocusableElement.current) {
        e.preventDefault();
      }
      // When Tab is pressed
      if (isLastElementFocused && !isShiftKey) {
        e.preventDefault();
        firstFocusableElement.current.focus();
      }

      // When Shift+Tab is pressed
      if (isFirstElementFocused && isShiftKey) {
        e.preventDefault();
        lastFocusableElement.current.focus();
      }
    }
  };

  const contextValues = {
    isPopOverOpen: isControlled ? isOpen : isPopOverOpen,
    handleTogglePopoverOpen,
    triggerElement,
    onKeyDown: handleOnKeyDown,
    setTriggerElement,
    setPopoverElement,
    showContentOnHover,
    popoverElement,
  };

  return (
    <PopoverContext.Provider value={contextValues}>
      {children}
    </PopoverContext.Provider>
  );
};

export default Popover;
