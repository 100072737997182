/* eslint-disable no-unused-expressions */
import { useEffect, useRef } from 'react';

// List of elements which are focusable.
const focusableElements = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type=hidden])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'object',
  'embed',
  '[tabindex]:not(.modal)',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
];

function useFocusLock(ref, isOpen, focusFirstElement = true) {
  const firstFocusableElement = useRef(null);
  const lastFocusableElement = useRef(null);

  useEffect(() => {
    if (ref.current) {
      const focusableElementsRef = Array.from(
        ref.current.querySelectorAll(focusableElements.join(', '))
      );

      firstFocusableElement.current = focusableElementsRef[0];
      lastFocusableElement.current = focusableElementsRef[focusableElementsRef.length - 1];
      if (focusFirstElement && firstFocusableElement.current) {
        firstFocusableElement.current.focus();
      } else {
        ref.current?.focus();
      }
    }
  }, [isOpen]);

  return { firstFocusableElement, lastFocusableElement };
}

export default useFocusLock;
