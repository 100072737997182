import React, { useEffect } from 'react';
import GlobeIcon from '../../../Images/Icons/GlobeIcon';
import ArrowDownIcon from '../../../Images/Icons/ArrowDownIcon';

const TopBarSearchInput = ({
  topBarSearchInput,
  triggerProps,
  isOpen,
  setTopBarSearchInput,
}) => {
  useEffect(() => {
    setTopBarSearchInput('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...triggerProps} className="flex align-center topbar__input">
      <GlobeIcon />
      <p
        className="topbar__input-text clipped"
        style={{ color: topBarSearchInput.length ? '#040532' : '' }}
      >
        {topBarSearchInput.length
          ? topBarSearchInput
          : 'Search by country, city, street...'}
      </p>
      <div
        style={{
          transform: isOpen ? 'rotate(-180deg)' : '',
          transition: 'transform 0.5s ease',
        }}
      >
        <ArrowDownIcon />
      </div>
    </div>
  );
};

export default TopBarSearchInput;
